import { Flex, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Container from "../../../component/Container";
import { reputation } from "../../../constant/API_ROUTES/DataReputation";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import Section from "../Section";
import SectionPortfolio from "../SectionPortfolio";
const Section2 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack
      spacing={0}
      gap={isMobileOrTablet ? 0 : isTabletOrLaptop ? "90px" : "100px"}
      mb="50px"
      position="relative"
      // bg={isMobileOrTablet ? "rgba(20,20,20,0.5)" : "none"}
      transform="translateY(-70px)"
    >
      <Container direction="column" position="relative" gap="20px">
        <Stack
          data-aos="fade-up"
          padding={isMobileOrTablet ? "20px" : "64px"}
          mt="70px"
          direction="column"
          bg={COLOR.tertiary}
          borderRadius="24px"
          alignItems="center"
          justifyContent="center"
          border={`1px solid ${COLOR.quaternary}`}
        >
          <img
            style={{
              display: isTabletOrLaptop ? "flex" : "none",
            }}
            width="257px"
            src={IMAGES.aboutTeam}
            alt=""
          />
          <Text
            textAlign="center"
            fontSize="40px"
            fontWeight={700}
            lineHeight="52.08px"
            fontFamily="DM Sans"
            color={COLOR.sixteen}
            m={0}
          >
            About OneBit Ventures
          </Text>
          <Text
            w={isTabletOrLaptop ? "100%" : "940px"}
            textAlign="center"
            fontSize="16px"
            fontWeight={400}
            lineHeight="24px"
            fontFamily="DM Sans4"
            color={COLOR.sixteen}
          >
            We are a professional investment firm focused on identifying,
            evaluating, and investing in promising blockchain technology
            projects. With a mission to contribute to the development and
            widespread adoption of blockchain, we are committed to creating
            lasting value for both the community and investors.
          </Text>
          {isTabletOrLaptop ? (
            <Stack
              mt="20px"
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              // border={`1px solid ${COLOR.quaternary}`}
            >
              <Stack
                gap="70px"
                p="24px 32px"
                borderRadius="16px"
                bg={COLOR.sixth}
                border={`1px solid ${COLOR.quaternary}`}
              >
                <Stack>
                  <img width="64px" src={IMAGES.invested} alt="" />
                  <Flex alignItems="center" gap="10px">
                    <Text
                      m={0}
                      textAlign="left"
                      fontSize={isMobile ? "30px" : "32px"}
                      fontWeight={700}
                      lineHeight="40px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      $6M +
                    </Text>
                    <Text
                      m={0}
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="23.44px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      Invested
                    </Text>
                  </Flex>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="18px"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                  >
                    Fund focused on exploring high-quality projects with great
                    potential
                  </Text>
                </Stack>
                <Stack>
                  <img width="64px" src={IMAGES.project} alt="" />
                  <Flex alignItems="center" gap="10px">
                    <Text
                      m={0}
                      textAlign="left"
                      fontSize={isMobile ? "30px" : "32px"}
                      fontWeight={700}
                      lineHeight="40px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      60 +
                    </Text>
                    <Text
                      m={0}
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="23.44px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      Portfolio projects
                    </Text>
                  </Flex>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="18px"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                  >
                    In various aspects, including blockchain basic
                    infrastructures, GameFi, DeFi, WEB3.0, NFT and Metaverse
                  </Text>
                </Stack>
                <Stack>
                  <img width="64px" src={IMAGES.kol} alt="" />
                  <Flex alignItems="center" gap="10px">
                    <Text
                      m={0}
                      textAlign="left"
                      fontSize={isMobile ? "30px" : "32px"}
                      fontWeight={700}
                      lineHeight="40px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      100 +
                    </Text>
                    <Text
                      m={0}
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="23.44px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      KOLs
                    </Text>
                  </Flex>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="18px"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                  >
                    With large communities, strong expertises and great
                    influences on Blockchain Technology
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Flex
              mt="20px"
              alignItems="center"
              justifyContent="center"
              direction={isTabletOrLaptop ? "column" : "row"}
              // bg={COLOR.sixth}
              borderRadius={isTabletOrLaptop ? "none" : "16px"}
              border={`1px solid ${COLOR.quaternary}`}
            >
              {reputation.map((i, index) => (
                <Stack
                  spacing={5}
                  borderLeftRadius={
                    index === 1 || index === 2 ? "none" : "16px"
                  }
                  borderRightRadius={
                    index === 0 || index === 1 ? "none" : "16px"
                  }
                  p="24px 32px"
                  key={i.title}
                  bg={COLOR.sixth}
                  w={isMobileOrTablet ? "100%" : "357px"}
                  h={isMobileOrTablet ? "100%" : "248px"}
                  borderLeft={
                    index === 0 ? "none" : `1px solid ${COLOR.quaternary}`
                  }
                >
                  <img width="64px" src={i.img} alt="" />
                  <Flex alignItems="center" gap="10px">
                    <Text
                      m={0}
                      textAlign="left"
                      fontSize={isMobile ? "30px" : "32px"}
                      fontWeight={700}
                      lineHeight="40px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      {i.number}
                    </Text>
                    <Text
                      m={0}
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="23.44px"
                      color={COLOR.sixteen}
                      fontFamily="DM Sans"
                    >
                      {i.title}
                    </Text>
                  </Flex>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={400}
                    lineHeight="18px"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                  >
                    {i.desc}
                  </Text>
                </Stack>
              ))}
            </Flex>
          )}
        </Stack>
        <img
          style={{
            position: "absolute",
            top: "80%",
            right: "-2%",
            zIndex: 1,
            display: isTabletOrLaptop ? "none" : "flex",
          }}
          width="257px"
          src={IMAGES.aboutTeam}
          alt=""
        />
      </Container>
      {/* <Stack bg="#0C5055" w="400px" h="400px" filter="blur(150px)" /> */}
      <img
        style={{
          display: isTabletOrLaptop ? "none" : "flex",

          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 0,
          opacity: 0.5,

          width: "30%",
        }}
        src={IMAGES.light}
        alt=""
      />
      <Stack id="SectionPortfolio">
        <SectionPortfolio />
      </Stack>
      <Section />

      {/* <StackedCenterCarousel mt="50px" /> */}
    </Stack>
  );
};

export default Section2;
