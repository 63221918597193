import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section1 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        {" "}
        Interpretation and Definitions
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Interpretation
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Definitions
      </Text>
    </Stack>
  );
};

export default Section1;
