import IMAGES from "../../../constant/API_ROUTES/images";

export const socials = [
  {
    img: IMAGES.xIcon,
    link: "https://twitter.com/onebit_ventures",
  },
  {
    img: IMAGES.facebookIcon,
    link: "https://www.facebook.com/OnebitVentures",
  },
  {
    img: IMAGES.telegramIcon,
    link: "https://t.me/onebit_ventures",
  },
];
