import { Flex, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Container from "../../../component/Container";
import TeamSlider from "../../../component/TeamSlider";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";

const Section4 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Container justifyContent="center" position="relative" mt="50px">
      <Stack
        sx={{
          width: "100%",
          height: "420px",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          style={{
            filter: "brightness(40%) blur(5px)",
            opacity: 0.7,
            height: "100vh",
            objectFit: "cover",
          }}
        >
          <source src="/video/Onebit3.mp4" type="video/mp4"></source>
        </video>
      </Stack>
      <Container
        position="absolute"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          p={isMobileOrTablet ? "30px 10px" : "40px 50px"}
          justifyContent="center"
          alignItems="center"
        >
          <Text
            textAlign="center"
            fontFamily="DM Sans"
            fontSize="24px"
            fontWeight={700}
            lineHeight="31.25px"
            color={COLOR.primary}
            m={0}
          >
            Our Team
          </Text>
          <Text
            w={isTabletOrLaptop ? "100%" : "700px"}
            textAlign="center"
            fontFamily="DM Sans"
            fontSize={isMobileOrTablet ? "25px" : "40px"}
            fontWeight={700}
            lineHeight={isMobileOrTablet ? "40px" : "48px"}
            color={COLOR.white}
            m={0}
          >
            We're really skilled at creating and growing tech companies
          </Text>
          <TeamSlider />
        </Stack>
      </Container>
    </Container>
  );
};

export default Section4;
