import {
  Stack,
  Text,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";

import React from "react";
import COLOR from "../../constant/API_ROUTES/color";
import Section1 from "./Section1";
import Container from "../../component/Container";
import useResponsive from "../../hooks/useResponsive";
import IMAGES from "../../constant/API_ROUTES/images";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import { Link } from "react-router-dom";

const title = [
  {
    id: "introduction",
    title: "Introduction",
  },
  {
    id: "introduction",
    title: "Interpretation and Definitions",
  },
  {
    id: "collecting",
    title: "Collecting and Using Your Personal Data",
  },
  {
    id: "disclosure",
    title: "Disclosure of Your Personal Data",
  },
  {
    id: "children",
    title: "Children's Privacy",
  },
  {
    id: "links",
    title: "Links to Other Websites",
  },
  {
    id: "changes",
    title: "Changes to this Privacy Policy",
  },
  {
    id: "contact",
    title: "Contact Us",
  },
];

const Privacy: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const link = window.location.href;
  const bttLink = (id: string) => {
    window.location.href = `#${id}`;
    window.history.replaceState(null, "", link);
  };
  return (
    <Stack
      p="120px 0 90px 0"
      bg={COLOR.sixth}
      sx={{
        backgroundImage: `url(${IMAGES.tron})`,
        backgroundSize: "cover",
        // backgroundPosition: "center",s
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container gap="10px" position="relative">
        <Stack
          w={isMobileOrTablet ? "30%" : "20%"}
          display={isMobile ? "none" : "flex"}
        >
          <Flex gap="5px">
            <Link to={APP_ROUTES.HOME}>
              <Text
                cursor="pointer"
                sx={{
                  fontFamily: "DM Sans5",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: COLOR.white,
                }}
              >
                Home
              </Text>
            </Link>
            <Text
              sx={{
                fontFamily: "DM Sans5",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: COLOR.thirteen,
              }}
            >
              &gt;
            </Text>
            <Text
              sx={{
                fontFamily: "DM Sans5",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: COLOR.twentyNine,
                borderBottom: `1px solid ${COLOR.twentyNine}`,
              }}
            >
              Privacy Policy
            </Text>
          </Flex>
          <Stack gap="10px">
            {title.map((item, index) => (
              <Stack
                cursor="pointer"
                transition="all 235ms ease-in-out"
                border="2px solid transparent"
                key={index}
                w="170px"
                onClick={() => bttLink(item.id)}
                _hover={{
                  width: "170px",

                  padding: "10px 8px",
                  borderRadius: "12px",
                  border: `2px solid ${COLOR.nineteen}`,
                }}
              >
                <Text
                  w="170px"
                  m={0}
                  _hover={{
                    width: "100%",
                    color: COLOR.white,
                  }}
                  sx={{
                    fontFamily: "DM Sans5",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: COLOR.fourteen,
                  }}
                >
                  {item.title}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack
          w={isMobile ? "100%" : isMobileOrTablet ? "70%" : "80%"}
          zIndex={1}
        >
          <Text
            m={0}
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "36px",
              lineHeight: "52px",
              color: COLOR.white,
              textAlign: "center",
            }}
          >
            {" "}
            Privacy Policy
          </Text>
          <Text
            m={0}
            sx={{
              fontFamily: "DM Sans4",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "28px",
              color: COLOR.fourteen,
              textAlign: "center",
            }}
          >
            {" "}
            Last updated: March 09, 2024
          </Text>
          <Stack id="definitions">
            <Section1 />
          </Stack>
          <Stack id="collecting">
            <Section2 />
          </Stack>
          <Stack id="disclosure">
            <Section3 />
          </Stack>
          <Stack id="children">
            <Section4 />
          </Stack>
          <Stack id="links">
            <Section5 />
          </Stack>

          <Stack id="changes">
            <Section6 />
          </Stack>
          <Stack id="contact">
            <Section7 />
          </Stack>
        </Stack>
      </Container>
      <img
        src={IMAGES.poCircle}
        style={{
          display: isMobileOrTablet ? "none" : "flex",
          position: "absolute",
          top: "85%",
          left: "10%",
          width: "500px",
          opacity: 0.7,
        }}
        alt=""
      />
    </Stack>
  );
};
export default Privacy;
