import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { Link, useLocation } from "react-router-dom";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import MenuIcon from "../Icon/Menu";
import ModalComponent from "../ModalForm";
import { useEffect, useState } from "react";
function Nav() {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const location = useLocation();
  const [tabID, setTabID] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabID = searchParams.get("contact");
    if (tabID === "1") {
      onOpen();
      setTabID(0);
    } else if (tabID === "2") {
      setTabID(1);
      onOpen();
    }
  }, []);
  return (
    <Flex
      w="full"
      direction="column"
      h="5.3rem"
      position="fixed"
      zIndex={99}
      backdropFilter="blur(10px)"
      bg="#0E0C151F"
      borderBottom={`1px solid ${COLOR.colorButton}`}
    >
      <Container>
        <Flex w="full" h={isMobile ? "60px" : "50px"} alignItems="center">
          <Container>
            <Flex
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Flex w="full" mt="2rem">
                {isMobile ? (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                    gap="20px"
                  >
                    <Link to={APP_ROUTES.HOME} onClick={handleLabelClick}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={IMAGES.logoWeb}
                        alt=""
                        width="127px"
                        height="150px"
                      />
                    </Link>

                    <Flex
                      w="130px"
                      p="0 16px"
                      h="44px"
                      gap="7px"
                      justifyContent="center"
                      alignItems="center"
                      bg={COLOR.bgButton}
                      borderRadius="1000px"
                      transition="all 235ms ease-in-out"
                      cursor="pointer"
                      _hover={{
                        transform: "translate3d(0, 2px, 0)",
                      }}
                      onClick={onOpen}
                    >
                      <Text
                        fontFamily="DM Sans"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="22px"
                        color={COLOR.colorButton}
                        m={0}
                      >
                        Pitch Us
                      </Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Flex>
                    <Menu>
                      <MenuButton
                        bg={COLOR.bgButton}
                        borderRadius="50%"
                        as={IconButton}
                        aria-label="Options"
                        variant="outline"
                        border="none"
                        _hover={{
                          background: "none",
                        }}
                        icon={<MenuIcon />}
                      />
                      <MenuList p="10px">
                        <Link to={APP_ROUTES.HOME} onClick={handleLabelClick}>
                          <Text
                            paddingX="1rem"
                            fontWeight={700}
                            fontFamily="DM Sans"
                          >
                            Home
                          </Text>
                        </Link>
                        <Link
                          to={APP_ROUTES.PROFILE}
                          onClick={handleLabelClick}
                        >
                          <Text
                            paddingX="1rem"
                            fontWeight={700}
                            fontFamily="DM Sans"
                          >
                            Profile
                          </Text>
                        </Link>
                        <Link to={APP_ROUTES.NEWS} onClick={handleLabelClick}>
                          <Text
                            paddingX="1rem"
                            fontWeight={700}
                            fontFamily="DM Sans"
                          >
                            Blogs
                          </Text>
                        </Link>
                        <Link to={APP_ROUTES.ABOUT} onClick={handleLabelClick}>
                          <Text
                            paddingX="1rem"
                            fontWeight={700}
                            fontFamily="DM Sans"
                          >
                            Career
                          </Text>
                        </Link>

                        <ModalComponent
                          tabID={tabID}
                          isOpen={isOpen}
                          onClose={onClose}
                        />
                      </MenuList>
                    </Menu>
                  </Flex>
                ) : (
                  <Container>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      width="full"
                    >
                      <Link to={APP_ROUTES.HOME} onClick={handleLabelClick}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "127.38px",
                            height: "40px",
                          }}
                          src={IMAGES.logoWeb}
                          alt=""
                        />
                      </Link>

                      <Flex gap="2rem" alignItems="center">
                        <Link to={APP_ROUTES.HOME} onClick={handleLabelClick}>
                          <Text
                            fontWeight={400}
                            fontFamily="DM Sans4"
                            m={0}
                            fontSize="16px"
                            fontStyle="normal"
                            lineHeight="24px"
                            _hover={{
                              borderBottom: `1px solid ${COLOR.primary}`,
                              color: COLOR.primary,
                            }}
                            borderBottom={
                              location.pathname === APP_ROUTES.HOME
                                ? `1px solid ${COLOR.primary}`
                                : "none"
                            }
                            color={
                              location.pathname === APP_ROUTES.HOME
                                ? COLOR.primary
                                : COLOR.secondary
                            }
                          >
                            Home
                          </Text>
                        </Link>

                        <Link
                          to={APP_ROUTES.PROFILE}
                          onClick={handleLabelClick}
                        >
                          <Text
                            m={0}
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight={400}
                            fontFamily="DM Sans4"
                            lineHeight="24px"
                            _hover={{
                              borderBottom: `1px solid ${COLOR.primary}`,
                              color: COLOR.primary,
                            }}
                            borderBottom={
                              location.pathname === APP_ROUTES.PROFILE
                                ? `1px solid ${COLOR.primary}`
                                : "none"
                            }
                            color={
                              location.pathname === APP_ROUTES.PROFILE
                                ? COLOR.primary
                                : COLOR.secondary
                            }
                          >
                            Profile
                          </Text>
                        </Link>
                        <Link to={APP_ROUTES.NEWS} onClick={handleLabelClick}>
                          <Text
                            fontWeight={400}
                            fontFamily="DM Sans4"
                            m={0}
                            fontSize="16px"
                            fontStyle="normal"
                            lineHeight="24px"
                            _hover={{
                              borderBottom: `1px solid ${COLOR.primary}`,
                              color: COLOR.primary,
                            }}
                            borderBottom={
                              location.pathname === APP_ROUTES.NEWS
                                ? `1px solid ${COLOR.primary}`
                                : "none"
                            }
                            color={
                              location.pathname === APP_ROUTES.NEWS
                                ? COLOR.primary
                                : COLOR.secondary
                            }
                          >
                            Blogs
                          </Text>
                        </Link>
                        <Link to={APP_ROUTES.ABOUT} onClick={handleLabelClick}>
                          <Text
                            fontWeight={400}
                            fontFamily="DM Sans4"
                            m={0}
                            fontSize="16px"
                            fontStyle="normal"
                            lineHeight="24px"
                            _hover={{
                              borderBottom: `1px solid ${COLOR.primary}`,
                              color: COLOR.primary,
                            }}
                            borderBottom={
                              location.pathname === APP_ROUTES.ABOUT
                                ? `1px solid ${COLOR.primary}`
                                : "none"
                            }
                            color={
                              location.pathname === APP_ROUTES.ABOUT
                                ? COLOR.primary
                                : COLOR.secondary
                            }
                          >
                            Career
                          </Text>
                        </Link>
                        <Flex
                          w="148px"
                          p="0 16px"
                          h="44px"
                          gap="7px"
                          justifyContent="center"
                          alignItems="center"
                          bg={COLOR.bgButton}
                          borderRadius="1000px"
                          transition="all 235ms ease-in-out"
                          cursor="pointer"
                          _hover={{
                            transform: "translate3d(0, 2px, 0)",
                          }}
                          onClick={onOpen}
                        >
                          <Text
                            fontFamily="DM Sans"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="22px"
                            color={COLOR.colorButton}
                            m={0}
                          >
                            Pitch Us
                          </Text>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Flex>
                        <ModalComponent
                          tabID={tabID}
                          isOpen={isOpen}
                          onClose={onClose}
                        />
                      </Flex>
                    </Flex>
                  </Container>
                )}
              </Flex>
            </Flex>
          </Container>
        </Flex>
      </Container>
    </Flex>
  );
}

export default Nav;
