import { Flex, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import Container from "../Container";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  tabID: any;
}

const TabForm: React.FC<TabsProps> = ({ tabs, tabID }) => {
  const [activeTab, setActiveTab] = useState(tabID);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex justifyContent="center" w="full" position="relative">
      <Container direction="column" w="full">
        <Stack>
          <Flex
            p="20px 0"
            gap="10px"
            w="full"
            alignItems="center"
            justifyContent="center"
          >
            {tabs.map((tab, index) => (
              <Flex
                cursor="pointer"
                p="6px 16px"
                justifyContent="center"
                key={index}
                className={`tab-item ${index === activeTab ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
                borderRadius="4px"
                bg={index === activeTab ? COLOR.twentySeven : "none"}
                border={
                  index === activeTab ? `1px solid ${COLOR.eighth}` : "none"
                }
              >
                <Text
                  textAlign="center"
                  color={index === activeTab ? COLOR.white : COLOR.white}
                  fontFamily="DM Sans"
                  fontSize="14px"
                  fontWeight={700}
                  lineHeight="22px"
                  m={0}
                >
                  {tab.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Stack>
        <Stack spacing={0}>{tabs[activeTab].content}</Stack>
      </Container>
    </Flex>
  );
};

export default TabForm;
