const APP_ROUTES = {
  HOME: "/",
  ECOSYSTEM: "/ecosystem",
  // PORTFOLIO: "/portfolio",
  PROFILE: "/profile",
  NEWS: "/blogs",
  ABOUT: "/about",
  NEWSDETAIL: `/blogs/detail`,
  sectionPortfolio: "/portfolio",
  PRIVACY: "/privacy",
  TERM: "/term",
  INTRODUCTION: "/introduction",
  DISCLOSURE: "/disclosure",
  DEFINITIONS: "/definitions",
  COLLECTING: "/collecting",
  CHILDREN: "/children",
  LINKS: "/links",
  CHANGES: "/changes",
  CONTACT: "/contact",
  INTERPRETATION: "interpretation",
  DATA: "/data",
  ACKNOWLEDGMENT: "acknowledgment",
  WEBSITES: "/websites",
  TERMINATION: "/termination",
  LIMITATION: "/limitation",
  GOVERNING: "/governing",
  RESOLUTION: "/resolution",
  CAREERDETAIL: `/career/detail`,
};

export default APP_ROUTES;
