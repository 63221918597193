const IMAGES = {
  bel: "/logoV2/bel.png",
  bho: "/logoV2/bho.png",
  blx: "/logoV2/blx.png",
  ccu: "/logoV2/ccu.png",
  cloudcmc: "/logoV2/cloudcmc.png",
  coin: "/logoV2/coin.png",
  coinbay: "/logoV2/coinbay.png",
  ethVN: "/logoV2/ethVN.png",
  fpt: "/logoV2/fpt.png",
  kardi: "/logoV2/kardi.png",
  platinum: "/logoV2/platinum.png",
  dts: "/logoV2/dts.png",
  amc: "/logoV2/amc.png",
  bd: "/logoV2/BD.png",
  pv: "/logoV2/pv.png",
  sui: "/logoV2/sui.png",
  w3w: "/logoV2/w3w.png",
  poBanner: "/images/poBanner.png",
  tron: "/images/maskG.png",
  search: "/images/iconSearch.svg",
  BGBlog: "/images/BGBlog.png",
  avatar: "/images/Avatar.png",
  kaisen: "/images/kengan.png",

  blog1: "/images/blog1.png",
  blog2: "/images/blog2.png",
  blog3: "/images/blog3.png",
  blog4: "/images/blog4.png",
  blog5: "/images/blog5.png",
  blog6: "/images/blog6.png",
  blog7: "/images/blog7.png",
  blog8: "/images/blog8.png",
  blog9: "/images/blog9.png",
  blog10: "/images/blog10.png",

  Picture1: "/images/Picture1.png",
  Picture2: "/images/Picture2.png",
  Picture3: "/images/Picture3.png",
  Picture4: "/images/Picture4.png",
  Picture5: "/images/Picture5.png",
  Picture6: "/images/Picture6.png",
  Picture7: "/images/Picture7.png",
  Picture8: "/images/Picture8.png",
  Picture9: "/images/Picture9.png",
  Picture10: "/images/Picture10.png",

  career5: "/images/career5.png",
  career6: "/images/career6.png",
  career7: "/images/career7.png",
  career8: "/images/career8.png",
  career9: "/images/career9.png",
  career10: "/images/career10.png",
  iconC1: "/images/iconC1.svg",
  iconC2: "/images/iconC2.svg",
  iconC3: "/images/iconC3.svg",
  iconC4: "/images/iconC4.svg",
  screen: "/images/Screen.png",
  bannerC: "/images/bannerC.png",

  cBg: "/images/CBg.png",
  IC1: "/images/IC1.svg",
  IC2: "/images/IC2.svg",
  IC3: "/images/IC3.svg",
  IC4: "/images/IC4.svg",
  StepperGroup: "/images/StepperGroup.png",

  CD1: "/images/CD1.png",
  CD2: "/images/CD2.png",
  CD3: "/images/CD3.png",
  CD4: "/images/CD4.png",
  CD5: "/images/CD5.png",
  Icon: "/images/Icon.svg",

  p1: "/images/p1.svg",
  p2: "/images/p2.svg",
  p3: "/images/p3.svg",
  p4: "/images/p4.svg",

  poCircle: "/images/poCircle.png",
  po1: "/logoV2/po1.png",
  po2: "/logoV2/po2.png",
  po3: "/logoV2/po3.png",
  po4: "/logoV2/po4.png",
  po5: "/logoV2/po5.png",
  po6: "/logoV2/po6.png",
  po7: "/logoV2/po7.png",
  po8: "/logoV2/po8.png",
  po9: "/logoV2/po9.png",
  po10: "/logoV2/po10.png",
  po11: "/logoV2/po1.1png",
  po12: "/logoV2/po1.p2ng",
  po13: "/logoV2/po1.pn3g",

  bar: "/images/bar.png",
  arbi: "/logoV2/arbi.png",
  ava: "/logoV2/ava.png",
  bnb: "/logoV2/bnb.png",
  eth: "/logoV2/eth.png",
  carda: "/logoV2/carda.png",
  mantle: "/logoV2/mantle.png",
  matic: "/logoV2/matic.png",
  near: "/logoV2/near.png",
  oke: "/logoV2/oke.png",
  opti: "/logoV2/opti.png",
  polk: "/logoV2/polk.png",
  solana: "/logoV2/solana.png",
  bannerSection1: "/images/heroBanner.png",
  bannerRocket: "/images/bannerRocket.png",
  bannerBlog: "/images/blogBanner.png",
  scheduleIcon: "/images/scheduleIcon.png",
  bannerFrame: "/images/Frame.png",
  logoWeb: "/logo/LogoV2.png",
  strategyV2: "/logoV2/strategy.svg",
  connectionV2: "/logoV2/connection.svg",
  financeV2: "/logoV2/finance.svg",
  invested: "/logoV2/invested.svg",
  kol: "/logoV2/kol.svg",
  check: "/logoV2/CheckIcon.svg",
  bc: "/logoV2/bc.png",
  bsc: "/logoV2/bsc.png",
  project: "/logoV2/project.svg",
  cmc: "/logoV2/cmc.png",
  dc: "/logoV2/dc.png",
  kts: "/logoV2/kts.png",
  kdg: "/logoV2/kdg.png",
  ku: "/logoV2/ku.png",
  ts: "/logoV2/ts.png",
  xt: "/logoV2/xt.png",
  ps: "/logoV2/ps.png",
  dnt: "/logoV2/dnt.png",
  icon1: "/logoV2/1.png",
  icon2: "/logoV2/2.png",
  icon3: "/logoV2/3.png",
  icon4: "/logoV2/4.png",
  icon5: "/logoV2/5.png",
  elemon: "/logoV2/elemon.png",
  bscC: "/logoV2/bscC.png",
  cogi: "/logoV2/cogi.png",
  dk: "/logoV2/dk.png",
  dl: "/logoV2/dl.png",
  gb: "/logoV2/gb.png",
  ge: "/logoV2/ge.png",
  hwl: "/logoV2/hwl.png",
  qorpo: "/logoV2/qorpo.png",

  lfw: "/logoV2/lfw.png",
  lr: "/logoV2/lr.png",
  lsc: "/logoV2/lsc.png",
  sidus: "/logoV2/sidus.png",
  sn: "/logoV2/sn.png",
  tp: "/logoV2/tp.png",
  zm: "/logoV2/zm.png",
  cl: "/logoV2/cl.png",
  light: "/logoV2/light.png",
  lightL: "/logoV2/lightL.png",
  al: "/logoV2/arrowLeft.svg",
  ar: "/logoV2/arrowRight.svg",
  telegramIcon: "/logoV2/IconTele.svg",
  xIcon: "/logoV2/IconX.svg",
  inIcon: "/logoV2/IconIn.svg",
  aboutTeam: "/images/aboutTeam.png",
  section1: "/images/section1.png",
  dntreLogo: "/logo/dntre.png",
  onBlockLogo: "/logo/onBlock.png",
  bigcoinLogo: "/logo/bigcoin.png",
  platiumLogo: "/logo/platium.png",
  ktsLogo: "/logo/kts.png",
  zbsLogo: "/logo/zbs.png",
  danaLogo: "/logo/dana.png",
  kvLogo: "/logo/kv.png",
  dntLogo: "/logo/dnt.png",
  caloLogo: "/logo/calo.png",
  hydraLogo: "/logo/hydra.png",
  vuonuomLogo: "/logo/vuonuom.png",
  onewayLogo: "/logo/oneway.png",
  sidusLogo: "/logo/sidus.png",
  daoLogo: "/logo/dao.png",
  gaiaLogo: "/logo/gaia.png",
  cypherLogo: "/logo/cypher.png",
  dropsLogo: "/logo/drops.png",
  f6sLogo: "/logo/f6s.png",
  carpLogo: "/logo/carp.png",
  pitchLogo: "/logo/pitch.png",
  crunchLogo: "/logo/crunch.png",
  chainLogo: "/logo/chain.png",
  webIcon: "/logo/web.svg",
  facebookIcon: "/logoV2/IconFacebook.svg",
  gitIcon: "/logoV2/IconGithub.svg",
  map: "/images/map.png",
  line: "/images/line.png",
  mapMobile: "/images/mapMobile.png",
  cardB1: "/images/cardB1.png",
  cardB2: "/images/cardB2.png",
  B3: "/images/B3.png",
  instagramIcon: "/logo/insta.svg",
  coinIcon: "/logo/coin.svg",
  strategyIcon: "/logo/strategy.svg",
  connectionIcon: "/logo/connection.svg",
  marketingIcon: "/logo/marketing.svg",
  wellIcon: "/logo/well.svg",
  advisedIcon: "/logo/advised.svg",
  connectedIcon: "/logo/connected.svg",
  supportedIcon: "/logo/supported.svg",
  ecosystem: "/images/ecosystem.png",
  ecosystemBanner: "/images/ecosystemBanner.png",
  onetripLogo: "/logo/onetrip.png",
  everworldLogo: "/logo/everworld.png",
  geLogo: "/logo/ge.png",
  dareLogo: "/logo/dare.png",
  gliiterLogo: "/logo/gliiter.png",
  polkaLogo: "/logo/polka.png",
  byteLogo: "/logo/byte.png",
  shopdiLogo: "/logo/shopdi.png",

  b3: "/images/b3.png",
  b4: "/images/b4.png",
  b5: "/images/b5.png",
  b6: "/images/b6.png",
  bgBanner: "/images/bgBanner.png",
  section: "/images/SectionFind.png",
  founder: "/staff/founder.png",
  chief: "/staff/chief.png",
  coFounder: "/staff/cofounder.png",
  executive: "/staff/executive.png",
  officer: "/staff/officer.png",
  tech: "/staff/LVP1.png",
  supervisor: "/staff/supervisor.png",
  manager: "/staff/manager.png",
  accounting: "/staff/accounting.png",
  financial: "/staff/financial.png",
  rossy: "/staff/roxy.png",
  phoebe: "/staff/phoebe.png",
  scott: "/staff/scott.png",
  rocket: "/images/rocket.png",
  new1: "/images/n1.png",
  new2: "/images/new2.png",
  new3: "/images/new3.png",
  new5: "/images/new5.png",
  new6: "/images/new6.png",
  card1: "/images/card1.png",
  card2: "/images/card2.png",
  card3: "/images/card3.png",
  vector: "/images/Vector.png",
  vector2: "/images/vector2.png",
  bubble: "/bubble/bubble.png",
  bubble2: "/bubble/bubble2.png",
  bubble3: "/bubble/bubble3.png",
  bubble4: "/bubble/bubble4.png",
  bubble5: "/bubble/bubble5.png",
  bubble6: "/bubble/bubble6.png",
  bubble7: "/bubble/bubble7.png",
  bubble8: "/bubble/bubble8.png",
  bubble9: "/bubble/bubble9.png",
  bubble10: "/bubble/bubble10.png",
  bubble11: "/bubble/bubble11.png",
  bubble12: "/bubble/bubble12.png",
  bubbleHover: "/bubble/hoverBubble.png",
  bubble2Hover: "/bubble/hoverBubble2.png",
  bubble3Hover: "/bubble/hoverBubble3.png",
  bubble4Hover: "/bubble/hoverBubble4.png",
  bubble5Hover: "/bubble/hoverBubble5.png",
  bubble6Hover: "/bubble/hoverBubble6.png",
  bubble7Hover: "/bubble/hoverBubble7.png",
  bubble8Hover: "/bubble/hoverBubble8.png",
  bubble9Hover: "/bubble/hoverBubble9.png",
  bubble10Hover: "/bubble/hoverBubble10.png",
  bubble11Hover: "/bubble/hoverBubble11.png",
  bubble12Hover: "/bubble/hoverBubble12.png",
};

export default IMAGES;
