import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import APP_ROUTES from "./constant/API_ROUTES/app_routes";
import "./index.css";
import About from "./pages/About";
import Ecosystem from "./pages/Ecosystem";
import Home from "./pages/Home";
import SectionPortfolio from "./pages/Home/SectionPortfolio";
import News from "./pages/News";
import NewsDetails from "./pages/News/NewsDetails";
import Portfolio from "./pages/Portfolio";
import Privacy from "./pages/Privacy";
import Section1 from "./pages/Privacy/Section1";
import Section2 from "./pages/Privacy/Section2";
import Section3 from "./pages/Privacy/Section3";
import Section4 from "./pages/Privacy/Section4";
import Section5 from "./pages/Privacy/Section5";
import Section6 from "./pages/Privacy/Section6";
import Section7 from "./pages/Privacy/Section7";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import Term from "./pages/Term";
import Section8 from "./pages/Term/Section8";
import Governing from "./pages/Term/Governing";
import Limitation from "./pages/Term/Limitation";
import Termination from "./pages/Term/Termination";
import Websites from "./pages/Term/Websites";
import Acknowledgment from "./pages/Term/Acknowledgment";
import DataPersonal from "./pages/Term/DataPersonal";
import Interpretation from "./pages/Term/Interpretation";
import AboutDetail from "./pages/About/AboutDetail";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>
    <Router>
      <Navbar />
      <Routes>
        <Route path={APP_ROUTES.HOME} element={<Home />} />
        <Route path={APP_ROUTES.ABOUT} element={<About />} />
        <Route path={APP_ROUTES.ECOSYSTEM} element={<Ecosystem />} />
        <Route path={APP_ROUTES.PROFILE} element={<Portfolio />} />
        <Route path={APP_ROUTES.NEWS} element={<News />} />
        <Route path={APP_ROUTES.CAREERDETAIL} element={<AboutDetail />} />

        <Route
          path={`${APP_ROUTES.NEWSDETAIL}/:_id`}
          element={<NewsDetails />}
        />
        <Route path={APP_ROUTES.PRIVACY} element={<Privacy />} />
        <Route
          path={APP_ROUTES.sectionPortfolio}
          element={<SectionPortfolio />}
        />
        <Route path={APP_ROUTES.DEFINITIONS} element={<Section1 />} />
        <Route path={APP_ROUTES.COLLECTING} element={<Section2 />} />
        <Route path={APP_ROUTES.DISCLOSURE} element={<Section3 />} />
        <Route path={APP_ROUTES.CHILDREN} element={<Section4 />} />
        <Route path={APP_ROUTES.LINKS} element={<Section5 />} />
        <Route path={APP_ROUTES.CHANGES} element={<Section6 />} />
        <Route path={APP_ROUTES.CONTACT} element={<Section7 />} />
        <Route path={APP_ROUTES.TERM} element={<Term />} />
        <Route path={APP_ROUTES.INTERPRETATION} element={<Interpretation />} />
        <Route path={APP_ROUTES.DATA} element={<DataPersonal />} />
        <Route path={APP_ROUTES.ACKNOWLEDGMENT} element={<Acknowledgment />} />
        <Route path={APP_ROUTES.WEBSITES} element={<Websites />} />
        <Route path={APP_ROUTES.TERMINATION} element={<Termination />} />
        <Route path={APP_ROUTES.LIMITATION} element={<Limitation />} />
        <Route path={APP_ROUTES.GOVERNING} element={<Governing />} />
        <Route path={APP_ROUTES.RESOLUTION} element={<Section8 />} />
      </Routes>

      <Footer />
    </Router>
  </ChakraProvider>
);
reportWebVitals();
