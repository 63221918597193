import { Flex, Stack, Text } from "@chakra-ui/react";
import Container from "../../../component/Container";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import { isFirefox } from "react-device-detect";
import { useEffect, useState } from "react";
// const videos = ["/video/Onebit1.mp4", "/video/Onebit4.mov"];
const Section1 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  // const initialVideoIndex: number = parseInt(
  //   localStorage.getItem("currentVideoIndex") || "0"
  // );

  // const [currentVideoIndex, setCurrentVideoIndex] = useState(initialVideoIndex);

  // const changeVideo = () => {
  //   const newIndex = currentVideoIndex === 0 ? 1 : 0;
  //   setCurrentVideoIndex(newIndex);
  //   localStorage.setItem("currentVideoIndex", newIndex.toString());
  // };

  // useEffect(() => {
  //   changeVideo();
  // }, []);

  return (
    <Stack position="relative" spacing={0}>
      <Stack position="relative">
        <Stack
          // bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 5.15%, #000000 100%, rgba(0, 0, 0, 0) 100%)"
          bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 27.15%, #000000 67.01%, rgba(0, 0, 0, 0) 100%)"
          opacity="0.5"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="20%"
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: "100vh",
            objectFit: "cover",
            display: isFirefox ? "none" : "block",
          }}
        >
          <source src="/video/Onebit1.mp4" type="video/mp4"></source>
        </video>
        <iframe
          style={{
            height: "100vh",
            minWidth: 1400,
            display: isFirefox ? "block" : "none",
          }}
          src="https://www.youtube.com/embed/9_7Zmg-pCAE?&autoplay=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=9_7Zmg-pCAE"
          allowFullScreen
        ></iframe>
        <Stack
          // bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 5.15%, #000000 100%, rgba(0, 0, 0, 0) 100%)"
          bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 27.15%, #000000 67.01%, rgba(0, 0, 0, 0) 100%)"
          opacity="0.5"
          position="absolute"
          top="90%"
          left="0"
          width="100%"
          height="20%"
        />
      </Stack>

      <Container>
        <Stack
          top={isMobileOrTablet ? "10%" : "20%"}
          left="0"
          position="absolute"
          justifyContent="center"
          alignItems="center"
          width="full"
          direction="column"
        >
          <Text
            textAlign="center"
            m={0}
            w={isTabletOrLaptop ? "100%" : "900px"}
            sx={{
              lineHeight: isMobile ? "44px" : "72px",
              fontWeight: 700,
              fontSize: isMobile ? "30px" : "64px",
              color: COLOR.sixteen,
              marginTop: isMobile ? "50px" : "10px",
            }}
            fontFamily="DM Sans"
          >
            Connecting & Investing in Blockchain Technology
          </Text>
          <Text
            textAlign="center"
            m={0}
            sx={{
              lineHeight: "30px",
              fontWeight: 400,
              fontSize: "17px",
              color: COLOR.twentyFive,
            }}
            fontFamily="DM Sans4"
          >
            Building, connecting and supporting projects and investors in the
            field of blockchain technology
          </Text>
          <Flex
            gap="10px"
            flexWrap="wrap"
            justifyContent="center"
            direction={isMobileOrTablet ? "row" : "row"}
            display={isTabletOrLaptop ? "flex" : "none"}
          >
            <Flex
              gap="10px"
              borderRadius="8px"
              p="16px 24px"
              bg={COLOR.bgTrans}
              backdropFilter="blur(10px)"
            >
              <Text
                m={0}
                sx={{
                  lineHeight: "25.6px",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: COLOR.white,
                }}
                fontFamily="DM Sans4"
              >
                Strategy
              </Text>
              <img src={IMAGES.strategyV2} alt="" />
            </Flex>
            <Flex
              gap="10px"
              borderRadius="8px"
              p="16px 24px"
              bg={COLOR.bgTrans}
              backdropFilter="blur(10px)"
            >
              <img src={IMAGES.connectionV2} alt="" />
              <Text
                m={0}
                sx={{
                  lineHeight: "25.6px",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: COLOR.white,
                }}
                fontFamily="DM Sans4"
              >
                Connection
              </Text>
            </Flex>
            <Flex
              gap="10px"
              borderRadius="8px"
              p="16px 24px"
              bg={COLOR.bgTrans}
              backdropFilter="blur(10px)"
            >
              <img src={IMAGES.financeV2} alt="" />
              <Text
                m={0}
                sx={{
                  lineHeight: "25.6px",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: COLOR.white,
                }}
                fontFamily="DM Sans4"
              >
                Finance
              </Text>
            </Flex>
          </Flex>
        </Stack>

        <Stack display={isTabletOrLaptop ? "none" : "flex"}>
          <Flex
            top={isMobileOrTablet ? "50%" : "47%"}
            left={isMobileOrTablet ? "10%" : "65%"}
            position="absolute"
            gap="10px"
            borderRadius="8px"
            p="16px 24px"
            bg={COLOR.bgTrans}
            backdropFilter="blur(10px)"
          >
            <Text
              m={0}
              sx={{
                lineHeight: "25.6px",
                fontWeight: 400,
                fontSize: "16px",
                color: COLOR.white,
              }}
              fontFamily="DM Sans4"
            >
              Strategy
            </Text>
            <img src={IMAGES.strategyV2} alt="" />
          </Flex>
          <Flex
            top={isMobileOrTablet ? "60%" : "55%"}
            left={isMobileOrTablet ? "55%" : "75%"}
            position="absolute"
            gap="10px"
            borderRadius="8px"
            p="16px 24px"
            bg={COLOR.bgTrans}
            backdropFilter="blur(10px)"
          >
            <img src={IMAGES.connectionV2} alt="" />
            <Text
              m={0}
              sx={{
                lineHeight: "25.6px",
                fontWeight: 400,
                fontSize: "16px",
                color: COLOR.white,
              }}
              fontFamily="DM Sans4"
            >
              Connection
            </Text>
          </Flex>
          <Flex
            top={isMobileOrTablet ? "40%" : isTabletOrLaptop ? "65%" : "29%"}
            right="8%"
            position="absolute"
            gap="10px"
            borderRadius="8px"
            p="16px 24px"
            bg={COLOR.bgTrans}
            backdropFilter="blur(10px)"
          >
            <img src={IMAGES.financeV2} alt="" />
            <Text
              m={0}
              sx={{
                lineHeight: "25.6px",
                fontWeight: 400,
                fontSize: "16px",
                color: COLOR.white,
              }}
              fontFamily="DM Sans4"
            >
              Finance
            </Text>
          </Flex>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Section1;
