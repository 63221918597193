const COLOR = {
  primary: " #38EDF9",
  red: "red",
  secondary: "#B8B8B8",
  bgButton:
    "linear-gradient(269.09deg, #1ABCFC 11.71%, #38EDF9 46.29%, #1ABCFC 106.75%)",
  colorButton: "#1D2939",
  bgTrans:
    "linear-gradient(95.36deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.01) 100%)",
  tertiary: "#4F4F4F1A",
  quaternary: "#E1E1E21F",
  fifth: "#A1A1AA",
  sixth: "#141414",
  seventh: "#1B1818",
  eighth: "#434343",
  ninth: "#17141F73",
  tenth: "#1A1A1A99",
  eleventh: "#1A1A1A14",
  twelve: "#27272A",
  thirteen: "#747474",
  fourteen: "#FFFFFFB2",
  fifteen: "#088EAF",
  sixteen: "#FCFCFC",
  seventeen: "#E6E8EC",
  eighteen: "#3D3D3D",
  nineteen: "#20D3EE",
  twenty: "FFFFFF1F",
  twentyOne: "#181818",
  twentyTwo: "#343434",
  twentyThree: "#4F4F4F33",
  twentyFour: "#1A1A1A5C",
  bg: "#FFFFFF2B",
  twentyFive: "#FFFFFFA3",
  twentySix: "#FCFCFD",
  twentySeven: "#231F1F",
  twentyEight: "#FFFFFF1F",
  twentyNine: "#50A1FF",

  ins: "#BC2A8D",
  black: "#000",
  white: "#fff",
  fb: "#3B5998",
  LinkedIn: "#0077B5",
  bar: "#FDE0A1",
  bgS2: "rgba(255, 255, 255, 0.05)",
  bgNav: "rgba(255, 255, 255, 0.10)",
  borderReputation: "#637381",
  circle: "#3F4753",
  bgFooter: "#0F1928",
  divider: "#6F757E",
  background: "#161C24",
  borderAbout: "#919EAB",
  borderHover: "#05DDBE",
  borderCard: " #0889DD",
  blackShadow: "rgba(0, 0, 0, 0.20)",
  backG: "linear-gradient(180deg, #040D16 0%, rgba(4, 13, 22, 0.00) 100%)",
  line: "linear-gradient(180deg, rgba(0, 3, 7, 0) -95%, #000307 100%)",
  dividerAbout: "#C4CDD5",
  bgSlide: "#212B36",
  bgNews: "rgba(0, 0, 0, 0.10)",
  tab: "linear-gradient(121.41deg, #05DDBE 0%, #0889DD 100%)",
};

export default COLOR;
