import { Stack, Text, Flex } from "@chakra-ui/react";

import React from "react";
import COLOR from "../../constant/API_ROUTES/color";
import Container from "../../component/Container";
import useResponsive from "../../hooks/useResponsive";
import IMAGES from "../../constant/API_ROUTES/images";
import Section7 from "./Governing";
import Section6 from "./Limitation";
import Section5 from "./Termination";
import Section4 from "./Websites";
import Section3 from "./Acknowledgment";
import Section2 from "./DataPersonal";
import Section1 from "./Interpretation";
import Section8 from "./Section8";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import { Link } from "react-router-dom";

const title = [
  {
    id: "interpretation",
    title: "Interpretation and Definitions",
  },
  {
    id: "data",
    title: "Collecting and Using Your Personal Data",
  },
  {
    id: "acknowledgment",
    title: "Acknowledgment",
  },
  {
    id: "websites",
    title: "Links to Other Websites",
  },
  {
    id: "termination",
    title: "Termination",
  },
  {
    id: "limitation",
    title: "Limitation of Liability",
  },
  {
    id: "governing ",
    title: "Governing Law",
  },
  {
    id: "resolution",
    title: "Disputes Resolution",
  },
];

const Term: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const link = window.location.href;
  const bttLink = (id: string) => {
    window.location.href = `#${id}`;
    window.history.replaceState(null, "", link);
  };
  return (
    <Stack
      p="120px 0 90px 0"
      bg={COLOR.sixth}
      sx={{
        backgroundImage: `url(${IMAGES.tron})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container gap="10px" position="relative">
        <Stack
          gap="10px"
          w={isMobileOrTablet ? "30%" : "20%"}
          display={isMobile ? "none" : "flex"}
        >
          <Flex gap="5px">
            <Link to={APP_ROUTES.HOME}>
              <Text
                cursor="pointer"
                sx={{
                  fontFamily: "DM Sans5",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: COLOR.white,
                }}
              >
                Home
              </Text>
            </Link>
            <Text
              sx={{
                fontFamily: "DM Sans5",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: COLOR.thirteen,
              }}
            >
              &gt;
            </Text>
            <Text
              sx={{
                fontFamily: "DM Sans5",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: COLOR.twentyNine,
                borderBottom: `1px solid ${COLOR.twentyNine}`,
              }}
            >
              Terms and Conditions
            </Text>
          </Flex>
          {title.map((item, index) => (
            <Stack
              cursor="pointer"
              transition="all 235ms ease-in-out"
              border="2px solid transparent"
              key={index}
              w="170px"
              onClick={() => bttLink(item.id)}
              _hover={{
                width: "170px",

                padding: "10px 8px",
                borderRadius: "12px",
                border: `2px solid ${COLOR.nineteen}`,
              }}
            >
              <Text
                w="170px"
                m={0}
                _hover={{
                  width: "100%",
                  color: COLOR.white,
                }}
                sx={{
                  fontFamily: "DM Sans5",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: COLOR.fourteen,
                }}
              >
                {item.title}
              </Text>
            </Stack>
          ))}
        </Stack>

        <Stack
          w={isMobile ? "100%" : isMobileOrTablet ? "70%" : "80%"}
          zIndex={1}
        >
          <Text
            m={0}
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "36px",
              lineHeight: "52px",
              color: COLOR.white,
              textAlign: "center",
            }}
          >
            {" "}
            Terms and Conditions
          </Text>
          <Text
            m={0}
            sx={{
              fontFamily: "DM Sans4",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "28px",
              color: COLOR.fourteen,
              textAlign: "center",
            }}
          >
            {" "}
            Last updated: March 09, 2024
          </Text>
          <Text
            m={0}
            sx={{
              fontFamily: "DM Sans4",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "28px",
              color: COLOR.white,
              textAlign: "center",
            }}
          >
            {" "}
            Please read these terms and conditions carefully before using Our
            Service.
          </Text>
          <Stack id="interpretation">
            <Section1 />
          </Stack>
          <Stack id="data">
            <Section2 />
          </Stack>
          <Stack id="acknowledgment">
            <Section3 />
          </Stack>
          <Stack id="websites">
            <Section4 />
          </Stack>
          <Stack id="termination">
            <Section5 />
          </Stack>

          <Stack id="limitation">
            <Section6 />
          </Stack>
          <Stack id="governing">
            <Section7 />
          </Stack>
          <Stack id="resolution">
            <Section8 />
          </Stack>
        </Stack>
      </Container>
      <img
        src={IMAGES.poCircle}
        style={{
          display: isMobileOrTablet ? "none" : "flex",
          position: "absolute",
          top: "80%",
          left: "10%",
          width: "500px",
          opacity: 0.7,
        }}
        alt=""
      />
    </Stack>
  );
};
export default Term;
