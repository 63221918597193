import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section5 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        Links to Other Websites
      </Text>

      <Text
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </Text>
    </Stack>
  );
};

export default Section5;
