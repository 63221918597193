// ModalComponent.tsx
import { Input, Select, Stack, Text, Button, useToast } from "@chakra-ui/react";
import COLOR from "../../constant/API_ROUTES/color";
import { useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
const KOLSForm = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const URL = /^(ftp|http|https):\/\/[^ "]+$/;
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Your Full Name is required"),
    nickname: Yup.string().required("Your famous nickname is required"),
    language: Yup.string().required("Your language is required"),
    location: Yup.string().required("Country is required"),
    platform: Yup.string().required("Platform is required"),

    locationPlan: Yup.string().required(
      "Country or region do you plan on marketing in is required"
    ),
    brief: Yup.string()
      .required("Description type is required")
      .min(50, "Description must be at least 50 characters")
      .max(500, "Description must be at least 500 characters"),
    platformUrl: Yup.string().matches(URL, "Enter a valid url"),
    followers: Yup.number()
      .required("Enter number required")
      .nullable()
      .test(
        "noEOrSign",
        "Number had an 'e' or sign.",
        (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
      )
      .min(0),
    contactEmail: Yup.string()
      .required("Email of contact person is required")
      .email("Must be a valid email")
      .max(255),
  });
  const handleSubscribe = async (values: any, { resetForm }: any) => {
    setIsLoading(true);

    try {
      for (const key in values) {
        if (values[key] === "") {
          delete values[key];
        }
      }
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pitch`, {
        method: "POST",
        body: JSON.stringify({ type: "kols", data: values }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast({
          title: "Subscribe successful",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        resetForm();
      } else {
        const data = await response.json();
        if (data.error === "AlreadySubscribed") {
          toast({
            title: "You have already subscribed",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Failed to subscribe");
        }
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Failed to subscribe",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        name: "",
        platform: "",
        nickname: "",
        language: "",
        location: "",

        locationPlan: "",
        contactEmail: "",
        brief: "",
        platformUrl: "",
        followers: "",
        questions: "",
      }}
      validationSchema={RegisterSchema}
      onSubmit={handleSubscribe}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          <Stack w="full" className="form">
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Your Full Name
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="name">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  type="text"
                  autoComplete="off"
                  placeholder="Enter your Name"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.name && touched.name && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.name}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Your famous nickname
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="nickname">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  placeholder="Enter your nickname"
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.nickname && touched.nickname && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.nickname}
                </Text>
              )}
            </Stack>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Your language
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="language">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  placeholder="Enter your language"
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.language && touched.language && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.language}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              What country are you based in?
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="location">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter your country"
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.location && touched.location && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.location}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: ",Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              What country or region do you plan on marketing in?
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="locationPlan">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter the country or region"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.locationPlan && touched.locationPlan && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.locationPlan}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Your Email
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="contactEmail">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter your Email"
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.contactEmail && touched.contactEmail && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.contactEmail}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                fontFamily: "DM Sans5",
                lineHeight: "28px",
              }}
              m={0}
            >
              Primary Platform/Channel for promoting
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="platform">
              {({ field }: FieldProps<string>) => (
                <Select
                  {...field}
                  color={COLOR.white}
                  placeholder="Select Type"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                >
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Youtube"
                  >
                    Youtube
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Facebook"
                  >
                    Facebook
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Telegram"
                  >
                    Telegram
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans5",
                    }}
                    value="Twitter"
                  >
                    Twitter (X)
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans5",
                    }}
                    value="Tiktok"
                  >
                    Tiktok{" "}
                  </option>

                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Instagram"
                  >
                    Instagram
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Discord"
                  >
                    Discord
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Reddit"
                  >
                    Reddit
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="LinkedIn"
                  >
                    LinkedIn
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="BinanceSquare"
                  >
                    Binance Square
                  </option>

                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Other"
                  >
                    Other
                  </option>
                </Select>
              )}
            </Field>
            <Stack>
              {errors.platform && touched.platform && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.platform}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Please share a brief introduction about yourself and your
              respective platform/community.
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="brief">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter description"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.brief && touched.brief && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.brief}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Platform URL
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="platformUrl">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter Platform URL"
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.platformUrl && touched.platformUrl && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.platformUrl}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              How many followers/members/users do you have on your primary
              platform?
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="followers">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  type="number"
                  min={0}
                  placeholder="Enter number of followers/members/users"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.followers && touched.followers && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.followers}
                </Text>
              )}
            </Stack>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Do you have any questions for us (Optional)
            </Text>
            <Field name="questions">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter here"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Button
              zIndex={1}
              isLoading={isLoading}
              type="submit"
              p="0 10px"
              justifyContent="center"
              cursor="pointer"
              gap="10px"
              h="44px"
              borderRadius="10px"
              bg={COLOR.nineteen}
              alignItems="center"
              fontSize="16px"
              fontWeight={700}
              lineHeight="30px"
              fontFamily="DM Sans"
              color={COLOR.sixth}
              _hover={{ bg: COLOR.nineteen }}
            >
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default KOLSForm;
