import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../../component/Container";
import TabProfile from "../../component/TabProfile";
import Aos from "aos";
const data = [
  {
    title: "Focused Investment Direction",
    img: IMAGES.po2,
    desc: "Clear focus on specific startup types and industries, spanning sectors like technology, healthcare, and renewable energy, with attention to various developmental stages.",
  },
  {
    title: "Value-Centric Approach",
    img: IMAGES.po1,

    desc: "Prioritizing value creation for both the VC firm and startups, achieved through strategic guidance, industry connections, operational support, and facilitating access to funding rounds.",
  },
  {
    title: "Long-Term Commitment",
    img: IMAGES.po3,

    desc: "Demonstrating dedication to sustained success and sustainability over short-term gains, fostering enduring partnerships and steady growth by patiently waiting for returns.",
  },
  {
    title: "Embracing Innovation and Disruption",
    img: IMAGES.po4,

    desc: "Actively seeking innovative startups with potential to disrupt markets or introduce new paradigms, capitalizing on emerging trends and opportunities in the entrepreneurial landscape.",
  },
];

const dataStrategy = [
  {
    title: "Diversified Portfolio Construction",
    img: IMAGES.po5,
  },
  {
    title: "Early-Stage Innovation Support",
    img: IMAGES.po6,
  },
  {
    title: "Strategic Sector Focus",
    img: IMAGES.po7,
  },
  {
    title: "Impactful Social Ventures",
    img: IMAGES.po8,
  },
];
const dataPhilosophy = [
  {
    title: "Strategic Portfolio Construction",
    img: IMAGES.p1,
  },
  {
    title: "Long-Term Value Creation",
    img: IMAGES.p2,
  },
  {
    title: "Disciplined Risk Management",
    img: IMAGES.p3,
  },
  {
    title: "Active Portfolio Management",
    img: IMAGES.p4,
  },
];
const Portfolio: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const tabs = [
    {
      label: "Strategy",
      title: "Projects and Investment Strategy",
      img: IMAGES.po9,

      desc: "Our focus areas are investing in diverse blockchain technology projects, ranging from financial applications, DeFi, NFTs to blockchain-based solutions across various industries. Our investment strategy revolves carefully selecting high-potential projects and quality management teams.",
      content: (
        <Grid
          mt="20px"
          gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
          gridColumnGap="20px"
          gridRowGap="30px"
        >
          {dataStrategy.map((i) => (
            <Stack key={i.title}>
              <Stack justifyContent="center" alignItems="center">
                <img
                  src={i.img}
                  alt=""
                  style={{
                    width: "48px",
                    height: "48px",
                  }}
                />
                <Stack p="0 30px" spacing={0}>
                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.white}
                    fontFamily="DM Sans"
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                  >
                    {i.title}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      ),
    },
    {
      label: "Philosophy",
      title: "Investment Philosophy",
      img: IMAGES.po10,
      desc: "We believe that innovation and progress are stem from supporting promising projects in the field of blockchain technology. With a deep understanding of technology and the market, we concentrate on creating sustainable value, supporting project development, and accompanying them on the journey of positive change.",
      content: (
        <Grid
          gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
          gridColumnGap="20px"
          gridRowGap="30px"
          // data-aos="fade-up"
        >
          {dataPhilosophy.map((i) => (
            <Stack key={i.title}>
              <Stack justifyContent="center" alignItems="center">
                <img
                  src={i.img}
                  alt=""
                  style={{
                    width: "48px",
                    height: "48px",
                  }}
                />
                <Stack p="0 30px" spacing={0}>
                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.white}
                    fontFamily="DM Sans"
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                  >
                    {i.title}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      ),
    },
  ];
  return (
    <Stack bg={COLOR.sixth}>
      <Stack
        sx={{
          backgroundImage: `url(${IMAGES.poBanner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          justifyContent="flex-start"
          alignItems="center"
          p={isTabletOrLaptop ? "150px 20px 10px 20px" : "150px 0"}
        >
          <Stack justifyContent="flex-start">
            <Text
              sx={{
                color: COLOR.white,
                fontFamily: "DM Sans",
                m: 0,
                fontSize: "48px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "64px",
              }}
            >
              More than a Venture Capital
            </Text>
            <Text
              w={isTabletOrLaptop ? "100%" : "800px"}
              sx={{
                color: COLOR.white,
                fontFamily: "DM Sans4",
                m: 0,
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "32px",
              }}
            >
              We are a professional investment firm focused on identifying,
              evaluating, and investing in promising blockchain technology
              projects. With a mission to contribute to the development and
              widespread adoption of blockchain, we are committed to creating
              lasting value for both of community and investors.
            </Text>
          </Stack>
        </Container>
      </Stack>
      <Stack
        bg=" linear-gradient(180deg, rgba(0, 0, 0, 0) -10.76%, #000000
      32.65%, #000000 49.91%, rgba(0, 0, 0, 0) 81.94%)"
        opacity="0.5"
        position="absolute"
        top="16%"
        left="0"
        zIndex={0}
        width="100%"
        height="7%"
      />
      <Stack
        // bg="#00000069"
        transform={isMobileOrTablet ? "translateY(0px)" : "translateY(-50px)"}
        justifyContent="center"
        alignItems="center"
        p={isMobile ? "0 30px" : isTabletOrLaptop ? "50px" : "0 130px"}
        position="relative"
      >
        <img
          src={IMAGES.poCircle}
          alt=""
          style={{
            display: isMobileOrTablet ? "none" : "flex",
            position: "absolute",
            top: "-3%",
            // left: "",
            zIndex: 0,

            width: "700px",
          }}
        />
        <Stack>
          <Text
            sx={{
              textAlign: "center",
              color: COLOR.nineteen,
              fontFamily: "DM Sans",
              m: 0,
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "28px",
            }}
          >
            Vision
          </Text>
          <Text
            sx={{
              textAlign: "center",
              color: COLOR.white,
              fontFamily: "DM Sans",
              m: 0,
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "42px",
            }}
          >
            Onebit Ventures, Empowering All!
          </Text>

          <Grid
            gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
            gap="20px"
            mt="50px"
          >
            {data.map((item, index) => (
              <Flex
                direction={isMobileOrTablet ? "column" : "row"}
                bg={COLOR.twentyEight}
                p="24px"
                borderRadius="12px"
                gap="10px"
                key={index}
                justifyContent="center"
                alignItems="center"
                boxShadow={COLOR.eleventh}
              >
                <img
                  width="80px"
                  height="80px"
                  src={item.img}
                  alt={item.title}
                />
                <Stack>
                  <Text
                    sx={{
                      textAlign: isMobileOrTablet ? "center" : "flex-start",
                      color: COLOR.white,
                      fontFamily: "DM Sans",
                      m: 0,
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "28px",
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    sx={{
                      textAlign: isMobileOrTablet ? "center" : "flex-start",

                      color: COLOR.white,
                      fontFamily: "DM Sans4",
                      m: 0,
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    {item.desc}
                  </Text>
                </Stack>
              </Flex>
            ))}{" "}
          </Grid>
          <Stack mt={isMobileOrTablet ? "100px" : "150px"}>
            <TabProfile tabs={tabs} />
          </Stack>
        </Stack>

        <Flex
          w="full"
          direction={isTabletOrLaptop ? "column" : "row"}
          m="50px 0"
          justifyContent="space-between"
          p={isMobileOrTablet ? "50px 15px" : "60px"}
          h={isTabletOrLaptop ? "100%" : "499px"}
          borderRadius="20px"
          sx={{
            backgroundImage: `url(${IMAGES.bannerFrame})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Stack justifyContent="center">
            <Text
              m={0}
              fontFamily="DM Sans"
              fontSize="24px"
              fontWeight="700"
              lineHeight="31px"
              textAlign="left"
              color={COLOR.primary}
            >
              Keep In Touch
            </Text>
            <Text
              m={0}
              color={COLOR.twentySix}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="500"
              lineHeight="39px"
              textAlign="left"
            >
              We'd love to hear from you!
            </Text>
            <Text
              m={0}
              color={COLOR.twentySix}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="500"
              lineHeight="39px"
              textAlign="left"
            >
              Let's schedule a call to discuss your needs.
            </Text>
            <Flex
              mt="20px"
              w="120px"
              p="0 10px"
              justifyContent="center"
              cursor="pointer"
              gap="10px"
              h="44px"
              borderRadius="1000px"
              bg={COLOR.nineteen}
              alignItems="center"
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
            >
              <a
                href="https://calendly.com/louis_onebitventures"
                target="blank"
                rel="noreferrer"
              >
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={700}
                  lineHeight="30px"
                  fontFamily="DM Sans"
                  color={COLOR.colorButton}
                >
                  {" "}
                  Book a call
                </Text>
              </a>
            </Flex>
          </Stack>

          <Stack justifyContent="center" alignItems="center">
            <img
              style={{
                marginTop: "10px",
              }}
              width="500px"
              src={IMAGES.scheduleIcon}
              alt=""
            />
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};
export default Portfolio;
