import { Flex, Grid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useMemo, useState } from "react";
import Container from "../../../component/Container";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import ModalStartUp from "../../../component/ModalStartUp";

const Section3 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const brands = [
    {
      img: IMAGES.platinum,
      link: "https://vc.platinum.fund/en/",
    },

    {
      link: "https://www.coinstore.com/",
      img: IMAGES.coin,
    },
    {
      link: "https://sui.io/",
      img: IMAGES.sui,
    },

    {
      link: "https://daihoc.fpt.edu.vn/",
      img: IMAGES.fpt,
    },
    {
      link: "https://kardiachain.io/",
      img: IMAGES.kardi,
    },
    {
      link: "https://www.eth-vietnam.com/",
      img: IMAGES.ethVN,
    },
    {
      link: "https://polkabridge.org/ventures",
      img: IMAGES.pv,
    },
    {
      link: "https://w3w.link/",
      img: IMAGES.w3w,
    },
    {
      link: "https://bho.network/",
      img: IMAGES.bho,
    },
    {
      link: "https://belaunch.io/",
      img: IMAGES.bel,
    },
    {
      link: "https://blockx.network/",
      img: IMAGES.blx,
    },
    {
      link: "https://coincu.com/",
      img: IMAGES.ccu,
    },
    {
      link: "https://cmccloud.vn/",
      img: IMAGES.cloudcmc,
    },
    {
      link: "https://coinbay.io/vi/home",
      img: IMAGES.coinbay,
    },
    {
      link: "https://dtsgroup.io/",
      img: IMAGES.dts,
    },
    {
      link: "https://alphamooncapital.net/",
      img: IMAGES.amc,
    },
    {
      link: "https://www.bdventures.vn/",
      img: IMAGES.bd,
    },
  ];
  const data = [
    {
      id: 1,
      title: "Finance",
      desc: "Onebit Ventures is developing a cutting-edge platform, the Onebit Startup Pad, to revolutionize crowdfunding via a DeFi launcher, enabling investment in blockchain projects.",
    },
    {
      id: 2,
      title: "Training",
      desc: " Our platform will offer tailored training programs to equip investors with essential skills for success in today's market.",
    },
    {
      id: 3,
      title: "Insurance",
      desc: "Our primary objective is to minimize risks for investors to the fullest extent possible.",
    },
  ];
  const [tab, setTab] = useState(1);
  const currentTab = useMemo(() => data.find((item) => item.id === tab), [tab]);
  return (
    <Stack spacing={0} gap="100px" mb="50px">
      <Container
        direction="column"
        borderRadius="24px"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          fontSize="24px"
          fontWeight={700}
          lineHeight="31.25px"
          fontFamily="DM Sans"
          color={COLOR.primary}
        >
          Our Ecosystem
        </Text>
        <Text
          textAlign="center"
          fontSize="40px"
          fontWeight={700}
          lineHeight="53px"
          fontFamily="DM Sans"
          color={COLOR.sixteen}
          m={0}
        >
          Optimize benefits for the investor community
        </Text>
        <Flex
          zIndex={11}
          data-aos="fade-up"
          mt="20px"
          alignItems="center"
          justifyContent="space-between"
          w="full"
          direction={isTabletOrLaptop ? "column" : "row"}
          // bg={COLOR.sixth}
          borderRadius="24px"
          gap="30px"
          position="relative"
        >
          <Stack
            zIndex={11}
            fontSize="32px"
            fontWeight={400}
            lineHeight="42px"
            fontFamily="MontserratB"
            color={COLOR.white}
            gap="20px"
          >
            <img
              style={{
                display: isTabletOrLaptop ? "flex" : "none",
              }}
              width="100%"
              src={IMAGES.mapMobile}
              alt=""
            />
            <Text
              m={0}
              fontSize="32px"
              fontWeight={400}
              lineHeight="41px"
              fontFamily="DM Sans4"
              color={COLOR.sixteen}
            >
              ONEBIT STARTUP PAD
            </Text>
            <Stack gap="35px">
              {data.map((i, r) => (
                <Stack gap="15px" key={r}>
                  <Flex gap="15px">
                    <img src={IMAGES.check} alt="" />
                    <Text
                      cursor="pointer"
                      m={0}
                      onClick={() => setTab(i.id)}
                      fontSize="18px"
                      fontWeight={400}
                      lineHeight="24px"
                      fontFamily="DM Sans4"
                      color={COLOR.sixteen}
                    >
                      {i.title}
                    </Text>
                  </Flex>
                  {r + 1 === tab && (
                    <Text
                      w={isMobileOrTablet ? "100%" : "600px"}
                      fontSize="18px"
                      fontWeight={400}
                      lineHeight="30px"
                      fontFamily="DM Sans4"
                      color={COLOR.fifth}
                    >
                      {currentTab?.desc}
                    </Text>
                  )}
                </Stack>
              ))}
            </Stack>
            <a
              href="https://onebit.ventures/about"
              target="blank"
              rel="noreferrer"
            >
              <Flex
                display={isTabletOrLaptop ? "flex" : "none"}
                zIndex={1}
                p="0 15px"
                justifyContent="center"
                cursor="pointer"
                gap="10px"
                h="44px"
                w="150px"
                borderRadius="1000px"
                bg={COLOR.ninth}
                alignItems="center"
                border={`1px solid ${COLOR.primary}`}
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: "translate3d(0, 2px, 0)",
                }}
              >
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={700}
                  lineHeight="30px"
                  fontFamily="DM Sans"
                  color={COLOR.primary}
                >
                  {" "}
                  Visit Now
                </Text>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
                    stroke="#088EAF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Flex>
            </a>
            <Flex
              gap="10px"
              mt="20px"
              display={isTabletOrLaptop ? "none" : "flex"}
            >
              <a href="https://onbpad.com/home" target="blank" rel="noreferrer">
                <Flex
                  transition="all 235ms ease-in-out"
                  _hover={{
                    transform: "translate3d(0, 2px, 0)",
                  }}
                  p="0 25px"
                  justifyContent="center"
                  cursor="pointer"
                  zIndex={99}
                  gap="10px"
                  h="44px"
                  borderRadius="1000px"
                  bg={COLOR.nineteen}
                  alignItems="center"
                >
                  <Text
                    zIndex={99}
                    m={0}
                    fontSize="16px"
                    fontWeight={700}
                    lineHeight="30px"
                    fontFamily="DM Sans"
                    color={COLOR.sixth}
                  >
                    {" "}
                    Join ONBPad
                  </Text>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
                      stroke="#1A1A1A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Flex>
              </a>

              <Flex
                onClick={onOpen}
                zIndex={1}
                p="0 25px"
                justifyContent="center"
                cursor="pointer"
                gap="10px"
                h="44px"
                borderRadius="1000px"
                bg={COLOR.ninth}
                alignItems="center"
                border={`1px solid ${COLOR.primary}`}
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: "translate3d(0, 2px, 0)",
                }}
              >
                <Text
                  m={0}
                  zIndex={1}
                  fontSize="16px"
                  fontWeight={700}
                  lineHeight="30px"
                  fontFamily="DM Sans"
                  color={COLOR.nineteen}
                >
                  {" "}
                  Apply ONBPad
                </Text>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
                    stroke={COLOR.nineteen}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Flex>
              <ModalStartUp isOpen={isOpen} onClose={onClose} />
            </Flex>
          </Stack>
          <img
            style={{
              position: "absolute",
              top: "49%",
              left: "27%",

              display: isTabletOrLaptop ? "none" : "flex",
            }}
            width="260px"
            src={IMAGES.line}
            alt=""
          />

          <img
            style={{
              display: isTabletOrLaptop ? "none" : "flex",
            }}
            width={isMobileOrTablet ? "100%" : "650px"}
            src={IMAGES.map}
            alt=""
          />
        </Flex>
      </Container>
      <img
        style={{
          display: isTabletOrLaptop ? "none" : "flex",
          position: "absolute",
          top: "55%",
          left: 0,
          zIndex: 0,
          opacity: 0.5,
          width: "650px",
        }}
        src={IMAGES.lightL}
        alt=""
      />
      <Container direction="column">
        <Text
          fontSize="30px"
          fontWeight={700}
          lineHeight="42px"
          fontFamily="DM Sans4"
          textAlign="center"
          color={COLOR.white}
        >
          Onebit is trusted by global brands and ecosystem giants
        </Text>
        <Grid
          zIndex={1}
          gridTemplateColumns={
            isMobile ? "1fr" : isMobileOrTablet ? "1fr 1fr" : "1fr 1fr 1fr 1fr"
          }
          sx={{
            marginTop: "30px",
          }}
          gap="30px"
        >
          {brands.map((i, index) => (
            <a href={i.link} target="_blank" rel="noreferrer" key={i.img}>
              <Stack
                transition="all 235ms ease-in-out"
                _hover={{
                  boxShadow: "5px 4px 22px 0px #38EDF91C",
                  transform: "translate3d(0, 2px, 0)",
                  bg: "linear-gradient(80deg, rgba(96, 96, 96, 0.20) -5.25%, rgba(21, 112, 250, 0.20) 69.95%, rgba(26, 188, 252, 0.20) 82.08%, rgba(55, 255, 207, 0.20) 90.29%)",
                }}
                w="100%"
                h="103px"
                p="32px"
                borderRadius="8px"
                bg={COLOR.twentyEight}
                border={`1px solid ${COLOR.twentyEight}`}
                zIndex={1}
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
              >
                <img
                  src={i.img}
                  width={index === 16 ? "110px" : "200px"}
                  height="100%"
                  alt=""
                />
              </Stack>
            </a>
          ))}
        </Grid>
      </Container>
    </Stack>
  );
};

export default Section3;
