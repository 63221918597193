import { Flex, Grid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Container from "../../../component/Container";
import InvestorSlider from "../../../component/InvestorSlide";
import ModalComponent from "../../../component/ModalForm";
import ModalInvestor from "../../../component/ModalInvestor";
import ModalStartUp from "../../../component/ModalStartUp";
import SlideStartup from "../../../component/SlideStarUps";
import Tabs from "../../../component/Tabs";
import { DefaultProduct } from "../../../constant/API_ROUTES/DataProject/ProjectDefaults";
import { AllType } from "../../../constant/API_ROUTES/DataProject/model/ProdcuctType";
import { reputation } from "../../../constant/API_ROUTES/DataReputation";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
const Section = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const [products, setProduct] = useState(DefaultProduct);
  const [typeFilter, setTypeFilter] = useState<AllType | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filterAll = (all: AllType) => {
    const filterProducts = DefaultProduct.filter((item) => {
      return item.all === all;
    });
    setTypeFilter(all);
    setProduct(filterProducts);
  };
  const cards = [
    {
      id: 0,
      title: "Financial Assistance",
      description:
        "Furnishing vital resources to bolster project development and ensure successful outcomes.",
      image: `${IMAGES.icon1}`,
    },
    {
      id: 1,
      title: "Strategic Guidance",
      description:
        "Offering expert advice and support in crafting effective strategies and robust business plans.",
      image: `${IMAGES.icon2}`,
    },
    {
      id: 2,
      title: "Networking Opportunities",
      description:
        "Leveraging extensive connections to forge valuable partnerships with influential funds and collaborators, fostering growth and expansion.",
      image: `${IMAGES.icon3}`,
    },
    {
      id: 3,
      title: "Marketing Support",
      description:
        "Providing assistance in promoting essential project details to attract investors and stakeholders, enhancing visibility and credibility.",
      image: `${IMAGES.icon4}`,
    },
  ];
  const cards2 = [
    {
      id: 0,
      title: "Educating",
      description:
        "Providing free finance and investment courses to ensure investors are well-informed.",
      image: `${IMAGES.icon5}`,
    },
    {
      id: 1,
      title: "Advising",
      description:
        "Offering guidance on personal financial management for individual investment strategies.",
      image: `${IMAGES.icon2}`,
    },
    {
      id: 2,
      title: "Networking ",
      description:
        "Sharing potential investment opportunities to connect investors with promising ventures.",
      image: `${IMAGES.icon3}`,
    },
    {
      id: 3,
      title: " Supporting",
      description:
        "Assisting in marketing project-related information for enhanced visibility.",
      image: `${IMAGES.icon4}`,
    },
  ];
  const tabs = [
    {
      label: "For Startups",
      desc: "Onebit plays a pivotal role in supporting startups by providing them with not only flexible capital but also strategic mentorship tailored to their specific needs. We believe in nurturing innovation and facilitating the development of groundbreaking products and services. ",
      content: isTabletOrLaptop ? (
        <Grid
          gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
          gridColumnGap="20px"
          // data-aos="fade-up"
        >
          {cards.map((i) => (
            <Stack key={i.id}>
              <Stack
                borderRadius="16px"
                border={`1px solid ${COLOR.bg}`}
                mt="20px"
                justifyContent="center"
                alignItems="center"
                key={i.id}
                bg={COLOR.twentyFour}
                w="100%"
                h="100%"
              >
                <img
                  src={i.image}
                  alt={i.title}
                  style={{
                    width: "110px",
                    height: "110px",
                  }}
                />
                <Stack p="30px" spacing={0}>
                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.sixteen}
                    fontFamily="DM Sans"
                    fontSize="24px"
                    fontWeight="700"
                  >
                    {i.title}
                  </Text>

                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    {i.description}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      ) : (
        <SlideStartup />
      ),
      buttonForm: (
        <Stack
          mt="20px"
          transition="all 235ms ease-in-out"
          cursor="pointer"
          _hover={{
            transform: "translate3d(0, 2px, 0)",
          }}
          bg={isTabletOrLaptop ? COLOR.white : COLOR.nineteen}
          p="0 16px"
          w="120px"
          borderRadius="1000px"
          h="44px"
          justifyContent="center"
          alignItems="center"
          onClick={onOpen}
        >
          <Text
            textAlign="center"
            fontFamily="DM Sans"
            fontSize="16px"
            fontWeight={700}
            lineHeight="30px"
            color={COLOR.colorButton}
            m={0}
          >
            Apply Now
          </Text>
          <ModalStartUp isOpen={isOpen} onClose={onClose} />
        </Stack>
      ),
    },
    {
      label: "For Investors",
      desc: "Our dedication to generating value for investors is ingrained in our core mission. We employ a meticulous investment approach that prioritizes thorough due diligence and strategic decision-making to optimize returns while mitigating risks. Moreover, we are committed to fostering transparent and open communication channels with our investors, providing them with timely updates, performance reports, and in-depth market insights.",
      content: isTabletOrLaptop ? (
        <Grid
          gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"}
          gridColumnGap="20px"
          // data-aos="fade-up"
        >
          {cards2.map((i) => (
            <Stack key={i.id}>
              <Stack
                borderRadius="16px"
                border={`1px solid ${COLOR.bg}`}
                mt="20px"
                justifyContent="center"
                alignItems="center"
                key={i.id}
                bg={COLOR.twentyFour}
                w="100%"
                h="100%"
              >
                <img
                  src={i.image}
                  alt={i.title}
                  style={{
                    width: "130px",
                    height: "130px",
                  }}
                />
                <Stack p="20px" spacing={0}>
                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.sixteen}
                    fontFamily="DM Sans"
                    fontSize="24px"
                    fontWeight="700"
                  >
                    {i.title}
                  </Text>

                  <Text
                    m={0}
                    textAlign="center"
                    color={COLOR.fifth}
                    fontFamily="DM Sans4"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    {i.description}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      ) : (
        <InvestorSlider />
      ),
      buttonForm: (
        <Stack
          mt="20px"
          transition="all 235ms ease-in-out"
          cursor="pointer"
          _hover={{
            transform: "translate3d(0, 2px, 0)",
          }}
          bg={isTabletOrLaptop ? COLOR.white : COLOR.nineteen}
          p="0 16px"
          w="120px"
          borderRadius="1000px"
          h="44px"
          justifyContent="center"
          alignItems="center"
          onClick={onOpen}
        >
          <Text
            textAlign="center"
            fontFamily="DM Sans"
            fontSize="16px"
            fontWeight={700}
            lineHeight="30px"
            color={COLOR.colorButton}
            m={0}
          >
            Apply Now
          </Text>
          <ModalInvestor isOpen={isOpen} onClose={onClose} />
        </Stack>
      ),
    },
  ];

  return (
    <Stack>
      <img
        style={{
          display: isTabletOrLaptop ? "none" : "flex",

          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 0,
          opacity: 0.7,

          width: "30%",
        }}
        src={IMAGES.light}
        alt=""
      />

      <Stack justifyContent="center" alignItems="center" mt="200px" zIndex={1}>
        <Tabs tabs={tabs} />
      </Stack>
      <img
        style={{
          display: isTabletOrLaptop ? "none" : "flex",
          position: "absolute",
          top: "50%",
          left: 0,
          zIndex: 0,
          opacity: 0.7,
          width: "700px",
        }}
        src={IMAGES.lightL}
        alt=""
      />

      {/* <StackedCenterCarousel mt="50px" /> */}
    </Stack>
  );
};

export default Section;
