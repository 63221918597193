import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333374 1.99992C0.333374 1.35559 0.855708 0.833252 1.50004 0.833252H22.5C23.1444 0.833252 23.6667 1.35559 23.6667 1.99992C23.6667 2.64425 23.1444 3.16659 22.5 3.16659H1.50004C0.855708 3.16659 0.333374 2.64425 0.333374 1.99992Z"
        fill="#1D2939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333374 8.99992C0.333374 8.35559 0.855708 7.83325 1.50004 7.83325H22.5C23.1444 7.83325 23.6667 8.35559 23.6667 8.99992C23.6667 9.64425 23.1444 10.1666 22.5 10.1666H1.50004C0.855708 10.1666 0.333374 9.64425 0.333374 8.99992Z"
        fill="#1D2939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333374 15.9999C0.333374 15.3556 0.855708 14.8333 1.50004 14.8333H22.5C23.1444 14.8333 23.6667 15.3556 23.6667 15.9999C23.6667 16.6442 23.1444 17.1666 22.5 17.1666H1.50004C0.855708 17.1666 0.333374 16.6442 0.333374 15.9999Z"
        fill="#1D2939"
      />
    </svg>
  );
};

export default MenuIcon;
