import { Flex, Stack, Text } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import COLOR from "../../constant/API_ROUTES/color";
import { BlogType } from "../BlogType";

const BlogCard = ({ blog }: { blog: BlogType }) => {
  return (
    <Stack
      position="relative"
      w="100%"
      h="100%"
      bg={COLOR.sixth}
      sx={{
        borderRadius: "20px",
      }}
    >
      <img
        style={{
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          objectFit: "cover",
          height: "300px",
        }}
        width="100%"
        height="100%"
        src={blog.bannerUrl}
        alt=""
      />
      <Stack gap="20px" p="20px" spacing={0}>
        <Text
          m={0}
          fontFamily="DM Sans"
          fontWeight={700}
          fontSize="20px"
          textAlign="start"
          lineHeight="32px"
          color={COLOR.white}
        >
          {blog.title}
        </Text>
        <Text
          m={0}
          fontFamily="DM Sans4"
          fontWeight={400}
          lineHeight="24px"
          color={COLOR.white}
          fontSize="16px"
          textAlign="start"
        >
          {blog.shortDes}
        </Text>
        <Flex justifyContent="flex-end" mt="50px">
          <Link to={`${APP_ROUTES.NEWSDETAIL}/${blog._id}`}>
            <Stack
              spacing={0}
              w="100px"
              p="12px"
              borderRadius="12px"
              border={`1px solid ${COLOR.eighteen}`}
              justifyContent="center"
              alignItems="center"
              bg={COLOR.fifteen}
              position="absolute"
              bottom="5"
              right="5"
            >
              <Text
                m={0}
                fontFamily="DM Sans5"
                fontWeight={500}
                lineHeight="22px"
                color={COLOR.white}
                fontSize="14px"
                textAlign="center"
              >
                Read more
              </Text>
            </Stack>
          </Link>
        </Flex>
      </Stack>
    </Stack>
  );
};
export default BlogCard;
