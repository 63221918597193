import { Stack } from "@chakra-ui/react";

import Section1 from "./Section1";
import Section2 from "./Section2";

import React, { useCallback, useEffect } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

const Home: React.FC = () => {
  const link = window.location.href;

  const reviewBox = useCallback(() => {
    if (link.indexOf("SectionPortfolio") !== -1) {
      window.location.href = "#SectionPortfolio";
      window.history.replaceState(null, window.location.origin);
    }
  }, [link]);

  useEffect(() => {
    reviewBox();
  }, [reviewBox]);
  return (
    <>
      <Stack bg={COLOR.sixth} spacing={0}>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </Stack>
    </>
  );
};
export default Home;
