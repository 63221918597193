import { Divider, Flex, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Container from "../../../component/Container";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
// import { DefaultBlog } from "../../../component/BlogDefault";
import { AllType, BlogType } from "../../../component/BlogType";
import { Link } from "react-router-dom";
import APP_ROUTES from "../../../constant/API_ROUTES/app_routes";
import axios from "axios";
import moment from "moment";

const Section5 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const [type, setType] = useState<string>("all");
  const [blogFilterType, setBlogsFilterType] = useState<BlogType[]>([]);

  const [typeFilter, setTypeFilter] = useState<AllType | null>(null);
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // const filterAll = (all: AllType) => {
  //   const filterProducts = DefaultBlog.filter((item) => {
  //     return item.all === all;
  //   });
  //   setTypeFilter(all);
  //   setBlogs(filterProducts);
  // };
  // call api
  const blogsFilterTypes = (type: string) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blogs?&type=${type}`)
      .then((res) => {
        // console.log(res.data.data.blogs);
        setBlogsFilterType(res.data.data.blogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    blogsFilterTypes(type);
  }, [type]);

  return (
    <Stack mt="200px" direction="column" position="relative">
      <Container direction="column">
        <Flex justifyContent="space-between" w="full">
          <Text
            w={isMobileOrTablet ? "200px" : "100%"}
            fontFamily="DM Sans"
            fontSize={isMobileOrTablet ? "32px" : "40px"}
            fontWeight="700"
            lineHeight={isMobileOrTablet ? "normal" : "52px"}
            textAlign="left"
            color={COLOR.sixteen}
          >
            Latest from our blogs
          </Text>
          <Link to={APP_ROUTES.NEWS} onClick={handleLabelClick}>
            <Flex
              zIndex={1}
              p="0 10px"
              justifyContent="center"
              cursor="pointer"
              gap="10px"
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              w="100px"
              h="44px"
              borderRadius="1000px"
              bg={COLOR.ninth}
              alignItems="center"
              border={`1px solid ${COLOR.nineteen}`}
            >
              <Text
                zIndex={1}
                m={0}
                fontSize="16px"
                fontWeight={700}
                lineHeight={isMobileOrTablet ? "15px" : "30px"}
                fontFamily="DM Sans"
                color={COLOR.nineteen}
              >
                {" "}
                View All
              </Text>
            </Flex>
          </Link>
        </Flex>
        <Flex
          alignItems="center"
          gap="16px"
          flexWrap={isMobileOrTablet ? "wrap" : "nowrap"}
        >
          <Text
            m={0}
            cursor="pointer"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            fontFamily="DM Sans"
            fontSize="14px"
            fontWeight="700"
            lineHeight="22px"
            textAlign="center"
            color={
              typeFilter === AllType.all || !typeFilter
                ? COLOR.white
                : COLOR.fourteen
            }
            borderRadius={
              typeFilter === AllType.all || !typeFilter ? "3px" : "none"
            }
            p={typeFilter === AllType.all || !typeFilter ? "6px 10px" : "none"}
            bg={
              typeFilter === AllType.all || !typeFilter ? COLOR.seventh : "none"
            }
            border={
              typeFilter === AllType.all || !typeFilter
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
            onClick={() => {
              setType("all");
              setTypeFilter(AllType.all);
            }}
          >
            All
          </Text>
          <Text
            m={0}
            cursor="pointer"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            fontFamily="DM Sans"
            fontSize="14px"
            fontWeight="700"
            lineHeight="22px"
            textAlign="center"
            color={
              typeFilter === AllType.announcement ? COLOR.white : COLOR.fourteen
            }
            onClick={() => {
              setType("announcement");
              setTypeFilter(AllType.announcement);
            }}
            borderRadius={typeFilter === AllType.announcement ? "3px" : "none"}
            p={typeFilter === AllType.announcement ? "6px 10px" : "none"}
            bg={typeFilter === AllType.announcement ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.announcement
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
          >
            Announcement
          </Text>{" "}
          <Text
            m={0}
            cursor="pointer"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            fontFamily="DM Sans"
            fontSize="14px"
            fontWeight="700"
            lineHeight="22px"
            textAlign="center"
            color={
              typeFilter === AllType.blockchain ? COLOR.white : COLOR.fourteen
            }
            onClick={() => {
              setType("blockchain");
              setTypeFilter(AllType.blockchain);
            }}
            borderRadius={typeFilter === AllType.blockchain ? "3px" : "none"}
            p={typeFilter === AllType.blockchain ? "6px 10px" : "none"}
            bg={typeFilter === AllType.blockchain ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.blockchain
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
          >
            Blockchain
          </Text>{" "}
          <Text
            m={0}
            cursor="pointer"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            fontFamily="DM Sans"
            fontSize="14px"
            fontWeight="700"
            lineHeight="22px"
            textAlign="center"
            color={
              typeFilter === AllType.investment ? COLOR.white : COLOR.fourteen
            }
            onClick={() => {
              setType("investment");
              setTypeFilter(AllType.investment);
            }}
            borderRadius={typeFilter === AllType.investment ? "3px" : "none"}
            p={typeFilter === AllType.investment ? "6px 10px" : "none"}
            bg={typeFilter === AllType.investment ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.investment
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
          >
            Investment
          </Text>{" "}
          <Text
            m={0}
            cursor="pointer"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            fontFamily="DM Sans"
            fontSize="14px"
            fontWeight="700"
            lineHeight="22px"
            textAlign="center"
            color={
              typeFilter === AllType.project ? COLOR.white : COLOR.fourteen
            }
            onClick={() => {
              blogsFilterTypes("project");
              setTypeFilter(AllType.project);
            }}
            borderRadius={typeFilter === AllType.project ? "3px" : "none"}
            p={typeFilter === AllType.project ? "6px 10px" : "none"}
            bg={typeFilter === AllType.project ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.project
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
          >
            Project
          </Text>{" "}
        </Flex>
        <Flex
          gap="30px"
          direction={isMobileOrTablet ? "column" : "row"}
          mt="20px"
        >
          {blogFilterType.slice(0, 1).map((item, index) => (
            <Link to={`${APP_ROUTES.NEWSDETAIL}/${item._id}`} key={index}>
              <Stack spacing={1}>
                <img src={item.bannerUrl} alt="" />

                <Text
                  m={0}
                  sx={{
                    marginTop: "10px",
                  }}
                  cursor="pointer"
                  _hover={{
                    color: COLOR.nineteen,
                  }}
                  fontFamily="DM Sans"
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="31.25px"
                  color={COLOR.sixteen}
                >
                  {item.title}
                </Text>
                <Flex
                  alignItems="center"
                  gap="5px"
                  display={isMobileOrTablet ? "none" : "flex"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1310_2536)">
                      <path
                        d="M7.99967 3.99967V7.99967L10.6663 9.33301M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z"
                        stroke={COLOR.fifth}
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1310_2536">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Text
                    m={0}
                    fontFamily="DM Sans4"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="20px"
                    color={COLOR.fifth}
                  >
                    {moment(item.updatedAt).format("DD/MM/YYYY")}
                  </Text>
                </Flex>
                <Text
                  display={isMobileOrTablet ? "none" : "flex"}
                  fontFamily="DM Sans4"
                  fontSize="16px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={COLOR.fifth}
                >
                  {item.shortDes}
                </Text>
              </Stack>
            </Link>
          ))}

          <Stack spacing={0} gap="1px" zIndex={1}>
            {blogFilterType.map((item, index) => (
              <Link to={`${APP_ROUTES.NEWSDETAIL}/${item._id}`} key={index}>
                <Stack spacing={0} gap="10px" zIndex={1}>
                  <Text
                    cursor="pointer"
                    _hover={{
                      color: COLOR.nineteen,
                    }}
                    m={0}
                    fontFamily="DM Sans"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="19px"
                    color={COLOR.sixteen}
                  >
                    {item.title}
                  </Text>
                  {/* <Flex alignItems="center" gap="5px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1310_2536)">
                        <path
                          d="M7.99967 3.99967V7.99967L10.6663 9.33301M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z"
                          stroke={COLOR.fifth}
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1310_2536">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Text
                      m={0}
                      fontFamily="DM Sans4"
                      fontSize="13px"
                      fontWeight="400"
                      lineHeight="16px"
                      color={COLOR.fifth}
                    >
                      {item.time}
                    </Text>
                  </Flex> */}
                  <Text
                    display={
                      (isMobileOrTablet && index === 1) ||
                      (isMobileOrTablet && index === 2) ||
                      (isMobileOrTablet && index === 3)
                        ? "none"
                        : "flex"
                    }
                    m={0}
                    fontFamily="DM Sans4"
                    fontSize="13px"
                    fontWeight="400"
                    lineHeight="16px"
                    zIndex={1}
                    color={COLOR.fifth}
                  >
                    {item.shortDes}
                  </Text>
                  <Divider
                    opacity={0.2}
                    bg={COLOR.twentyTwo}
                    display={isMobileOrTablet ? "none" : "flex"}
                  />
                </Stack>
              </Link>
            ))}
          </Stack>
        </Flex>
      </Container>
      <img
        style={{
          display: isTabletOrLaptop ? "none" : "flex",

          position: "absolute",
          top: "5%",
          right: "0",
          zIndex: 0,
          opacity: 0.6,

          width: "450px",
        }}
        src={IMAGES.light}
        alt=""
      />
      <Container zIndex={1}>
        <Flex
          data-aos="fade-up"
          w="full"
          direction={isTabletOrLaptop ? "column" : "row"}
          m="50px 0"
          justifyContent="space-between"
          p={isMobileOrTablet ? "50px 15px" : "60px"}
          h={isTabletOrLaptop ? "100%" : "499px"}
          borderRadius="20px"
          sx={{
            backgroundImage: `url(${IMAGES.bannerFrame})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Stack justifyContent="center">
            <Text
              m={0}
              fontFamily="DM Sans"
              fontSize="24px"
              fontWeight="700"
              lineHeight="31px"
              textAlign="left"
              color={COLOR.primary}
            >
              Keep In Touch
            </Text>
            <Text
              m={0}
              color={COLOR.twentySix}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="500"
              lineHeight="39px"
              textAlign="left"
            >
              We'd love to hear from you!
            </Text>
            <Text
              m={0}
              color={COLOR.twentySix}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="500"
              lineHeight="39px"
              textAlign="left"
            >
              Let's schedule a call to discuss your needs.
            </Text>

            <Flex
              mt="20px"
              w="120px"
              p="0 10px"
              justifyContent="center"
              cursor="pointer"
              gap="10px"
              h="44px"
              borderRadius="1000px"
              bg={COLOR.nineteen}
              alignItems="center"
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
            >
              <a
                href="https://calendly.com/louis_onebitventures"
                target="blank"
                rel="noreferrer"
              >
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={700}
                  lineHeight="30px"
                  fontFamily="DM Sans"
                  color={COLOR.colorButton}
                >
                  {" "}
                  Book a call
                </Text>
              </a>
            </Flex>
          </Stack>

          <Stack justifyContent="center" alignItems="center">
            <img
              style={{
                marginTop: "10px",
              }}
              width="500px"
              src={IMAGES.scheduleIcon}
              alt=""
            />
          </Stack>
        </Flex>
      </Container>
    </Stack>
  );
};

export default Section5;
