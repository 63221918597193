import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import { useState } from "react";

const StartUpForm = () => {
  const toast = useToast();
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const URL = /^(ftp|http|https):\/\/[^ "]+$/;

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Project/Company Name is required"),
    type: Yup.string().required("Select type is required"),
    websiteUrl: Yup.string().matches(URL, "Enter a valid url"),
    // platform: Yup.string().required("Platform is required"),
    location: Yup.string().required("Location type is required"),
    description: Yup.string()
      .required("Description type is required")
      .min(50, "Description must be at least 50 characters")
      .max(500, "Description must be  500 characters"),
    stage: Yup.string().required("Stage is required"),
    investors: Yup.string().matches(URL, "Enter a valid url"),
    auditDetail: Yup.mixed()
      .test(
        "is-one-of-options",
        'Must be "No", "no", or "NO", or a valid URL',
        function (value: any) {
          // Check if the value is one of "No", "no", or "NO"
          if (["No", "no", "NO"].includes(value)) return true;

          // Check if the value is a valid URL
          const isURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
          return isURL;
        }
      )
      .required("This field is required"),
    docsUrl: Yup.string().matches(URL, "Enter a valid url"),
    founderName: Yup.string().required("Your Founder's Full Name is required"),
    founderProfileUrl: Yup.string().matches(URL, "Enter a valid url"),
    contactName: Yup.string().required(
      "Name and title of contact person is required"
    ),
    contactEmail: Yup.string()
      .required("Email of contact person is required")
      .email("Must be a valid email")
      .max(255),
    contactTelegram: Yup.string().matches(URL, "Enter a valid url"),
  });

  const handleSubscribe = async (values: any, { resetForm }: any) => {
    setIsLoading(true);

    try {
      for (const key in values) {
        if (values[key] === "") {
          delete values[key];
        }
      }
      // if (values.platform.indexOf("other") !== -1) {
      //   const index = values.platform.indexOf("other");
      //   values.platform.splice(index, 1, values.other);
      //   console.log(values.platform);
      //   delete values.other;
      // }
      // Check if values is defined
      // if (!values) {
      //   throw new Error("Values object is undefined or null");
      // }

      // for (const key in values) {
      //   if (values.hasOwnProperty(key) && values[key] === "") {
      //     delete values[key];
      //   }
      // }

      // if (values.platform && values.platform.indexOf("other") !== -1) {
      //   const index = values.platform.indexOf("other");
      //   values.platform.splice(index, 1, values.other);
      //   delete values.other;
      // }

      // if (!process.env.REACT_APP_BASE_URL) {
      //   throw new Error("REACT_APP_BASE_URL is not defined");
      // }
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pitch`, {
        method: "POST",
        body: JSON.stringify({ type: "startup", data: values }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast({
          title: "Subscribe successful",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        resetForm();
      } else {
        const data = await response.json();
        if (data.error === "AlreadySubscribed") {
          toast({
            title: "You have already subscribed",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Failed to subscribe");
        }
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Failed to subscribe",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Formik
        initialValues={{
          name: "",
          type: "",
          websiteUrl: "",
          location: "",
          description: "",
          platform: "",
          stage: "",
          investors: "",
          auditDetail: "",
          docsUrl: "",
          founderName: "",
          founderProfileUrl: "",
          contactName: "",
          contactEmail: "",
          contactTelegram: "",
          questions: "",
          other: "",
        }}
        validationSchema={RegisterSchema}
        onSubmit={handleSubscribe}
      >
        {({ handleSubmit, handleChange, errors, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            <Stack className="form" gap="10px">
              <Stack className="form" gap="10px">
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Project/Company Name
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>

                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      type="text"
                      autoComplete="off"
                      placeholder="Enter your Project/Company Name"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      w="full"
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>

                <Stack>
                  {errors.name && touched.name && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.name}
                    </Text>
                  )}
                </Stack>
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontFamily: "DM Sans5",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Project/Company Type
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="type">
                  {({ field }: FieldProps<string>) => (
                    <Select
                      {...field}
                      color={COLOR.white}
                      placeholder="Select Type"
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    >
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="GameFi"
                      >
                        GameFi
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Defi"
                      >
                        Defi
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="CeFi"
                      >
                        CeFi
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value=" BlockchainService"
                      >
                        Blockchain Service
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="BlockchainInfrastructure"
                      >
                        Blockchain Infrastructure
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Chain"
                      >
                        Chain (L1/L2)
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Meme"
                      >
                        Meme
                      </option>{" "}
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="NFT"
                      >
                        NFT
                      </option>{" "}
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Stablecoin"
                      >
                        Stablecoin
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Metaverse"
                      >
                        Metaverse
                      </option>
                      <option
                        style={{
                          color: COLOR.white,
                          fontWeight: "400",
                          fontFamily: "DM Sans4",
                        }}
                        value="Other"
                      >
                        Other
                      </option>
                    </Select>
                  )}
                </Field>
                <Stack>
                  {errors.type && touched.type && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.type}
                    </Text>
                  )}
                </Stack>
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans4",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Project/Company Website URL
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="websiteUrl">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      autoComplete="off"
                      {...field}
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      placeholder="Enter your Project/Company URL"
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.websiteUrl && touched.websiteUrl && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.websiteUrl}
                    </Text>
                  )}
                </Stack>
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Blockchain/Platform
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Stack>
                  <Grid
                    gridTemplateColumns={isMobileOrTablet ? "1fr" : "1fr 1fr"}
                    gridRowGap="10px"
                    gridColumnGap="40px"
                  >
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Binance"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Binance Smart Chain
                            </Text>
                            <img width="28px" src={IMAGES.bnb} alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="OKEx"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              OKExChain
                            </Text>
                            <img width="28px" src={IMAGES.oke} alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Ethereum"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Ethereum
                            </Text>
                            <img width="28px" src={IMAGES.eth} alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Polygon"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Polygon (Matic)
                            </Text>
                            <img src={IMAGES.matic} width="28px" alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Solana"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Solana
                            </Text>
                            <img src={IMAGES.solana} width="28px" alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Arbitrum"
                          size="lg"
                        >
                          <Flex gap="10px" alignItems="center">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Arbitrum
                            </Text>
                            <img src={IMAGES.arbi} width="28px" alt="" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Avalanche"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Avalanche
                            </Text>
                            <img src={IMAGES.ava} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Cardano"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Cardano
                            </Text>
                            <img src={IMAGES.carda} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Polkadot"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Polkadot
                            </Text>
                            <img src={IMAGES.polk} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Mantle"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Mantle
                            </Text>
                            <img src={IMAGES.mantle} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Near"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Near
                            </Text>
                            <img src={IMAGES.near} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="platform">
                      {({ field }: FieldProps<string>) => (
                        <Checkbox
                          {...field}
                          name="platform"
                          value="Optimism"
                          size="lg"
                        >
                          <Flex alignItems="center" gap="10px">
                            <Text
                              sx={{
                                color: COLOR.white,
                                fontFamily: "DM Sans5",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "28px",
                              }}
                              m={0}
                            >
                              Optimism
                            </Text>
                            <img src={IMAGES.opti} alt="" width="28px" />
                          </Flex>
                        </Checkbox>
                      )}
                    </Field>
                    <Flex gap="7px">
                      <Field name="platform">
                        {({ field }: FieldProps<string>) => (
                          <Checkbox
                            {...field}
                            size="lg"
                            value="other"
                          ></Checkbox>
                        )}
                      </Field>
                      <Field name="other">
                        {({ field }: FieldProps<string>) => (
                          <Input
                            autoComplete="off"
                            {...field}
                            w="full"
                            color={COLOR.white}
                            bg={COLOR.bgNav}
                            placeholder="Other"
                            border={`1px solid ${COLOR.borderReputation}`}
                          />
                        )}
                      </Field>
                    </Flex>
                  </Grid>
                </Stack>
                {/* <Stack>
                  {errors.platform && touched.platform && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.platform}
                    </Text>
                  )}
                </Stack> */}
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Tell us more about your Project/Company, the more info you
                  give us, the more likely we will consider your application
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="description">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      placeholder="Enter your Project/Company description"
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.description && touched.description && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.description}
                    </Text>
                  )}
                </Stack>
                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Where is your Project/Company based?
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="location">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your Project/Company location"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.location && touched.location && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.location}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: ",Montserrat",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Current Stage of Project/Company
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="stage">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your Project/Company stage"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.stage && touched.stage && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.stage}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Advisors/Backers/Investors of your Project/Company
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="investors">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your Advisors/Backers/Investors"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.investors && touched.investors && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.investors}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Have you been audited? If yes, please provide details
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="auditDetail">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your answer"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.auditDetail && touched.auditDetail && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.auditDetail}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Project/Company docs or whitepaper
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="docsUrl">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter URL"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.docsUrl && touched.docsUrl && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.docsUrl}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Your Founder's Full Name
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="founderName">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your Founder's Full Name"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.founderName && touched.founderName && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.founderName}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Your Founder's Profile (Linkedin/Twitter handle/Website URL)
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="founderProfileUrl">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter your Founder's Profile"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.founderProfileUrl && touched.founderProfileUrl && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.founderProfileUrl}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Name and title of contact person
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="contactName">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter name and title"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.contactName && touched.contactName && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.contactName}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Email of contact person
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="contactEmail">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      autoComplete="off"
                      placeholder="Enter Email"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.contactEmail && touched.contactEmail && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.contactEmail}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Telegram of contact person
                  <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
                </Text>
                <Field name="contactTelegram">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter Telegram handle"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
                <Stack>
                  {errors.contactTelegram && touched.contactTelegram && (
                    <Text
                      m={0}
                      style={{
                        color: COLOR.red,
                        fontSize: "12px",
                        width: "100%",
                        fontFamily: "DM Sans4",
                        fontWeight: 400,
                      }}
                    >
                      {errors.contactTelegram}
                    </Text>
                  )}
                </Stack>

                <Text
                  sx={{
                    color: COLOR.fourteen,
                    fontFamily: "DM Sans5",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                  m={0}
                >
                  Do you have any questions for us (Optional)
                </Text>
                <Field name="questions">
                  {({ field }: FieldProps<string>) => (
                    <Input
                      {...field}
                      placeholder="Enter here"
                      autoComplete="off"
                      color={COLOR.white}
                      bg={COLOR.bgNav}
                      border={`1px solid ${COLOR.borderReputation}`}
                    />
                  )}
                </Field>
              </Stack>
              <Button
                zIndex={1}
                isLoading={isLoading}
                type="submit"
                p="0 10px"
                justifyContent="center"
                cursor="pointer"
                gap="10px"
                h="44px"
                borderRadius="10px"
                bg={COLOR.nineteen}
                alignItems="center"
                fontSize="16px"
                fontWeight={700}
                lineHeight="30px"
                fontFamily="DM Sans"
                color={COLOR.sixth}
                _hover={{ bg: COLOR.nineteen }}
              >
                Subscribe
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default StartUpForm;
