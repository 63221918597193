// theme.ts

import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      "html, body": {
        fontFamily: `'Montserrat', PT Sans,  sans-serif,Inter`,
        padding: 0,
        margin: 0,
        scrollBehavior: "smooth",
      },
    },
  },
});

export default theme;
