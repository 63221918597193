export const data = [
  {
    title: "Onebit Ventures – Onebit investment fund",
  },
  {
    title: "Onebit Startup Pad - Startup incubator",
  },
  {
    title: " Solution Provider ",
  },
  {
    title: "Onebit Incubation – Incubating potential projects",
  },
  {
    title: "Onebit Trading - Asset management and trading",
  },
  {
    title: "Onebit Media",
  },
];
