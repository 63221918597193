// ModalComponent.tsx
import { Button, Input, Select, Stack, Text, useToast } from "@chakra-ui/react";
import COLOR from "../../constant/API_ROUTES/color";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
const InvestorForm = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const URL = /^(ftp|http|https):\/\/[^ "]+$/;
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Organization's Name is required"),
    type: Yup.string().required("Organization Type is required"),
    websiteUrl: Yup.string().matches(URL, "Enter a valid url"),
    location: Yup.string().required("Organization's Location type is required"),
    archiv: Yup.string().required(
      "Organization’s Experience and Achievements type is required"
    ),
    description: Yup.string()
      .required("Description type is required")
      .min(50, "Description must be at least 50 characters")
      .max(500, "Description must be at least 500 characters"),
    maxFund: Yup.number()
      .required("The maximum fund is required")
      .min(0)
      .max(5000000000, "The maximum fund is 5 billion")
      .nullable()
      .test(
        "noEOrSign",
        "Number had an 'e' or sign.",
        (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
      ),
    contactName: Yup.string().required(
      "Name and title of contact person is required"
    ),
    contactEmail: Yup.string()
      .required("Email of contact person is required")
      .email("Must be a valid email")
      .max(255),
    contactTelegram: Yup.string().matches(URL, "Enter a valid url"),
  });

  const handleSubscribe = async (values: any, { resetForm }: any) => {
    setIsLoading(true);

    try {
      for (const key in values) {
        if (values[key] === "") {
          delete values[key];
        }
      }
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pitch`, {
        method: "POST",
        body: JSON.stringify({ type: "investor", data: values }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast({
          title: "Subscribe successful",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        resetForm();
      } else {
        const data = await response.json();
        if (data.error === "AlreadySubscribed") {
          toast({
            title: "You have already subscribed",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Failed to subscribe");
        }
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Failed to subscribe",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        name: "",
        type: "",
        websiteUrl: "",
        location: "",
        archiv: "",
        description: "",
        maxFund: "",
        contactName: "",
        contactEmail: "",
        questions: "",
        contactTelegram: "",
      }}
      validationSchema={RegisterSchema}
      onSubmit={handleSubscribe}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          <Stack w="full" className="form">
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Organization&apos;s Name
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="name">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Enter your Organization's Name"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.name && touched.name && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.name}
                </Text>
              )}
            </Stack>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                fontFamily: "DM Sans5",
                lineHeight: "28px",
              }}
              m={0}
            >
              Organization Type
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="type">
              {({ field }: FieldProps<string>) => (
                <Select
                  {...field}
                  color={COLOR.white}
                  placeholder="Select Type"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                >
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="VC"
                  >
                    VC
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="IndividualInvestors"
                  >
                    Individual Investors
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="InvestmentFund"
                  >
                    Investment fund
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="VenturesDAO"
                  >
                    Ventures DAO
                  </option>
                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="PrivateEquity"
                  >
                    Private Equity
                  </option>

                  <option
                    style={{
                      color: COLOR.white,
                      fontWeight: "400",
                      fontFamily: "DM Sans4",
                    }}
                    value="Other"
                  >
                    Other
                  </option>
                </Select>
              )}
            </Field>
            <Stack>
              {errors.type && touched.type && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.type}
                </Text>
              )}
            </Stack>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Organization’s Website URL
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="websiteUrl">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  placeholder="Enter your Organization URL"
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.websiteUrl && touched.websiteUrl && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.websiteUrl}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Where is your Organization based?
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="location">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  placeholder="Enter your Organization’s Location"
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.location && touched.location && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.location}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Tell us more about your Organization (Vision/Mission/Invest
              strategies)
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="description">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter your Organization description"
                  color={COLOR.white}
                  autoComplete="off"
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.description && touched.description && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.description}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: ",Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Organization’s Experience and Achievements
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="archiv">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter the Organization’s Experience and Achievements"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.archiv && touched.archiv && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.archiv}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              The maximum fund that your Organization can invest for any
              Fundraising round
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="maxFund">
              {({ field }: FieldProps<string>) => (
                <Input
                  type="number"
                  {...field}
                  placeholder="Enter the maximum fund"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.maxFund && touched.maxFund && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.maxFund}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Name and title of contact person
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="contactName">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter name and title"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.contactName && touched.contactName && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.contactName}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Email of contact person
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="contactEmail">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter Email"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.contactEmail && touched.contactEmail && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.contactEmail}
                </Text>
              )}
            </Stack>

            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Telegram of contact person
              <span style={{ color: COLOR.red, marginLeft: "5px" }}>*</span>
            </Text>
            <Field name="contactTelegram">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  autoComplete="off"
                  placeholder="Enter Telegram handle"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Stack>
              {errors.contactTelegram && touched.contactTelegram && (
                <Text
                  m={0}
                  style={{
                    color: COLOR.red,
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "DM Sans4",
                    fontWeight: 400,
                  }}
                >
                  {errors.contactTelegram}
                </Text>
              )}
            </Stack>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans5",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
              }}
              m={0}
            >
              Do you have any questions for us (Optional)
            </Text>
            <Field name="questions">
              {({ field }: FieldProps<string>) => (
                <Input
                  {...field}
                  placeholder="Enter here"
                  autoComplete="off"
                  color={COLOR.white}
                  bg={COLOR.bgNav}
                  border={`1px solid ${COLOR.borderReputation}`}
                />
              )}
            </Field>
            <Button
              zIndex={1}
              isLoading={isLoading}
              type="submit"
              p="0 10px"
              justifyContent="center"
              cursor="pointer"
              gap="10px"
              h="44px"
              borderRadius="10px"
              bg={COLOR.nineteen}
              alignItems="center"
              fontSize="16px"
              fontWeight={700}
              lineHeight="30px"
              fontFamily="DM Sans"
              color={COLOR.sixth}
              _hover={{ bg: COLOR.nineteen }}
            >
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default InvestorForm;
