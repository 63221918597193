import "swiper/css";

import { Flex, FlexProps, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import axios from "axios";
import { BlogType } from "../BlogType";
// import { DefaultBlog } from "../BlogDefault";
type Props = {} & FlexProps;

export type CategoryType = {
  title: string;
  img: string;
  description: string;
  href?: string;
};

const SlideBlog = ({ ...props }: Props) => {
  const { isSmallDevice, isTabletOrLaptop, isMobileOrTablet } = useResponsive();
  const [blogs, setBlogs] = useState<BlogType[]>([]);

  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // call api
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blogs?limit=10`)
      .then((res) => {
        // console.log(res.data.data.blogs);
        setBlogs(res.data.data.blogs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Flex>
      <Swiper
        style={{
          width: isSmallDevice ? "400px" : "1700px",
          height: isMobileOrTablet
            ? "650px"
            : isTabletOrLaptop
            ? "450px"
            : "500px",
        }}
        loop={true}
        autoplay={true}
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={55}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
          type: "bullets",
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {blogs.map((item, index) => (
          <SwiperSlide key={index}>
            <Link to={`${APP_ROUTES.NEWSDETAIL}/${item._id}`}>
              <Flex gap="20px" direction={isMobileOrTablet ? "column" : "row"}>
                <img
                  src={item.bannerUrl}
                  alt={item.title}
                  style={{
                    borderRadius: "16px",
                    height: "400px",
                    width: isMobileOrTablet
                      ? "100%"
                      : isTabletOrLaptop
                      ? "500px"
                      : "700px",
                  }}
                />
                <Stack spacing={0}>
                  <Text
                    m={0}
                    color={COLOR.white}
                    fontFamily="DM Sans"
                    fontSize="36px"
                    fontWeight="700"
                    lineHeight="52px"
                  >
                    {item.title}
                  </Text>

                  <Text
                    m={0}
                    color={COLOR.white}
                    fontFamily="DM Sans4"
                    fontSize="20px"
                    lineHeight="32px"
                    fontWeight="400"
                  >
                    {item.shortDes}
                  </Text>

                  <Stack
                    mt="20px"
                    transition="all 235ms ease-in-out"
                    cursor="pointer"
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                    }}
                    bg={isTabletOrLaptop ? COLOR.white : COLOR.nineteen}
                    p="0 16px"
                    w="120px"
                    borderRadius="1000px"
                    h="44px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      textAlign="center"
                      fontFamily="DM Sans"
                      fontSize="16px"
                      fontWeight={700}
                      lineHeight="30px"
                      color={COLOR.colorButton}
                      m={0}
                    >
                      Read more
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Flex>
  );
};

export default SlideBlog;
