import { Flex, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";

interface Tab {
  title: string;
  label: string;
  desc: string;
  img: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const TabProfile: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex justifyContent="center" w="full">
      <Container
        direction={isTabletOrLaptop ? "column" : "column"}
        w="full"
        gap="20px"
        alignItems="center"
      >
        <Flex p="20px 0" gap="10px" w="full" alignItems="center">
          {tabs.map((tab, index) => (
            <Flex
              cursor="pointer"
              p="6px 16px"
              justifyContent="center"
              key={index}
              className={`tab-item ${index === activeTab ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
              borderRadius="4px"
              bg={index === activeTab ? COLOR.seventh : "none"}
              border={
                index === activeTab ? `1px solid ${COLOR.eighth}` : "none"
              }
            >
              <Text
                textAlign="center"
                color={index === activeTab ? "#FFF" : "#FFF"}
                fontFamily="DM Sans"
                fontSize="14px"
                fontWeight={700}
                lineHeight="22px"
                m={0}
              >
                {tab.label}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex
          justifyContent="center"
          gap="50px"
          w="full"
          h="auto"
          direction={isMobileOrTablet ? "column" : "row"}
        >
          <Stack w={isMobileOrTablet ? "100%" : "50%"}>
            <Text
              textAlign="left"
              fontFamily="DM Sans"
              fontSize="40px"
              fontWeight={600}
              lineHeight="52px"
              color={COLOR.white}
              m={0}
            >
              {tabs[activeTab].title}
            </Text>
            <Text
              textAlign="left"
              fontFamily="DM Sans4"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              color={COLOR.white}
              m={0}
            >
              {tabs[activeTab].desc}
            </Text>
            <Stack spacing={0} mt="40px">
              {tabs[activeTab].content}
            </Stack>
          </Stack>
          <Stack w={isMobileOrTablet ? "100%" : "50%"}>
            <img
              style={{
                width: "100%",
                height: "100%",
                maxHeight: isTabletOrLaptop ? "100%" : "440px",
              }}
              src={tabs[activeTab].img}
              alt=""
            />
          </Stack>
        </Flex>
      </Container>
    </Flex>
  );
};

export default TabProfile;
