import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section3 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Disclosure of Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Business Transactions
      </Text>
      <Text
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Text>
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Law enforcement
      </Text>
      <Text
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Text>
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Other legal requirements
      </Text>
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Text>
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Comply with a legal obligation
      </Text>{" "}
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Protect and defend the rights or property of the Company
      </Text>{" "}
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Prevent or investigate possible wrongdoing in connection with the
        Service
      </Text>{" "}
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Protect the personal safety of Users of the Service or the public
      </Text>{" "}
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Protect against legal liability
      </Text>
      <Text
        m={0}
        zIndex={1}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Security of Your Personal Data
      </Text>
      <Text
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
        zIndex={1}
      >
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Text>
    </Stack>
  );
};

export default Section3;
