import IMAGES from "../images";

export const reputation = [
  {
    img: IMAGES.invested,
    number: "$6M +",
    title: "Invested",
    desc: "Fund focused on exploring high-quality projects with great potential",
  },
  {
    img: IMAGES.project,
    number: "60 +",
    title: "Portfolio projects ",
    desc: "In various aspects, including blockchain basic infrastructures, GameFi, DeFi, WEB3.0, NFT and Metaverse",
  },
  {
    img: IMAGES.kol,
    number: "100 +",
    title: "KOLs",
    desc: "With large communities, strong expertises and great influences on Blockchain Technology",
  },
];
