import {
  Button,
  Flex,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import BlogCard from "../../component/BlogCard";
// import { DefaultBlog } from "../../component/BlogDefault";
import { AllType, BlogType } from "../../component/BlogType";
import Container from "../../component/Container";
import SlideBlog from "../../component/SlideBlog";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import axios from "axios";

const News: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  // const [blogs, setBlogs] = useState(DefaultBlog);
  const [blogsTest, setBlogsTest] = useState<BlogType[]>([]);
  const [blogFilter, setBlogsFilter] = useState<BlogType[]>();
  const [typeFilter, setTypeFilter] = useState<AllType | null>(null);
  const [isLoadings, setIsLoadings] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);
  const [type, setType] = useState<string>("all");
  const [isDisableNext, setIsDisablesNext] = useState<boolean>(false);
  const [isDisablePrev, setIsDisablePrev] = useState<boolean>(false);

  // const [limit, setLimit] = useState<Number>(6);

  // const filterAll = (all: AllType) => {
  //   const filterProducts = blogsTest.filter((item) => {
  //     return item.all === all;
  //   });
  //   setTypeFilter(all);
  //   // setBlogsTest(filterProducts);
  // };
  // const filterName = (name: string) => {
  //   if (!!name.trim()) {
  //     const filterProducts = blogsTest?.filter((item) => {
  //       return item.title.toUpperCase().indexOf(name.toUpperCase()) > -1;
  //     });

  //     setBlogsFilter(filterProducts);
  //   } else {
  //   }
  // };
  // call api blogs

  const blogsFilter = (name: string) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blogs?keyword=${name}`)
      .then((res) => {
        // console.log(res.data.data.blogs);
        setBlogsFilter(res.data.data.blogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const blogsFilterTypes = (skip: number, limit: number, type: string) => {
    setIsLoadings(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/blogs?skip=${skip}&limit=${limit}&type=${type}`
      )
      .then((res) => {
        const totalRecord = res.data.data.totalRecords;
        const count = skip * limit + res.data.data.blogs.length;
        if (count >= totalRecord) {
          setIsDisablesNext(true);
        } else {
          setIsDisablesNext(false);
        }
        setIsLoadings(false);
        setBlogsFilter(res.data.data.blogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNext = () => {
    setSkip(skip + 1);
    setIsDisablePrev(false);
  };
  const handlePrev = () => {
    setSkip(skip - 1);
  };
  useEffect(() => {
    blogsFilterTypes(skip, limit, type);
    if (skip === 0) {
      setIsDisablePrev(true);
    }
  }, [skip, type]);

  return (
    <Stack
      bg={COLOR.sixth}
      sx={{
        backgroundImage: `url(${IMAGES.BGBlog})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container direction="column" p="100px 0" mt="5rem">
        <SlideBlog />
        <Flex
          mt="30px"
          direction={isMobileOrTablet ? "column" : "row"}
          justifyContent="space-between"
          w="full"
          gap="20px"
        >
          <Flex
            alignItems="center"
            gap="16px"
            flexWrap={isMobileOrTablet ? "wrap" : "nowrap"}
          >
            <Text
              m={0}
              cursor="pointer"
              zIndex={1}
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              textAlign="center"
              color={
                typeFilter === AllType.all || !typeFilter
                  ? COLOR.white
                  : COLOR.fourteen
              }
              borderRadius={
                typeFilter === AllType.all || !typeFilter ? "3px" : "none"
              }
              p={
                typeFilter === AllType.all || !typeFilter ? "6px 10px" : "none"
              }
              bg={
                typeFilter === AllType.all || !typeFilter
                  ? COLOR.seventh
                  : "none"
              }
              border={
                typeFilter === AllType.all || !typeFilter
                  ? `1px solid ${COLOR.eighth}`
                  : "none"
              }
              onClick={() => {
                setType("all");
                setSkip(0);
                setTypeFilter(AllType.all);
              }}
            >
              All
            </Text>
            <Text
              m={0}
              cursor="pointer"
              zIndex={1}
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              textAlign="center"
              color={
                typeFilter === AllType.announcement
                  ? COLOR.white
                  : COLOR.fourteen
              }
              onClick={() => {
                setType("announcement");
                setSkip(0);
                setTypeFilter(AllType.announcement);
              }}
              borderRadius={
                typeFilter === AllType.announcement ? "3px" : "none"
              }
              p={typeFilter === AllType.announcement ? "6px 10px" : "none"}
              bg={typeFilter === AllType.announcement ? COLOR.seventh : "none"}
              border={
                typeFilter === AllType.announcement
                  ? `1px solid ${COLOR.eighth}`
                  : "none"
              }
            >
              Announcement
            </Text>{" "}
            <Text
              m={0}
              cursor="pointer"
              zIndex={1}
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              textAlign="center"
              color={
                typeFilter === AllType.blockchain ? COLOR.white : COLOR.fourteen
              }
              onClick={() => {
                setType("blockchain");
                setSkip(0);
                setTypeFilter(AllType.blockchain);
              }}
              borderRadius={typeFilter === AllType.blockchain ? "3px" : "none"}
              p={typeFilter === AllType.blockchain ? "6px 10px" : "none"}
              bg={typeFilter === AllType.blockchain ? COLOR.seventh : "none"}
              border={
                typeFilter === AllType.blockchain
                  ? `1px solid ${COLOR.eighth}`
                  : "none"
              }
            >
              Blockchain
            </Text>{" "}
            <Text
              m={0}
              cursor="pointer"
              zIndex={1}
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              textAlign="center"
              color={
                typeFilter === AllType.investment ? COLOR.white : COLOR.fourteen
              }
              onClick={() => {
                setType("investment");
                setSkip(0);
                setTypeFilter(AllType.investment);
              }}
              borderRadius={typeFilter === AllType.investment ? "3px" : "none"}
              p={typeFilter === AllType.investment ? "6px 10px" : "none"}
              bg={typeFilter === AllType.investment ? COLOR.seventh : "none"}
              border={
                typeFilter === AllType.investment
                  ? `1px solid ${COLOR.eighth}`
                  : "none"
              }
            >
              Investment
            </Text>{" "}
            <Text
              m={0}
              cursor="pointer"
              zIndex={1}
              transition="all 235ms ease-in-out"
              _hover={{
                transform: "translate3d(0, 2px, 0)",
              }}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              textAlign="center"
              color={
                typeFilter === AllType.project ? COLOR.white : COLOR.fourteen
              }
              onClick={() => {
                setSkip(0);
                setType("project");
                setTypeFilter(AllType.project);
              }}
              borderRadius={typeFilter === AllType.project ? "3px" : "none"}
              p={typeFilter === AllType.project ? "6px 10px" : "none"}
              bg={typeFilter === AllType.project ? COLOR.seventh : "none"}
              border={
                typeFilter === AllType.project
                  ? `1px solid ${COLOR.eighth}`
                  : "none"
              }
            >
              Project
            </Text>{" "}
          </Flex>
          <InputGroup w={isMobile ? "100%" : isTabletOrLaptop ? "30%" : "10%"}>
            <InputLeftElement pointerEvents="none">
              <img src={IMAGES.search} alt="" width="20px" height="20px" />
            </InputLeftElement>
            <Input
              color={COLOR.white}
              bg={COLOR.sixth}
              border="none"
              onChange={(event) => {
                blogsFilter(event.target.value);
              }}
              placeholder="Search...."
            />
          </InputGroup>
        </Flex>
        <Grid
          mt="30px"
          gridTemplateColumns={
            isMobile ? "1fr" : isTabletOrLaptop ? "1fr 1fr" : "1fr 1fr 1fr"
          }
          gridRowGap="20px"
          gridColumnGap="20px"
        >
          {(blogFilter ? blogFilter : blogsTest)?.map((item, index) => (
            <Flex key={item._id}>{<BlogCard blog={item} />}</Flex>
          ))}
        </Grid>
        <Flex marginTop={10} columnGap={5}>
          <Spacer />
          <Button
            isLoading={isLoadings}
            isDisabled={isDisablePrev}
            loadingText="Loading"
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="end"
            display=""
            onClick={() => {
              handlePrev();
            }}
          >
            Prev
          </Button>
          <Button
            isLoading={isLoadings}
            loadingText="Final"
            isDisabled={isDisableNext}
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="end"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </Flex>
      </Container>
    </Stack>
  );
};
export default News;
