import { Flex, FlexProps } from "@chakra-ui/react";
import useResponsive from "../../hooks/useResponsive";

const Container = ({ children, ...props }: FlexProps) => {
  const { isBigScreen } = useResponsive();
  return (
    <Flex w={!isBigScreen ? "90vw" : "90vw"} m={"0 auto"} {...props}>
      {children}
    </Flex>
  );
};

export default Container;
