import {
  Button,
  Divider,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import { socials } from "./Socials";
import { Link } from "react-router-dom";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
const Footer: React.FC = () => {
  const { isMobileOrTablet } = useResponsive();
  const toast = useToast();
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });
  const handleSubscribe = async (
    values: { email: any },
    { resetForm }: any
  ) => {
    setIsLoading(true);

    try {
      const { email } = values;
      const datas = await fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (datas.status === 200) {
        toast({
          title: "Subscribe successful",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }
      if (datas.status === 400) {
        toast({
          title: "You already subscribe",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }
      resetForm();
    } catch (err) {
      console.log(err);
      // if (errorResponse.status === 400) {
      //   toast({
      //     title: "You already subscribe",
      //     status: "error",
      //     position: "top-right",
      //     duration: 3000,
      //     isClosable: true,
      //   });
      // }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack bg={COLOR.twentyOne}>
      <Container>
        <Stack w="full" p="50px 0">
          <Stack gap="30px">
            <Flex justifyContent="space-between" w="full" alignItems="center">
              <img src={IMAGES.logoWeb} width="130px" height="32px" alt="" />
              <Stack
                onClick={() =>
                  window.location.replace(
                    `${window.location.origin}/#SectionPortfolio`
                  )
                }
                cursor="pointer"
              >
                <Text
                  m={0}
                  color={COLOR.fifteen}
                  fontFamily="DM Sans"
                  fontWeight={500}
                  borderBottom={`1px solid ${COLOR.fifteen}`}
                >
                  Our Portfolio
                </Text>
              </Stack>
            </Flex>
            <Divider opacity={0.2} bg={COLOR.twentyTwo} />

            <Flex
              justifyContent="space-between"
              w="full"
              gap="30px"
              direction={isMobileOrTablet ? "column" : "row"}
            >
              <Stack cursor="pointer" gap="20px">
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={500}
                  lineHeight="21px"
                  fontFamily="DM Sans"
                  color={COLOR.sixteen}
                  _hover={{
                    color: COLOR.sixteen,
                  }}
                >
                  Home
                </Text>

                <Link to={APP_ROUTES.PROFILE} onClick={handleLabelClick}>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="21px"
                    fontFamily="DM Sans"
                    color={COLOR.fifth}
                    _hover={{
                      color: COLOR.sixteen,
                    }}
                  >
                    Profile
                  </Text>
                </Link>
                <Link to={APP_ROUTES.NEWS} onClick={handleLabelClick}>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="21px"
                    fontFamily="DM Sans"
                    color={COLOR.fifth}
                    _hover={{
                      color: COLOR.sixteen,
                    }}
                  >
                    Blogs
                  </Text>
                </Link>
                <Link to={APP_ROUTES.ABOUT} onClick={handleLabelClick}>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="21px"
                    fontFamily="DM Sans"
                    color={COLOR.fifth}
                    _hover={{
                      color: COLOR.sixteen,
                    }}
                  >
                    Career
                  </Text>
                </Link>
              </Stack>
              <Stack cursor="pointer" gap="20px">
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={500}
                  lineHeight="21px"
                  fontFamily="DM Sans"
                  color={COLOR.sixteen}
                >
                  Platform
                </Text>
                <Link to={APP_ROUTES.PRIVACY} onClick={handleLabelClick}>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="21px"
                    fontFamily="DM Sans"
                    color={COLOR.fifth}
                    _hover={{
                      color: COLOR.sixteen,
                    }}
                  >
                    Privacy Policy
                  </Text>
                </Link>
                <Link to={APP_ROUTES.TERM} onClick={handleLabelClick}>
                  <Text
                    m={0}
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="21px"
                    fontFamily="DM Sans"
                    color={COLOR.fifth}
                    _hover={{
                      color: COLOR.sixteen,
                    }}
                  >
                    Term & Conditions
                  </Text>
                </Link>
              </Stack>{" "}
              <Stack gap="20px">
                <Text
                  m={0}
                  fontSize="16px"
                  fontWeight={500}
                  lineHeight="21px"
                  fontFamily="DM Sans"
                  color={COLOR.sixteen}
                >
                  Newsletter
                </Text>
                <Text
                  m={0}
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="24px"
                  fontFamily="DM Sans4"
                  color={COLOR.seventeen}
                >
                  Subscribe our newsletter to get more free design course and
                  resources
                </Text>
                <Formik
                  onSubmit={handleSubscribe}
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={RegisterSchema}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Flex
                        className="form"
                        // justifyContent="space-between"
                        gap="10px"
                      >
                        <Stack>
                          <Field name="email">
                            {({ field }: FieldProps<string>) => (
                              <Input
                                {...field}
                                w="100%"
                                border={`1px solid ${COLOR.eighteen}`}
                                bg={COLOR.sixth}
                                color={COLOR.white}
                                borderRadius="16px"
                                placeholder="Enter your email"
                                className="input"
                                autoComplete="off"
                                style={{ textDecoration: "none" }}
                              />
                            )}
                          </Field>

                          <Stack>
                            {errors.email && touched.email && (
                              <Text
                                style={{
                                  color: COLOR.red,
                                  fontSize: "12px",
                                  marginLeft: "20px",
                                  width: "100%",
                                  fontFamily: "DM Sans4",
                                  fontWeight: 400,
                                }}
                              >
                                {errors.email}
                              </Text>
                            )}
                          </Stack>
                        </Stack>

                        <Button
                          isLoading={isLoading}
                          p="0 10px"
                          justifyContent="center"
                          cursor="pointer"
                          gap="10px"
                          h="44px"
                          borderRadius="1000px"
                          bg={COLOR.nineteen}
                          alignItems="center"
                          type="submit"
                          fontSize="16px"
                          fontWeight={700}
                          lineHeight="30px"
                          fontFamily="DM Sans"
                          color={COLOR.sixth}
                          _hover={{
                            bg: COLOR.nineteen,
                          }}
                        >
                          Subscribe
                        </Button>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Flex>
            <Divider opacity={0.2} bg={COLOR.twentyTwo} />

            <Flex
              justifyContent="space-between"
              w="full"
              direction={isMobileOrTablet ? "column" : "row"}
            >
              <Text
                sx={{
                  color: COLOR.fifth,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: isMobileOrTablet ? "center" : "left",
                  fontFamily: "DM Sans4",
                  fontWeight: 400,
                }}
              >
                © 2024 Onebit Ventures
              </Text>
              <Flex justifyContent="center" gap="10px">
                {socials.map((i) => (
                  <a href={i.link} target="blank" rel="noreferrer" key={i.img}>
                    <Flex
                      transition="all 235ms ease-in-out"
                      cursor="pointer"
                      _hover={{
                        transform: "translate3d(0, 2px, 0)",
                      }}
                      w="40px"
                      h="40px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img src={i.img} alt="" />
                    </Flex>
                  </a>
                ))}
              </Flex>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
export default Footer;
