import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section1 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        {" "}
        Interpretation and Definitions
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Interpretation
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Definitions
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Account
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          For the purposes of this Privacy Policy:
        </span>
      </Text>

      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Affiliate
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          means an entity that controls, is controlled by or is under common
          control with a party, where "control" means ownership of 50% or more
          of the shares, equity interest or other securities entitled to vote
          for election of directors or other managing authority.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Company
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          (referred to as either "the Company", "We", "Us" or "Our" in this
          Agreement) refers to ONEBIT VENTURES PTE. LTD, 400 ORCHARD ROAD,
          #05-12, ORCHARD TOWERS, SINGAPORE 238875. Cookies are small files that
          are placed on Your computer, mobile device or any other device by a
          website, containing the details of Your browsing history on that
          website among its many uses.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Country
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          refers to: Singapore
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Device
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          means any device that can access the Service such as a computer, a
          cellphone or a digital tablet.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Personal Data
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          is any information that relates to an identified or identifiable
          individual.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Service
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          refers to the Website.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Service Provider
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analyzing how the Service is used.
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Usage Data
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Website
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          refers to Onebit Ventures, accessible from onebit.ventures
        </span>
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        You
        <span
          style={{
            fontFamily: "DM Sans4",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: COLOR.fourteen,
          }}
        >
          {" "}
          means the individual accessing or using the Service, or the company,
          or other legal entity on behalf of which such individual is accessing
          or using the Service, as applicable.
        </span>
      </Text>
    </Stack>
  );
};

export default Section1;
