import { Button, Divider, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Container from "../../../component/Container";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import COLOR from "../../../constant/API_ROUTES/color";
const job = [
  {
    title: "DevOps Engineer",
    desc: "Senior",
    img: IMAGES.career5,
  },
  {
    title: "Business Development",
    desc: "Senior",
    img: IMAGES.career6,
  },
  {
    title: "UI/UX Designer",
    desc: "Senior",
    img: IMAGES.career7,
  },
];
const AboutDetail: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();

  return (
    <Stack
      bg={COLOR.sixth}
      sx={{
        backgroundImage: `url(${IMAGES.tron})`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container direction="column" p="100px 0" mt="5rem" gap="50px">
        <Flex
          w="full"
          gap="30px"
          direction={isTabletOrLaptop ? "column" : "row"}
        >
          <Stack w={isTabletOrLaptop ? "100%" : "40%"} gap="30px">
            <img src={IMAGES.CD1} alt="" />
            <Flex
              display={isTabletOrLaptop ? "none" : "flex"}
              justifyContent="space-between"
              w="full"
              flexWrap="wrap"
            >
              <img src={IMAGES.CD2} alt="" />
              <img src={IMAGES.CD3} alt="" />
              <img src={IMAGES.CD4} alt="" />
              <img src={IMAGES.CD5} alt="" />
            </Flex>
          </Stack>
          <Stack w={isTabletOrLaptop ? "100%" : "60%"}>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="700"
              lineHeight="42px"
            >
              UI/UX Designer
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              m={0}
              fontSize="20px"
              fontWeight="700"
              lineHeight="32px"
            >
              Level: Senior
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              fontSize="20px"
              m={0}
              fontWeight="700"
              lineHeight="32px"
            >
              Type: Full-time
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              fontSize="20px"
              m={0}
              fontWeight="700"
              lineHeight="32px"
            >
              Salary: Negotiate
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              m={0}
              fontSize="20px"
              fontWeight="700"
              lineHeight="32px"
            >
              Application Deadline: 04/30/2024
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans"
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
            >
              Job Description
            </Text>
            <Text
              color={COLOR.white}
              fontFamily="DM Sans4"
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
            >
              We are a dynamic and innovative company dedicated to
              revolutionizing the way people experience digital products. With a
              focus on creativity, usability, and user-centric design, we are
              committed to delivering exceptional experiences across all
              platforms.
            </Text>
            <a href="mailto:recruitment@onebit.ventures">
              <Button
                bg="#07B6D5"
                color="sixth"
                fontFamily="DM Sans5"
                fontSize="18px"
                fontWeight="500"
                lineHeight="28px"
              >
                Apply Now
              </Button>
            </a>
            <Text
              mt="20px"
              color={COLOR.white}
              fontFamily="DM Sans5"
              fontSize="16px"
              fontWeight="500"
              lineHeight="24px"
            >
              Responsibilities
            </Text>
            <Text
              color={COLOR.fourteen}
              fontFamily="DM Sans4"
              fontSize="16px"
              fontWeight="400"
              m={0}
              lineHeight="24px"
            >
              Collaborate with cross-functional teams to understand project
              requirements and objectives.
            </Text>{" "}
            <Text
              color={COLOR.fourteen}
              fontFamily="DM Sans4"
              fontSize="16px"
              m={0}
              fontWeight="400"
              lineHeight="24px"
            >
              Design intuitive and engaging user interfaces for web and mobile
              applications.
            </Text>{" "}
            <Text
              color={COLOR.fourteen}
              fontFamily="DM Sans4"
              fontSize="16px"
              m={0}
              fontWeight="400"
              lineHeight="24px"
            >
              Conduct user research, usability testing, and gather feedback to
              iterate on designs.
            </Text>{" "}
            <Text
              color={COLOR.fourteen}
              fontFamily="DM Sans4"
              fontSize="16px"
              m={0}
              fontWeight="400"
              lineHeight="24px"
            >
              Create wireframes, prototypes, and mockups to effectively
              communicate design ideas.
            </Text>{" "}
            <Text
              color={COLOR.fourteen}
              fontFamily="DM Sans4"
              m={0}
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
            >
              Ensure consistency in design elements and maintain brand standards
              throughout projects.
            </Text>
            <Divider />
            <Flex justifyContent="space-between" w="full">
              <Text
                m={0}
                color={COLOR.white}
                fontFamily="DM Sans5"
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
              >
                Requirement
              </Text>
              <img src={IMAGES.Icon} alt="" />
            </Flex>
            <Divider />
            <Flex justifyContent="space-between" w="full">
              <Text
                color={COLOR.white}
                fontFamily="DM Sans5"
                m={0}
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
              >
                Benefits
              </Text>
              <img src={IMAGES.Icon} alt="" />
            </Flex>
            <Divider />
            <Flex justifyContent="space-between" w="full">
              <Text
                color={COLOR.white}
                fontFamily="DM Sans5"
                fontSize="16px"
                m={0}
                fontWeight="500"
                lineHeight="24px"
              >
                Contact
              </Text>
              <img src={IMAGES.Icon} alt="" />
            </Flex>
            <Divider />
          </Stack>
        </Flex>
        <Stack>
          <Text
            fontFamily="DM Sans"
            fontSize="30px"
            fontWeight="700"
            lineHeight="42px"
            color={COLOR.white}
          >
            Related Jobs
          </Text>
          <Grid
            gridTemplateColumns={isMobileOrTablet ? "1fr" : "1fr 1fr 1fr"}
            gridColumnGap="30px"
            gridRowGap="30px"
          >
            {job.map((item, index) => (
              <Stack>
                <img style={{ width: "100%" }} src={item.img} alt="" />
                <Text
                  m={0}
                  fontFamily="DM Sans"
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="28px"
                  color={COLOR.white}
                >
                  {item.title}
                </Text>
                <Text
                  m={0}
                  fontFamily="DM Sans4"
                  fontSize="16px"
                  fontWeight="400"
                  lineHeight="24px"
                  color={COLOR.fourteen}
                >
                  {item.desc}
                </Text>
              </Stack>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
};
export default AboutDetail;
