import "swiper/css";

import { Flex, FlexProps, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { Autoplay } from "swiper/modules";

import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
type Props = {} & FlexProps;

export type CategoryType = {
  title: string;
  img: string;
  description: string;
  href?: string;
};

const categories: CategoryType[] = [
  {
    title: "Educating",
    description:
      "Providing free finance and investment courses to ensure investors are well-informed.",
    img: `${IMAGES.icon5}`,
  },
  {
    title: "Advising",
    description:
      "Offering guidance on personal financial management for individual investment strategies.",
    img: `${IMAGES.icon2}`,
  },
  {
    title: "Networking ",
    description:
      "Sharing potential investment opportunities to connect investors with promising ventures.",
    img: `${IMAGES.icon3}`,
  },
  {
    title: " Supporting",
    description:
      "Assisting in marketing project-related information for enhanced visibility.",
    img: `${IMAGES.icon4}`,
  },
];

const InvestorSlider = ({ ...props }: Props) => {
  const { isSmallDevice, isTabletOrLaptop, isMobileOrTablet } = useResponsive();
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <Flex {...props} p="0 50px 0 0">
      <Swiper
        style={{
          width: isSmallDevice ? "400px" : "700px",
          height: "400px",
        }}
        grabCursor={true}
        slidesPerView={2}
        autoplay={true}
        spaceBetween={55}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
          type: "bullets",

          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
          clickableClass: "swiper-pagination-clickable",
          modifierClass: "swiper-pagination-",
          currentClass: "swiper-pagination-current",
          totalClass: "swiper-pagination-total",
          hiddenClass: "swiper-pagination-hidden",
        }}
        modules={[Pagination, Autoplay]}
        loop={true}
        className="mySwiper"
      >
        {categories.map((item, index) => (
          <SwiperSlide>
            <Stack
              cursor="pointer"
              p="10px"
              borderRadius="16px"
              border={`1px solid ${COLOR.bg}`}
              _hover={{
                boxShadow: "5px 4px 22px 0px #38EDF91C",
                transform: "translate3d(0, 2px, 0)",
                bg: "linear-gradient(80deg, rgba(96, 96, 96, 0.20) -5.25%, rgba(21, 112, 250, 0.20) 69.95%, rgba(26, 188, 252, 0.20) 82.08%, rgba(55, 255, 207, 0.20) 90.29%)",
              }}
              transition="all 235ms ease-in-out"
              mt="20px"
              justifyContent="center"
              alignItems="center"
              key={item.img}
              bg={COLOR.twentyFour}
              w="320px"
              h="310px"
            >
              <img
                src={item.img}
                alt={item.title}
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <Stack spacing={0}>
                <Text
                  m={0}
                  textAlign="center"
                  color={COLOR.sixteen}
                  fontFamily="DM Sans"
                  fontSize="24px"
                  fontWeight="700"
                >
                  {item.title}
                </Text>

                <Text
                  m={0}
                  textAlign="center"
                  color={COLOR.fifth}
                  fontFamily="DM Sans4"
                  fontSize="16px"
                  fontWeight="400"
                >
                  {item.description}
                </Text>
              </Stack>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Flex>
  );
};

export default InvestorSlider;
