export type BlogType = {
  _id: string;
  bannerUrl: string;
  title: string;
  shortDes: string;
  all?: AllType;
  description?: string;
  url?: string;
  content?: string;
  createdAt?: string;
  postedAt?: string;
  updatedAt?: string;
  types?: string[];
  author?: { _id: string; name: string };
};

export enum AllType {
  all = "all",
  announcement = "announcement",
  blockchain = "blockchain",
  investment = "investment",
  project = "project",
}
