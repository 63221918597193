import { Flex, Stack, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React, { useEffect, useRef, useState } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";

const cards = [
  {
    x: "",
    telegram: "",
    id: 1,
    title: "Chief Technology Officer",
    name: "Phung Thanh Sang",
    description: "",
    image: ``,
  },
  {
    linkIn: "https://www.linkedin.com/in/dong-ngo-hoang/",
    x: "https://twitter.com/phuongdongcorp",
    telegram: "https://t.me/tonybap",
    id: 2,
    title: "Chief Executive Officer",
    name: "Ngo Hoang Dong",
    description: "Founder",
    image: `${IMAGES.founder}`,
  },
  {
    telegram: "https://t.me/scottdo",
    x: "https://twitter.com/scottdoTSC",
    linkIn: "https://www.linkedin.com/in/scott-do-103061122/",
    id: 3,
    title: "Chief Marketing Officer",
    name: "Scott Do",
    description: "",
    image: `${IMAGES.scott}`,
  },

  {
    telegram: "",
    x: "",
    linkIn:
      "https://www.linkedin.com/in/duc-ngo-078089b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    id: 4,
    title: "Chief Financial Officer",
    name: "Ngo Anh Duc",
    description: "",
    image: `${IMAGES.financial}`,
  },

  {
    x: "",
    telegram: "",
    linkIn:
      "https://www.linkedin.com/in/thuy-vu-4a2b28287/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    id: 5,
    title: "Genneral Accounting Officer",
    name: "Jenny Vu",
    description: "",
    image: `${IMAGES.accounting}`,
  },
  {
    x: "",
    telegram: "",
    id: 7,
    title: "Technology Supervisor",
    name: "Vadim Budaiw",
    description: "",
    image: `${IMAGES.supervisor}`,
  },
  {
    telegram: "",
    x: "",
    linkIn: "https://www.linkedin.com/in/louis-valentine-6454682a3/",
    id: 8,
    title: "Partnership Manager",
    name: "Louis Valentine",
    description: "",
    image: `${IMAGES.manager}`,
  },
  {
    telegram: "https://t.me/Huhgnhat",
    x: "",
    linkIn: "",
    id: 9,
    title: "Partner",
    name: "Kengan",
    description: "",
    image: `${IMAGES.kaisen}`,
  },
  {
    telegram: "https://t.me/phoebengn",
    x: "",
    linkIn: "https://www.linkedin.com/in/linh-nguyen-5a46b5226/",
    id: 10,
    title: "Business Development Executive",
    name: "Phoebe Nguyen",
    description: "",
    image: `${IMAGES.phoebe}`,
  },
  {
    telegram: "",
    x: "",
    linkIn: "https://www.linkedin.com/in/phuoc-nguyen-605470265/",
    id: 11,
    title: "Business Development Executive",
    name: "Mr.Bean",
    description: "",
    image: `${IMAGES.executive}`,
  },
];

type Card = {
  linkIn?: string;
  x?: string;
  telegram?: string;
  id: number;
  title?: string;
  name?: string;
  description?: string;
  image?: string;
};

const TeamSlider: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const splideRef = useRef<Splide | null>(null);
  const [cardsPerPage, setCardsPerPage] = useState(1);
  const [sortedCards, setSortedCards] = useState<Card[]>(cards);

  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  useEffect(() => {
    if (isMobileOrTablet) {
      setCardsPerPage(1);
    } else if (isTabletOrLaptop) {
      setCardsPerPage(2);
    } else {
      setCardsPerPage(3);
    }
  }, [isMobile, isTabletOrLaptop]);

  const handlePrevClick = () => {
    if (currentPage === 1) {
      setCurrentPage(Math.ceil(cards.length / cardsPerPage));
    } else {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleNextClick = () => {
    if (currentPage === Math.ceil(cards.length / cardsPerPage)) {
      setCurrentPage(1);
    } else {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const paginatedCards = sortedCards.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );
  useEffect(() => {
    const sortCards = () => {
      const cardIndex = cards.findIndex((card) => card.id === 1);
      console.log(cardIndex);
      if (isMobile && cardIndex === 0) {
        const newCards = [...cards];
        const [card1] = newCards.splice(cardIndex, 1);
        newCards.splice(1, 0, card1);
        return newCards;
      }
      return cards;
    };

    setSortedCards(sortCards());
    console.log(isMobile);
  }, [isMobile]);

  return (
    <Stack position="relative">
      <Flex
        zIndex={1}
        cursor="pointer"
        onClick={handlePrevClick}
        left={isMobile ? "-12%" : isMobileOrTablet ? "-9%" : "-3%"}
        top="43%"
        position="absolute"
        w="48px"
        h="48px"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        bg={COLOR.twelve}
        border={`1px solid ${COLOR.quaternary}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M25.3332 16.0003H6.6665M6.6665 16.0003L15.9998 25.3337M6.6665 16.0003L15.9998 6.66699"
            stroke="#747474"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Flex>
      <Splide
        ref={splideRef}
        options={{
          gap: "10px",
          perPage: cardsPerPage,
          pagination: false,
          arrows: false,
          breakpoints: {
            575: {
              perPage: 2,
            },
            900: {
              perPage: 1,
            },
            1024: {
              perPage: 2,
            },
            1200: {
              perPage: 3,
            },
          },
        }}
      >
        {paginatedCards.map((card, index) => (
          <SplideSlide key={card.id}>
            <Stack
              gap="10px"
              p="16px"
              transition="all 0.3s ease-in-out"
              mt="20px"
              key={card.id}
              bg={COLOR.tenth}
              w={isMobile ? "250px" : "355px"}
              h="180px"
              borderRadius="16px"
            >
              <Flex alignItems="center" gap="20px">
                <img
                  src={card.image}
                  alt={card.title}
                  style={{
                    width: isMobile ? "70px" : "100px",
                    height: isMobile ? "70px" : "100px",
                    borderRadius: "50%",
                    objectFit: "contain",
                    border: `1px solid ${COLOR.primary}`,
                  }}
                />
                <Stack spacing={0}>
                  <Text
                    m={0}
                    textAlign="left"
                    color={COLOR.sixteen}
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    fontFamily="DM Sans"
                  >
                    {card.name}
                  </Text>
                  <Text
                    sx={{
                      marginTop: "5px",
                    }}
                    m={0}
                    textAlign="left"
                    color={COLOR.sixteen}
                    fontFamily="DM Sans4"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="400"
                  >
                    {card.title}
                  </Text>
                  {card.id === 5 ||
                  card.id === 7 ||
                  card.id === 8 ||
                  card.id === 9 ||
                  card.id === 10 ||
                  card.id === 11 ? (
                    <Stack h="13.5px" />
                  ) : null}
                  <Text
                    m={0}
                    textAlign="left"
                    color={COLOR.sixteen}
                    fontFamily="DM Sans4"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="400"
                  >
                    {card.description}
                  </Text>
                </Stack>
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                gap="16px"
                cursor="pointer"
              >
                <a href={card.x} target="blank" rel="noreferrer">
                  <img
                    style={{
                      display:
                        // card.id === 1 ||
                        card.id === 4 ||
                        card.id === 5 ||
                        card.id === 6 ||
                        card.id === 7 ||
                        card.id === 8 ||
                        card.id === 9 ||
                        card.id === 10 ||
                        card.id === 11
                          ? "none"
                          : "flex",
                    }}
                    src={IMAGES.xIcon}
                    alt=""
                  />
                </a>
                <a
                  href={card.telegram}
                  target="blank"
                  rel="noreferrer"
                  style={{
                    display:
                      card.id === 4 ||
                      card.id === 6 ||
                      card.id === 7 ||
                      card.id === 8 ||
                      card.id === 11
                        ? "none"
                        : "flex",
                  }}
                >
                  <img src={IMAGES.telegramIcon} alt="" />
                </a>
                <a
                  style={{
                    display:
                      card.id === 1 ||
                      // card.id === 2 ||
                      card.id === 5 ||
                      card.id === 8 ||
                      card.id === 9
                        ? "none"
                        : "flex",
                  }}
                  href={card.linkIn}
                  target="blank"
                  rel="noreferrer"
                >
                  <img src={IMAGES.inIcon} alt="" />
                </a>
              </Flex>
            </Stack>
          </SplideSlide>
        ))}
      </Splide>
      <Flex
        cursor="pointer"
        onClick={handleNextClick}
        right="-2%"
        top="43%"
        position="absolute"
        w="48px"
        h="48px"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        bg={COLOR.twelve}
        border={`1px solid ${COLOR.quaternary}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M6.6665 16.0003H25.3332M25.3332 16.0003L15.9998 6.66699M25.3332 16.0003L15.9998 25.3337"
            stroke="#747474"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Flex>
      {/* <Flex justifyContent="center" mt="20px">
        {Array.from({ length: Math.ceil(cards.length / cardsPerPage) }).map(
          (_, index) => (
            <Flex
              key={index}
              mx="5px"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              w={index + 1 === currentPage ? "30px" : "10px"}
              h={index + 1 === currentPage ? "12px" : "10px"}
              borderRadius={index + 1 === currentPage ? "7px" : "50%"}
              bg={index + 1 === currentPage ? COLOR.tab : COLOR.white}
              onClick={() => handlePageChange(index + 1)}
              transition="all 0.3s ease-in-out"
            />
          )
        )}
      </Flex> */}
    </Stack>
  );
};

export default TeamSlider;
