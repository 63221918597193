import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section2 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack mt="20px">
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        {" "}
        Collecting and Using Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Types of Data Collected
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Personal Data
      </Text>
      <Text
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to: Email address Usage Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Usage Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Usage Data is collected automatically when using the Service.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Text>
    </Stack>
  );
};

export default Section2;
