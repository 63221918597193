export type ProductType = {
  img: string;
  all: AllType;
  desc: string;
  link: string;
};

export enum AllType {
  gameFi = "GameFi",
  defi = "DeFi",
}
