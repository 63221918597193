import { Flex, Grid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Container from "../../../component/Container";
import ModalComponent from "../../../component/ModalForm";
import { DefaultProduct } from "../../../constant/API_ROUTES/DataProject/ProjectDefaults";
import { AllType } from "../../../constant/API_ROUTES/DataProject/model/ProdcuctType";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const SectionPortfolio = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const [products, setProduct] = useState(DefaultProduct);
  const [typeFilter, setTypeFilter] = useState<AllType | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabID, setTabID] = useState(0);

  const filterAll = (all: AllType) => {
    const filterProducts = DefaultProduct.filter((item) => {
      return item.all === all;
    });
    setTypeFilter(all);
    setProduct(filterProducts);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabID = searchParams.get("modal");
    if (tabID === "1") {
      setTabID(0);
    } else if (tabID === "2") {
      setTabID(1);
    }
  }, []);
  return (
    <Container direction="column">
      <Text
        mt="50px"
        w={isTabletOrLaptop ? "100%" : "800px"}
        fontFamily="DM Sans"
        fontSize="40px"
        fontWeight="700"
        lineHeight="52px"
        textAlign="left"
        color={COLOR.sixteen}
      >
        We proudly backed many successful founders in their journeys
      </Text>
      <Flex
        justifyContent="space-between"
        w="full"
        gap="20px"
        alignItems={isMobileOrTablet ? "left" : "center"}
        direction={isMobileOrTablet ? "column" : "row"}
      >
        <Flex alignItems="center" flexWrap="wrap" gap="20px">
          <Flex
            cursor="pointer"
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            borderRadius={!typeFilter ? "3px" : "none"}
            p={!typeFilter ? "6px 10px" : "none"}
            bg={!typeFilter ? COLOR.seventh : "none"}
            border={!typeFilter ? `1px solid ${COLOR.eighth}` : "none"}
            onClick={() => {
              setProduct(DefaultProduct);
              setTypeFilter(null);
            }}
          >
            <Text
              m={0}
              color={!typeFilter ? COLOR.white : COLOR.fourteen}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              cursor="pointer"
            >
              All
            </Text>
          </Flex>
          <Flex
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            onClick={() => filterAll(AllType.defi)}
            borderRadius={typeFilter === AllType.defi ? "3px" : "none"}
            p={typeFilter === AllType.defi ? "6px 10px" : "none"}
            bg={typeFilter === AllType.defi ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.defi ? `1px solid ${COLOR.eighth}` : "none"
            }
          >
            <Text
              m={0}
              color={typeFilter === AllType.defi ? COLOR.white : COLOR.fourteen}
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              cursor="pointer"
            >
              Defi
            </Text>{" "}
          </Flex>
          <Flex
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            borderRadius={typeFilter === AllType.gameFi ? "3px" : "none"}
            p={typeFilter === AllType.gameFi ? "6px 10px" : "none"}
            onClick={() => filterAll(AllType.gameFi)}
            bg={typeFilter === AllType.gameFi ? COLOR.seventh : "none"}
            border={
              typeFilter === AllType.gameFi
                ? `1px solid ${COLOR.eighth}`
                : "none"
            }
          >
            <Text
              m={0}
              color={
                typeFilter === AllType.gameFi ? COLOR.white : COLOR.fourteen
              }
              fontFamily="DM Sans"
              fontSize="14px"
              fontWeight="700"
              lineHeight="22px"
              cursor="pointer"
            >
              Gamefi
            </Text>{" "}
          </Flex>
        </Flex>
        <Stack justifyContent="center" alignItems="center">
          <Stack
            cursor="pointer"
            bg={COLOR.nineteen}
            p="0 16px"
            borderRadius="1000px"
            h="44px"
            w="190px"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
            transition="all 235ms ease-in-out"
            _hover={{
              transform: "translate3d(0, 2px, 0)",
            }}
            onClick={onOpen}
          >
            <Text
              textAlign="center"
              fontFamily="DM Sans"
              fontSize="16px"
              fontWeight={700}
              lineHeight="30px"
              color={COLOR.colorButton}
              m={0}
            >
              Submit your project
            </Text>
          </Stack>
          <ModalComponent tabID={tabID} isOpen={isOpen} onClose={onClose} />
        </Stack>
      </Flex>
      <Grid
        gridTemplateColumns={
          isMobile
            ? "1fr"
            : isMobileOrTablet
            ? "1fr 1fr"
            : isTabletOrLaptop
            ? "1fr 1fr 1fr"
            : "1fr 1fr 1fr 1fr"
        }
        flexWrap="wrap"
        gap="27px"
        mt="30px"
        zIndex={1}
        data-aos="fade-up"
      >
        {products.map((item, index) => (
          <a
            key={item.img}
            href={item.link}
            target="blank"
            rel="noreferrerOnebit is trusted by global brands and ecosystem giants"
          >
            <Flex
              zIndex={1}
              gap="10px"
              borderRadius="4px"
              h={isMobile ? "136px" : "84px"}
              p="12px 37px"
              // maxW={isMobile ? "100%" : "281px"}
              justifyContent="space-between"
              border="1px solid rgba(255, 255, 255, 0.12)"
              alignItems="center"
              bg={COLOR.twentyThree}
              cursor="pointer"
              transition="all 235ms ease-in-out"
              _hover={{
                boxShadow: "5px 4px 22px 0px #38EDF91C",
                transform: "translate3d(0, 2px, 0)",
                bg: "linear-gradient(80deg, rgba(96, 96, 96, 0.20) -5.25%, rgba(21, 112, 250, 0.20) 69.95%, rgba(26, 188, 252, 0.20) 82.08%, rgba(55, 255, 207, 0.20) 90.29%)",
              }}
            >
              <img width="160px" src={item.img} alt="" />
              <Text
                m={0}
                color={COLOR.white}
                fontFamily="DM Sans"
                fontSize="24px"
                fontWeight="700"
                lineHeight="40px"
              >
                x{item.desc}
              </Text>
            </Flex>
          </a>
        ))}
      </Grid>
    </Container>
  );
};

export default SectionPortfolio;
