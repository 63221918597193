import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Container from "../../component/Container";
import APP_ROUTES from "../../constant/API_ROUTES/app_routes";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";

const About: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const data = [
    {
      title: "Competitive Salary and Benefits",
      desc: "We value the effort and contribution of each employee.",
      img: IMAGES.iconC1,
    },
    {
      title: "Career Development",
      desc: "We provide learning and skill development opportunities through training courses and mentorship programs.",
      img: IMAGES.iconC2,
    },
    {
      title: "Creative Work Environment",
      desc: "You will work in a dynamic and creative team environment.",
      img: IMAGES.iconC3,
    },
    {
      title: "Work-Life Balance",
      desc: "We support a balance between work and personal life.",
      img: IMAGES.iconC4,
    },
  ];
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const hire = [
    {
      title: "Apply",
      desc: "Feel free to apply for more than one job opportunity.",
      img: IMAGES.IC2,
    },
    {
      title: "Interview",
      desc: "A chance for not only us to get to know you better, but for you to find out more about working at Onebit!",
      img: IMAGES.IC3,
    },
    {
      title: "Notify",
      desc: "A recruiter will notify you if you are selected for the position. If you aren’t a fit for this particular role, not to worry. We add new jobs every day.",
      img: IMAGES.IC1,
    },
    {
      title: "Contract",
      desc: "Welcome you to Onebit!",
      img: IMAGES.IC4,
    },
  ];
  const job = [
    {
      link: APP_ROUTES.CAREERDETAIL,
      title: "DevOps Engineer",
      desc: "Senior",
      img: IMAGES.career5,
    },
    {
      title: "Business Development",
      link: APP_ROUTES.CAREERDETAIL,
      desc: "Senior",
      img: IMAGES.career6,
    },
    {
      title: "UI/UX Designer",
      link: APP_ROUTES.CAREERDETAIL,
      desc: "Senior",
      img: IMAGES.career7,
    },
    {
      title: "Business Analyst",
      link: APP_ROUTES.CAREERDETAIL,
      desc: "Senior",
      img: IMAGES.career8,
    },
    {
      title: "FE Developer",
      link: APP_ROUTES.CAREERDETAIL,
      desc: "Senior",
      img: IMAGES.career9,
    },
    {
      title: "BE Developer",
      link: APP_ROUTES.CAREERDETAIL,
      desc: "Senior",
      img: IMAGES.career10,
    },
  ];
  return (
    <Stack
      bg={COLOR.sixth}
      sx={{
        backgroundImage: `url(${IMAGES.cBg})`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container direction="column" p="100px 0" mt="5rem" gap="50px">
        <Flex gap="20px" direction={isMobileOrTablet ? "column" : "row"}>
          <Stack>
            <Text
              fontFamily="DM Sans"
              fontSize="48px"
              fontWeight="700"
              lineHeight="64px"
              color={COLOR.white}
            >
              Explore Career Opportunities with Us
            </Text>
            <Text
              fontFamily="DM Sans4"
              fontSize="20px"
              color={COLOR.white}
              fontWeight="400"
              lineHeight="32px"
            >
              Welcome to Onebit! Here, we believe that every individual has
              unique abilities and potential, and we are committed to creating a
              diverse, stimulating, and supportive work environment where you
              can grow and advance in your career.
            </Text>
          </Stack>
          <img
            style={{
              borderRadius: "13px",
              width: isMobileOrTablet ? "100%" : "628px",
            }}
            src={IMAGES.screen}
            alt=""
          />
        </Flex>
        <Stack mt="50px">
          <Text
            textAlign="center"
            fontFamily="DM Sans"
            fontSize="30px"
            fontWeight="700"
            lineHeight="42px"
            color={COLOR.white}
          >
            Why Onebit?
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            gap="30px"
          >
            {data.map((item, index) => (
              <Stack key={index} justifyContent="center" alignItems="center">
                <img src={item.img} alt="" />
                <Text
                  w="250px"
                  textAlign="center"
                  fontFamily="DM Sans"
                  fontSize="18px"
                  fontWeight="700"
                  color={COLOR.white}
                  lineHeight="28px"
                >
                  {item.title}
                </Text>
                <Text
                  w="300px"
                  fontFamily="DM Sans4"
                  textAlign="center"
                  fontSize="16px"
                  color={COLOR.fourteen}
                  fontWeight="400"
                  lineHeight="24px"
                >
                  {item.desc}
                </Text>
              </Stack>
            ))}
          </Flex>
        </Stack>
        <Stack mt="30px">
          <Text
            textAlign="center"
            color={COLOR.white}
            fontFamily="DM Sans"
            fontSize="30px"
            fontWeight="700"
            lineHeight="42px"
          >
            We Are Hiring
          </Text>
          <Grid
            gridTemplateColumns={isMobileOrTablet ? "1fr" : "1fr 1fr 1fr"}
            gridColumnGap="30px"
            gridRowGap="30px"
          >
            {job.map((item, index) => (
              <Link to={item.link} key={index} onClick={handleLabelClick}>
                <Stack>
                  <img style={{ width: "100%" }} src={item.img} alt="" />
                  <Text
                    m={0}
                    fontFamily="DM Sans"
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={COLOR.white}
                  >
                    {item.title}
                  </Text>
                  <Text
                    m={0}
                    fontFamily="DM Sans4"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    color={COLOR.fourteen}
                  >
                    {item.desc}
                  </Text>
                </Stack>
              </Link>
            ))}
          </Grid>
        </Stack>
        <Flex
          w="full"
          direction={isTabletOrLaptop ? "column" : "row"}
          mt="50px"
          justifyContent="space-between"
          p={isMobileOrTablet ? "50px 15px" : "60px"}
          h={isTabletOrLaptop ? "100%" : "499px"}
          borderRadius="20px"
          sx={{
            backgroundImage: `url(${IMAGES.bannerC})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Stack justifyContent="center">
            <Text
              m={0}
              fontFamily="DM Sans"
              fontSize="30px"
              fontWeight="700"
              lineHeight="42px"
              textAlign="center"
              color={COLOR.white}
            >
              Our Culture
            </Text>
            <Text
              m={0}
              color={COLOR.white}
              fontFamily="DM Sans4"
              fontSize="20px"
              fontWeight="400"
              lineHeight="32px"
              textAlign="center"
            >
              At our company, we respect and value diversity, creativity, and
              the commitment of each individual. We encourage an open and
              collaborative work environment where everyone can share opinions,
              ideas, and experiences to achieve common goals.
            </Text>
          </Stack>
        </Flex>
        <Stack mt="30px">
          <Text
            textAlign="center"
            fontFamily="DM Sans"
            fontSize="30px"
            fontWeight="700"
            lineHeight="42px"
            color={COLOR.white}
          >
            How we hire
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            gap="30px"
          >
            {hire.map((item, index) => (
              <Stack key={index} justifyContent="center" alignItems="center">
                <img src={item.img} alt="" />
                <Text
                  w="250px"
                  textAlign="center"
                  fontFamily="DM Sans"
                  fontSize="18px"
                  fontWeight="700"
                  color={COLOR.white}
                  lineHeight="28px"
                >
                  {item.title}
                </Text>
                <Text
                  w="300px"
                  fontFamily="DM Sans4"
                  textAlign="center"
                  fontSize="16px"
                  color={COLOR.fourteen}
                  fontWeight="400"
                  lineHeight="24px"
                >
                  {item.desc}
                </Text>
              </Stack>
            ))}
          </Flex>
          <Stack
            justifyContent="center"
            alignItems="center"
            display={isMobileOrTablet ? "none" : "flex"}
          >
            <img style={{ width: "90%" }} src={IMAGES.StepperGroup} alt="" />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
export default About;
