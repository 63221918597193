import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section2 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack mt="20px">
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        {" "}
        Collecting and Using Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: COLOR.white,
        }}
      >
        {" "}
        Types of Data Collected
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Personal Data
      </Text>
      <Text
        zIndex={1}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to: Email address Usage Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Usage Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Usage Data is collected automatically when using the Service.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Tracking Technologies and Cookies
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Cookies or Browser Cookies. A cookie is a small file placed on Your
        Device. You can instruct Your browser to refuse all Cookies or to
        indicate when a Cookie is being sent. However, if You do not accept
        Cookies, You may not be able to use some parts of our Service. Unless
        you have adjusted Your browser setting so that it will refuse Cookies,
        our Service may use Cookies.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        · Web Beacons. Certain sections of our Service and our emails may
        contain small electronic files known as web beacons (also referred to as
        clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
        for example, to count users who have visited those pages or opened an
        email and for other related website statistics (for example, recording
        the popularity of a certain section and verifying system and server
        integrity).
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Necessary / Essential Cookies
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Type: Session Cookies
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Administered by: Us
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Cookies Policy / Notice Acceptance Cookies
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Type: Persistent Cookies
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Administered by: Us
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Functionality Cookies
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Type: Persistent Cookies
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Administered by: Us
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Use of Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        The Company may use Personal Data for the following purposes:
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        To provide and maintain our Service, including to monitor the usage of
        our Service.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        To manage Your Account: to manage Your registration as a user of the
        Service. The Personal Data You provide can give You access to different
        functionalities of the Service that are available to You as a registered
        user.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        For the performance of a contract: the development, compliance and
        undertaking of the purchase contract for the products, items or services
        You have purchased or of any other contract with Us through the Service.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        To contact You: To contact You by email, telephone calls, SMS, or other
        equivalent forms of electronic communication, such as a mobile
        application's push notifications regarding updates or informative
        communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable
        for their implementation.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        To provide You with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless You have
        opted not to receive such information.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        To manage Your requests: To attend and manage Your requests to Us.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        For business transfers: We may use Your information to evaluate or
        conduct a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service
        users is among the assets transferred.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        For other purposes: We may use Your information for other purposes, such
        as data analysis, identifying usage trends, determining the
        effectiveness of our promotional campaigns and to evaluate and improve
        our Service, products, services, marketing and your experience.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        We may share Your personal information in the following situations:
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        ·   With Service Providers: We may share Your personal information with
        Service Providers to monitor and analyze the use of our Service, to
        contact You.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        ·   For business transfers: We may share or transfer Your personal
        information in connection with, or during negotiations of, any merger,
        sale of Company assets, financing, or acquisition of all or a portion of
        Our business to another company.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        ·   With Affiliates: We may share Your information with Our affiliates,
        in which case we will require those affiliates to honor this Privacy
        Policy. Affiliates include Our parent company and any other
        subsidiaries, joint venture partners or other companies that We control
        or that are under common control with Us.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        ·   With business partners: We may share Your information with Our
        business partners to offer You certain products, services or promotions.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        ·   With other users: when You share personal information or otherwise
        interact in the public areas with other users, such information may be
        viewed by all users and may be publicly distributed outside.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        ·   With Your consent: We may disclose Your personal information for any
        other purpose with Your consent.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Retention of Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Transfer of Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from your jurisdiction.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.white,
        }}
      >
        {" "}
        Delete Your Personal Data
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about you.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        You may update, amend, or delete Your information at any time by signing
        in to Your Account, if you have one, and visiting the account settings
        section that allows you to manage Your personal information. You may
        also contact Us to request access to, correct, or delete any personal
        information that You have provided to Us.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: COLOR.fourteen,
        }}
      >
        {" "}
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </Text>
    </Stack>
  );
};

export default Section2;
