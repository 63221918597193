import { Flex, Stack, Text } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import Container from "../../component/Container";
import { data } from "../../constant/API_ROUTES/DataEcosystem";
import COLOR from "../../constant/API_ROUTES/color";
import IMAGES from "../../constant/API_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";

const Ecosystem: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <>
      <Stack
        // p={isMobile ? "10px" : "0 50px"}
        sx={{
          backgroundImage: `url(${IMAGES.ecosystemBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "1000px",
          position: "relative",
        }}
      >
        <Stack
          bg={`rgba(0,0,0,0.8)`}
          zIndex={hoveredItem !== null ? 1 : -1}
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          transition="all 300ms ease-in-out"
        />
        <Container h="2000px" direction="column" mt="55px" p="80px 0">
          <Text
            textAlign="center"
            color={COLOR.white}
            fontFamily="MontserratB"
            fontSize={isMobile ? "20px" : isMobileOrTablet ? "30px" : "64px"}
            fontStyle="normal"
            fontWeight="700"
          >
            Our Ecosystem
          </Text>
          <Flex
            mt="50px"
            direction={isMobileOrTablet ? "column" : "row"}
            w="full"
            gap={isMobile ? "50px" : "90px"}
          >
            <Stack
              gap="30px"
              w={isMobileOrTablet ? "100%" : isTabletOrLaptop ? "40%" : "30%"}
            >
              <Text
                color={COLOR.white}
                fontFamily="PT Sans,sans-serif"
                fontSize={isMobile ? "14px" : "16px"}
                fontStyle="normal"
                fontWeight="700"
              >
                To optimize benefits for the investor community, we diversify
                the product and service portfolio in Our Ecosystem. Areas we
                focus on:
              </Text>
              <Stack>
                {data.map((item, index) => (
                  <Flex key={item.title} gap="10px">
                    <Text
                      m={0}
                      color={COLOR.white}
                      fontFamily="PT Sans,sans-serif"
                      fontSize={isMobile ? "14px" : "16px"}
                      fontStyle="normal"
                      fontWeight="700"
                    >
                      {index + 1}.
                    </Text>
                    <Text
                      m={0}
                      color={COLOR.white}
                      fontFamily="Montserrat"
                      fontSize={isMobile ? "14px" : "16px"}
                      fontStyle="normal"
                      fontWeight="700"
                    >
                      {item.title}
                    </Text>
                  </Flex>
                ))}
              </Stack>
            </Stack>

            <Flex
              h={isMobileOrTablet ? "500px" : "full "}
              justifyContent="flex-end"
              flexWrap="wrap"
              w={isMobileOrTablet ? "100%" : isTabletOrLaptop ? "60%" : "70%"}
              position="relative"
              zIndex={2}
            >
              <Stack
                position="absolute"
                left={
                  isMobileOrTablet
                    ? "25%"
                    : isTabletOrLaptop
                    ? 0
                    : isBigScreen
                    ? "20%"
                    : "30%"
                }
                top={isMobileOrTablet ? "5%" : isBigScreen ? "5%" : "20%"}
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 0
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(0)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "37px"
                      : isTabletOrLaptop
                      ? "56px"
                      : "76px",
                    height: isMobileOrTablet
                      ? "37px"
                      : isTabletOrLaptop
                      ? "56px"
                      : "76px",
                  }}
                  src={
                    hoveredItem === 0
                      ? IMAGES.bubble
                      : hoveredItem !== null
                      ? IMAGES.bubbleHover
                      : IMAGES.bubble
                  }
                  alt=""
                />
              </Stack>
              <Stack
                position="absolute"
                left={
                  isMobileOrTablet
                    ? "60%"
                    : isTabletOrLaptop
                    ? "70%"
                    : isBigScreen
                    ? "67%"
                    : "70%"
                }
                top={isBigScreen ? 0 : "5%"}
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 1
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "50px"
                      : isTabletOrLaptop
                      ? "84px"
                      : "104px",
                    height: isMobileOrTablet
                      ? "50px"
                      : isTabletOrLaptop
                      ? "84px"
                      : "104px",
                  }}
                  src={
                    hoveredItem === 1
                      ? IMAGES.bubble2
                      : hoveredItem !== null
                      ? IMAGES.bubble2Hover
                      : IMAGES.bubble2
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobileOrTablet
                    ? "20%"
                    : isTabletOrLaptop
                    ? "17%"
                    : isBigScreen
                    ? "35%"
                    : "50%"
                }
                left={
                  isMobileOrTablet
                    ? "30%"
                    : isTabletOrLaptop
                    ? "15%"
                    : isBigScreen
                    ? "30%"
                    : "40%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 2
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "28px"
                      : isTabletOrLaptop
                      ? "38px"
                      : "58px",
                    height: isMobileOrTablet
                      ? "28px"
                      : isTabletOrLaptop
                      ? "38px"
                      : "58px",
                  }}
                  src={
                    hoveredItem === 2
                      ? IMAGES.bubble12
                      : hoveredItem !== null
                      ? IMAGES.bubble12Hover
                      : IMAGES.bubble12
                  }
                  alt=""
                />
              </Stack>
              <Stack
                position="absolute"
                top={isTabletOrLaptop ? "10%" : isBigScreen ? "15%" : "25%"}
                left={
                  isMobileOrTablet
                    ? "40%"
                    : isTabletOrLaptop
                    ? "35%"
                    : isBigScreen
                    ? "45%"
                    : "50%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 3
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "47px"
                      : isTabletOrLaptop
                      ? "76px"
                      : "96px",
                    height: isMobileOrTablet
                      ? "47px"
                      : isTabletOrLaptop
                      ? "76px"
                      : "96px",
                  }}
                  src={
                    hoveredItem === 3
                      ? IMAGES.bubble11
                      : hoveredItem !== null
                      ? IMAGES.bubble11Hover
                      : IMAGES.bubble11
                  }
                  alt=""
                />
              </Stack>
              <Stack
                position="absolute"
                top={
                  isMobileOrTablet
                    ? "15%"
                    : isTabletOrLaptop
                    ? "25%"
                    : isBigScreen
                    ? "35%"
                    : "36%"
                }
                left={
                  isMobile
                    ? "70%"
                    : isMobileOrTablet
                    ? "60%"
                    : isTabletOrLaptop
                    ? "70%"
                    : isBigScreen
                    ? "80%"
                    : "74%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 4
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "70px"
                      : isTabletOrLaptop
                      ? "123px"
                      : "143px",
                    height: isMobileOrTablet
                      ? "70px"
                      : isTabletOrLaptop
                      ? "123px"
                      : "143px",
                  }}
                  src={
                    hoveredItem === 4
                      ? IMAGES.bubble3
                      : hoveredItem !== null
                      ? IMAGES.bubble3Hover
                      : IMAGES.bubble3
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobileOrTablet
                    ? "25%"
                    : isTabletOrLaptop
                    ? "33%"
                    : isBigScreen
                    ? "45%"
                    : "65%"
                }
                left={
                  isMobileOrTablet
                    ? "35%"
                    : isTabletOrLaptop
                    ? "25%"
                    : isBigScreen
                    ? "40%"
                    : "50%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 5
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(5)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "107px"
                      : isTabletOrLaptop
                      ? "182px"
                      : "218px",
                    height: isMobileOrTablet
                      ? "107px"
                      : isTabletOrLaptop
                      ? "182px"
                      : "218px",
                  }}
                  src={
                    hoveredItem === 5
                      ? IMAGES.bubble5
                      : hoveredItem !== null
                      ? IMAGES.bubble5Hover
                      : IMAGES.bubble5
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobileOrTablet
                    ? "27%"
                    : isTabletOrLaptop
                    ? "30%"
                    : isBigScreen
                    ? "55%"
                    : "80%"
                }
                left={
                  isMobile
                    ? 0
                    : isMobileOrTablet
                    ? "15%"
                    : isTabletOrLaptop
                    ? -10
                    : isBigScreen
                    ? "15%"
                    : "25%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 6
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(6)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "70px"
                      : isTabletOrLaptop
                      ? "120px"
                      : "140px",
                    height: isMobileOrTablet
                      ? "70px"
                      : isTabletOrLaptop
                      ? "120px"
                      : "140px",
                  }}
                  src={
                    hoveredItem === 6
                      ? IMAGES.bubble10
                      : hoveredItem !== null
                      ? IMAGES.bubble10Hover
                      : IMAGES.bubble10
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobile
                    ? "35%"
                    : isMobileOrTablet
                    ? "40%"
                    : isTabletOrLaptop
                    ? "60%"
                    : isBigScreen
                    ? "85%"
                    : "95%"
                }
                left={
                  isMobile
                    ? "73%"
                    : isMobileOrTablet
                    ? "60%"
                    : isTabletOrLaptop
                    ? "65%"
                    : isBigScreen
                    ? "75%"
                    : "78%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 7
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(7)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "90px"
                      : isTabletOrLaptop
                      ? "150px"
                      : "180px",
                    height: isMobileOrTablet
                      ? "90px"
                      : isTabletOrLaptop
                      ? "150px"
                      : "180px",
                  }}
                  src={
                    hoveredItem === 7
                      ? IMAGES.bubble9
                      : hoveredItem !== null
                      ? IMAGES.bubble9Hover
                      : IMAGES.bubble9
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobileOrTablet
                    ? "47%"
                    : isTabletOrLaptop
                    ? "65%"
                    : isBigScreen
                    ? "100%"
                    : "130%"
                }
                left={
                  isMobile
                    ? 0
                    : isMobileOrTablet
                    ? "10%"
                    : isTabletOrLaptop
                    ? -15
                    : isBigScreen
                    ? "10%"
                    : "25%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 8
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(8)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "48px"
                      : isTabletOrLaptop
                      ? "76px"
                      : "96px",
                    height: isMobileOrTablet
                      ? "48px"
                      : isTabletOrLaptop
                      ? "76px"
                      : "96px",
                  }}
                  src={
                    hoveredItem === 8
                      ? IMAGES.bubble4
                      : hoveredItem !== null
                      ? IMAGES.bubble4Hover
                      : IMAGES.bubble4
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobile
                    ? "50%"
                    : isMobileOrTablet
                    ? "55%"
                    : isTabletOrLaptop
                    ? "80%"
                    : isBigScreen
                    ? "110%"
                    : "145%"
                }
                left={
                  isMobileOrTablet
                    ? "20%"
                    : isTabletOrLaptop
                    ? "15%"
                    : isBigScreen
                    ? "25%"
                    : "40%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 9
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(9)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "54px"
                      : isTabletOrLaptop
                      ? "70px"
                      : "111px",
                    height: isMobileOrTablet
                      ? "54px"
                      : isTabletOrLaptop
                      ? "70px"
                      : "111px",
                  }}
                  src={
                    hoveredItem === 9
                      ? IMAGES.bubble6
                      : hoveredItem !== null
                      ? IMAGES.bubble6Hover
                      : IMAGES.bubble6
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobile
                    ? "53%"
                    : isMobileOrTablet
                    ? "55%"
                    : isTabletOrLaptop
                    ? "85%"
                    : isBigScreen
                    ? "110%"
                    : "145%"
                }
                left={
                  isMobile
                    ? "42%"
                    : isMobileOrTablet
                    ? "35%"
                    : isTabletOrLaptop
                    ? "40%"
                    : isBigScreen
                    ? "47%"
                    : "60%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 10
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(10)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "35px"
                      : isTabletOrLaptop
                      ? "47px"
                      : "73px",
                    height: isMobileOrTablet
                      ? "35px"
                      : isTabletOrLaptop
                      ? "47px"
                      : "73px",
                  }}
                  src={
                    hoveredItem === 10
                      ? IMAGES.bubble7
                      : hoveredItem !== null
                      ? IMAGES.bubble7Hover
                      : IMAGES.bubble7
                  }
                  alt=""
                />
              </Stack>{" "}
              <Stack
                position="absolute"
                top={
                  isMobile
                    ? "50%"
                    : isMobileOrTablet
                    ? "50%"
                    : isTabletOrLaptop
                    ? "90%"
                    : isBigScreen
                    ? "100%"
                    : "130%"
                }
                left={
                  isMobile
                    ? "60%"
                    : isMobileOrTablet
                    ? "50%"
                    : isBigScreen
                    ? "60%"
                    : "70%"
                }
                transition="all 0.3s ease-in-out"
                filter={
                  hoveredItem === 11
                    ? "brightness(100%)"
                    : hoveredItem !== null
                    ? "grayscale(100%)"
                    : "none"
                }
                onMouseEnter={() => handleMouseEnter(11)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  style={{
                    width: isMobileOrTablet
                      ? "28px"
                      : isTabletOrLaptop
                      ? "40px"
                      : "58px",
                    height: isMobileOrTablet
                      ? "28px"
                      : isTabletOrLaptop
                      ? "40px"
                      : "58px",
                  }}
                  src={
                    hoveredItem === 11
                      ? IMAGES.bubble8
                      : hoveredItem !== null
                      ? IMAGES.bubble8Hover
                      : IMAGES.bubble8
                  }
                  alt=""
                />
              </Stack>{" "}
            </Flex>
          </Flex>
        </Container>
      </Stack>
      <Stack
        bg="linear-gradient(180deg, #001B37 0%, #0F1928 100%)"
        position="relative"
        justifyContent="space-between"
        w="full"
        sx={{
          // backgroundImage: `url(${IMAGES.bannerRocket})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          height: isTabletOrLaptop ? "none" : isBigScreen ? "200vh" : "90vh",
        }}
      >
        <Stack direction="column" p="30px">
          <Text
            mt="50px"
            textAlign="center"
            color={COLOR.white}
            fontFamily="MontserratB"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="700"
          >
            What are the advantages of cooperating with us ?
          </Text>
          <Flex direction={isTabletOrLaptop ? "column" : "row"}>
            <img
              style={{
                width: isTabletOrLaptop ? "100%" : "60%",
                position: isTabletOrLaptop ? "static" : "absolute",
                top: "-3%",
              }}
              src={IMAGES.rocket}
              alt=""
            />
            <Stack
              gap="30px"
              position={isTabletOrLaptop ? "static" : "absolute"}
              left="50%"
            >
              <Text
                mt="50px"
                textAlign="left"
                color={COLOR.white}
                fontFamily="MontserratB"
                fontSize="48px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="60px"
              >
                Startup Launchpad
              </Text>
              <Stack spacing={0}>
                <Text
                  color={COLOR.white}
                  fontFamily="PTSans"
                  fontSize="14px"
                  m={0}
                  fontStyle="normal"
                  lineHeight="22px"
                  fontWeight="400"
                  maxW="480px"
                  // w={isTabletOrLaptop ? "100%" : "650px"}
                >
                  Onebit Ventures is actively engaged in the development of a
                  platform designed to facilitate crowdfunding via a DeFi
                  launcher, enabling investment in projects utilizing blockchain
                  technology.
                </Text>
                <Text
                  m={0}
                  color={COLOR.white}
                  fontFamily="PTSans"
                  fontSize="14px"
                  fontStyle="normal"
                  lineHeight="22px"
                  fontWeight="400"
                  maxW="490px"
                >
                  Additionally, a diverse range of ecosystems and businesses
                  will provide support for projects spanning finance, training,
                  insurance, and community engagement.
                </Text>
              </Stack>
              <Flex gap="22px">
                <Stack
                  w={isMobileOrTablet ? "100%" : "200px"}
                  cursor="pointer"
                  bg={COLOR.primary}
                  p={isMobile ? "10px 0" : "16px 48px"}
                  border={`3px solid ${COLOR.primary}`}
                  borderRadius="4px"
                  transition="all 235ms ease-in-out"
                  _hover={{
                    transform: "translate3d(0, 2px, 0)",
                  }}
                >
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="28px"
                    color={COLOR.white}
                    m={0}
                  >
                    Join IDO
                  </Text>
                </Stack>
                <Stack
                  cursor="pointer"
                  w={isMobileOrTablet ? "100%" : "254px"}
                  p={isMobile ? "10px 0" : "16px 48px"}
                  border={`3px solid ${COLOR.primary}`}
                  borderRadius="4px"
                  transition="all 235ms ease-in-out"
                  _hover={{
                    transform: "translate3d(0, 2px, 0)",
                  }}
                >
                  <Text
                    textAlign="center"
                    fontSize="18px"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="28px"
                    color={COLOR.primary}
                    m={0}
                  >
                    Apply for IDO
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </Flex>
        </Stack>
      </Stack>
    </>
  );
};
export default Ecosystem;
