import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section4 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        Links to Other Websites
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </Text>{" "}
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </Text>
    </Stack>
  );
};

export default Section4;
