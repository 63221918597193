import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section6 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        Changes to this Privacy Policy
      </Text>

      <Text
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page. We will
        let You know via email and/or a prominent notice on Our Service, prior
        to the change becoming effective and update the "Last updated" date at
        the top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </Text>
    </Stack>
  );
};

export default Section6;
