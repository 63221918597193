// ModalComponent.tsx
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import COLOR from "../../constant/API_ROUTES/color";
import useResponsive from "../../hooks/useResponsive";
import InvestorForm from "../InvestorForm";
import KOLSForm from "../KOLsForm";
import StartUpForm from "../StartUpForm";
import TabForm from "../TabForm";

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  tabID: number;
}
const tabs = [
  {
    label: "For Startups",
    content: <StartUpForm />,
  },
  {
    label: "For Investors",
    content: <InvestorForm />,
  },
  {
    label: "For KOLs",
    content: <KOLSForm />,
  },
];
const ModalComponent: React.FC<ModalComponentProps> = ({
  tabID,
  isOpen,
  onClose,
}) => {
  const { isMobile } = useResponsive();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isMobile ? "sm" : "xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            background: COLOR.sixth,
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
          }}
        >
          <ModalHeader
            sx={{
              color: COLOR.white,
              fontFamily: "DM Sans5",
              fontSize: isMobile ? "30px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: isMobile ? "25px" : "48px",
            }}
          >
            Apply To OneBit
          </ModalHeader>
          <ModalCloseButton color={COLOR.white} />

          <ModalBody>
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans4",
                m: 0,
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              To enhance our assistance tailored to your needs, kindly provide
              some information related to your inquiry. We're excited to delve
              into your details!
            </Text>
            <TabForm tabs={tabs} tabID={tabID} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalComponent;
