import { Stack, Text } from "@chakra-ui/react";
import COLOR from "../../../constant/API_ROUTES/color";
import useResponsive from "../../../hooks/useResponsive";
const Section7 = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();

  return (
    <Stack>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "38px",
          color: COLOR.white,
        }}
      >
        Governing Law
      </Text>
      <Text
        m={0}
        sx={{
          fontFamily: "DM Sans4",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
          color: COLOR.fourteen,
        }}
      >
        The laws of the Country, excluding its conflicts of law rules, shall
        govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </Text>
    </Stack>
  );
};

export default Section7;
