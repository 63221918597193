// ModalComponent.tsx
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import COLOR from "../../constant/API_ROUTES/color";
import useResponsive from "../../hooks/useResponsive";
import StartUpForm from "../StartUpForm";

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalStartUp: React.FC<ModalComponentProps> = ({ isOpen, onClose }) => {
  const { isMobile } = useResponsive();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isMobile ? "sm" : "xl"}
        scrollBehavior="inside"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            background: COLOR.sixth,
          }}
        >
          <ModalHeader
            sx={{
              color: COLOR.white,
              fontFamily: "DM Sans5",
              fontSize: isMobile ? "30px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: isMobile ? "25px" : "48px",
            }}
          >
            Apply To OneBit
          </ModalHeader>
          <ModalCloseButton color={COLOR.white} />

          <ModalBody overflowY="auto">
            <Text
              sx={{
                color: COLOR.fourteen,
                fontFamily: "DM Sans4",
                m: 0,
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              To enhance our assistance tailored to your needs, kindly provide
              some information related to your inquiry. We're excited to delve
              into your details!
            </Text>
            <StartUpForm />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalStartUp;
