import { useEffect, useState } from "react";

import { Button, Flex, Input, Stack, Text, useToast } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";

import { Link, useParams } from "react-router-dom";
// import { DefaultBlog } from "../../../component/BlogDefault";
import { BlogType } from "../../../component/BlogType";
import Container from "../../../component/Container";
import { socials } from "../../../component/Footer/Socials";
import APP_ROUTES from "../../../constant/API_ROUTES/app_routes";
import COLOR from "../../../constant/API_ROUTES/color";
import IMAGES from "../../../constant/API_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import axios from "axios";
import { error } from "console";
import moment from "moment";

function NewsDetails({}) {
  const [blog, setBlog] = useState<BlogType>();
  const [blogs, setBlogs] = useState<BlogType[]>([]);

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  type Blog = { _id?: string };
  const ItemBlog = useParams();
  const Item: Blog = ItemBlog;
  const BlogItem = Item._id;
  // console.log(BlogItem);
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blogs?limit=3`)
      .then((res) => {
        setBlogs(res.data.data.blogs);
        // console.log(res.data.data.blogs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blogs/${BlogItem}`)
      .then((res) => {
        // console.log(res.data.data);
        setBlog(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // if (BlogItem) {
    //   setBlog(DefaultBlog.find((item) => item.id === BlogItem));
    // }
  }, [BlogItem]);
  if (!blog) {
    return null;
  }
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });
  const handleSubscribe = async (
    values: { email: any },
    { resetForm }: any
  ) => {
    setIsLoading(true);

    try {
      const { email } = values;
      const datas = await fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (datas.status === 200) {
        toast({
          title: "Subscribe successful",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }
      if (datas.status === 400) {
        toast({
          title: "You already subscribe",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }
      resetForm();
    } catch (err) {
      console.log(err);
      // if (errorResponse.status === 400) {
      //   toast({
      //     title: "You already subscribe",
      //     status: "error",
      //     position: "top-right",
      //     duration: 3000,
      //     isClosable: true,
      //   });
      // }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    blog && (
      <Stack
        bg={COLOR.sixth}
        sx={{
          backgroundImage: `url(${IMAGES.tron})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container direction="column" p="100px 0" gap="10px">
          <Text
            fontFamily="DM Sans"
            fontSize="30px"
            fontWeight={700}
            lineHeight="42px"
            color={COLOR.white}
          >
            {blog.title}
          </Text>
          <Text
            fontFamily="DM Sans4"
            fontSize="18px"
            fontWeight={400}
            lineHeight="28px"
            color={COLOR.fourteen}
          >
            By {blog.author?.name} •{" "}
            {moment(blog.updatedAt).format("MMM DD, YYYY")}
          </Text>
          <Stack justifyContent="center" alignItems="center">
            <img
              style={{
                borderRadius: "16px",
                width: isMobileOrTablet ? "100%" : "60%",
              }}
              src={blog.bannerUrl}
              alt=""
            />
          </Stack>

          <Text
            marginTop="20px"
            fontFamily="DM Sans4"
            // fontSize="18px"
            // fontWeight={200}
            lineHeight="20px"
            color={COLOR.fourteen}
          >
            {blog.content ? (
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            ) : (
              <p>No content available</p>
            )}
          </Text>

          <Stack alignItems="center" mt="30px">
            <Stack position="relative">
              <Text
                textAlign="left"
                fontFamily="DM Sans"
                fontSize="20px"
                fontWeight={700}
                lineHeight="32px"
                color={COLOR.white}
              >
                Subscribe to our newsletter
              </Text>
              <Formik
                onSubmit={handleSubscribe}
                initialValues={{
                  email: "",
                }}
                validationSchema={RegisterSchema}
              >
                {({ handleChange, handleSubmit, errors, touched, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <Flex className="form" gap="10px">
                      <Stack>
                        <Field name="email">
                          {({ field }: FieldProps<string>) => (
                            <Input
                              {...field}
                              zIndex={1}
                              w={isMobileOrTablet ? "100%" : "500px"}
                              border={`1px solid ${COLOR.eighteen}`}
                              bg={COLOR.sixth}
                              color={COLOR.white}
                              borderRadius="12px"
                              placeholder="Enter your email"
                              className="input"
                              autoComplete="off"
                              style={{ textDecoration: "none" }}
                            />
                          )}
                        </Field>

                        <Stack>
                          {errors.email && touched.email && (
                            <Text
                              style={{
                                color: COLOR.red,
                                fontSize: "12px",
                                marginLeft: "20px",
                                width: "100%",
                                fontFamily: "DM Sans4",
                                fontWeight: 400,
                              }}
                            >
                              {errors.email}
                            </Text>
                          )}
                        </Stack>
                      </Stack>

                      <Button
                        zIndex={1}
                        isLoading={isLoading}
                        p="0 10px"
                        justifyContent="center"
                        cursor="pointer"
                        gap="10px"
                        h="44px"
                        borderRadius="1000px"
                        bg={COLOR.fifteen}
                        alignItems="center"
                        type="submit"
                        fontSize="16px"
                        border={`1px solid ${COLOR.nineteen}`}
                        fontWeight={700}
                        lineHeight="30px"
                        fontFamily="DM Sans"
                        color={COLOR.white}
                        _hover={{
                          bg: COLOR.fifteen,
                        }}
                      >
                        Subscribe
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
              <img
                src={IMAGES.poCircle}
                alt=""
                style={{
                  display: isTabletOrLaptop ? "none" : "flex",
                  position: "absolute",
                  top: "-160%",
                  // left: "",
                  zIndex: 0,

                  width: "700px",
                }}
              />
            </Stack>
            <Flex justifyContent="center" gap="10px" zIndex={1}>
              {socials.map((i) => (
                <a href={i.link} target="blank" rel="noreferrer" key={i.img}>
                  <Flex
                    transition="all 235ms ease-in-out"
                    cursor="pointer"
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                    }}
                    w="40px"
                    h="40px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img src={i.img} alt="" />
                  </Flex>
                </a>
              ))}
            </Flex>
          </Stack>
          <Flex
            justifyContent="space-between"
            w="full"
            direction={isMobileOrTablet ? "column" : "row"}
          >
            <Stack>
              <Text
                m={0}
                fontFamily="DM Sans"
                fontSize="24px"
                fontWeight={700}
                lineHeight="38px"
                color={COLOR.white}
              >
                Latest from our blog
              </Text>
              <Text
                m={0}
                fontFamily="DM Sans5"
                fontSize="18px"
                fontWeight={500}
                lineHeight="28px"
                color={COLOR.fourteen}
              >
                Weekly featured articles from top authors.
              </Text>
            </Stack>
            {/* <Flex bg="#333333">
              <Stack bg={COLOR.sixth}>
                <Text
                  fontFamily="DM Sans5"
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="24px"
                  color={COLOR.white}
                >
                  Featured
                </Text>
              </Stack>
              <Text
                fontFamily="DM Sans5"
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                color={COLOR.fourteen}
              >
                Latest
              </Text>
            </Flex> */}
          </Flex>
          <Stack gap="30px" mt="30px">
            {" "}
            {blogs.map((i, index) => (
              <Link
                to={`${APP_ROUTES.NEWSDETAIL}/${i._id}`}
                key={i._id}
                onClick={handleLabelClick}
              >
                <Flex
                  direction={isTabletOrLaptop ? "column" : "row"}
                  key={index}
                  justifyContent="space-between"
                  w="full"
                  p="24px"
                  borderRadius="12px"
                  border={`1px solid ${COLOR.eighteen}`}
                  gap="24px"
                >
                  <Stack w={isTabletOrLaptop ? "100%" : "50%"}>
                    <Text
                      cursor="pointer"
                      _hover={{
                        color: COLOR.nineteen,
                      }}
                      fontFamily="DM Sans5"
                      fontSize="20px"
                      fontWeight={500}
                      lineHeight="32px"
                      color={COLOR.white}
                    >
                      {i.title}
                    </Text>
                    <Text
                      fontFamily="DM Sans4"
                      fontSize="18px"
                      fontWeight={400}
                      lineHeight="28px"
                      color={COLOR.fourteen}
                    >
                      {i.shortDes}
                    </Text>
                    <Flex gap="10px" alignItems="center">
                      <Flex w="50px" h="50px" borderRadius="50%">
                        <img src={IMAGES.avatar} alt="" />
                      </Flex>
                      <Stack>
                        <Text
                          m={0}
                          fontFamily="DM Sans5"
                          fontSize="16px"
                          fontWeight={500}
                          lineHeight="24px"
                          color={COLOR.white}
                        >
                          {i.author?.name}
                        </Text>
                        <Text
                          m={0}
                          fontFamily="DM Sans5"
                          fontSize="16px"
                          fontWeight={500}
                          lineHeight="24px"
                          color="#FFFFFF5C"
                        >
                          {moment(blog.updatedAt).format("MMM DD, YYYY")}
                        </Text>
                      </Stack>
                    </Flex>
                    <Flex gap="10px">
                      {i.types?.map((item: string, index: number) => {
                        return (
                          <Stack
                            key={index}
                            border={`1px solid ${COLOR.eighteen}`}
                            borderRadius="13px"
                            p="6px 10px"
                            bg={COLOR.seventh}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Text
                              m={0}
                              fontFamily="DM Sans5"
                              fontSize="16px"
                              fontWeight={500}
                              lineHeight="24px"
                              color={COLOR.white}
                            >
                              {item}
                            </Text>
                          </Stack>
                        );
                      })}
                      {/* <Stack
                        p="6px 10px"
                        bg={COLOR.seventh}
                        border={`1px solid ${COLOR.eighteen}`}
                        borderRadius="13px"
                      >
                        <Text
                          m={0}
                          fontFamily="DM Sans5"
                          fontSize="16px"
                          fontWeight={500}
                          lineHeight="24px"
                          color={COLOR.white}
                        >
                          Project
                        </Text>
                      </Stack> */}
                    </Flex>
                  </Stack>
                  <Stack w={isTabletOrLaptop ? "100%" : "50%"}>
                    <img
                      style={{
                        border: `1px solid ${COLOR.eighteen}`,
                        width: "100%",
                      }}
                      src={i.bannerUrl}
                      alt=""
                    />
                  </Stack>
                </Flex>
              </Link>
            ))}
          </Stack>
        </Container>
      </Stack>
    )
  );
}

export default NewsDetails;
