import IMAGES from "../../images";
import { AllType, ProductType } from "../model/ProdcuctType";

export const DefaultProduct: ProductType[] = [
  {
    link: "https://coinmarketcap.com/vi/currencies/elemon/",
    img: IMAGES.elemon,
    all: AllType.gameFi,
    desc: "714",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/legend-of-fantasy-war/",
    img: IMAGES.lfw,
    all: AllType.gameFi,
    desc: "640",
  },
  {
    link: "https://qorpo.world/",
    img: IMAGES.qorpo,
    all: AllType.gameFi,
    desc: "35",
  },

  {
    link: "https://coinmarketcap.com/vi/currencies/bscs/",
    img: IMAGES.bscC,
    all: AllType.defi,
    desc: "535",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/last-survivor/",
    img: IMAGES.lsc,
    all: AllType.gameFi,
    desc: "250",
  },

  {
    link: "https://coinmarketcap.com/vi/currencies/luna-rush/",
    img: IMAGES.lr,
    all: AllType.gameFi,
    desc: "124",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/gaia-everworld/",
    img: IMAGES.ge,
    all: AllType.gameFi,
    desc: "18",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/zuki-moba/",
    img: IMAGES.zm,
    all: AllType.gameFi,
    desc: "48",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/dragon-kart/",
    img: IMAGES.dk,
    all: AllType.gameFi,
    desc: "40",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/shopnext-loyalty-token/",
    img: IMAGES.sn,
    all: AllType.gameFi,
    desc: "10",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/sidus/",
    img: IMAGES.sidus,
    all: AllType.gameFi,
    desc: "163",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/9d-nft/",
    img: IMAGES.cogi,
    all: AllType.gameFi,
    desc: "16",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/daolaunch/",
    img: IMAGES.dl,
    all: AllType.defi,
    desc: "12",
  },

  {
    link: "https://coinmarketcap.com/vi/currencies/calo-app/",
    img: IMAGES.cl,
    all: AllType.gameFi,
    desc: "13",
  },

  {
    link: "https://coinmarketcap.com/vi/currencies/the-parallel/",
    img: IMAGES.tp,
    all: AllType.gameFi,
    desc: "36",
  },
  {
    link: "https://coinmarketcap.com/vi/currencies/green-beli/",
    img: IMAGES.gb,
    all: AllType.gameFi,
    desc: "23",
  },
];
