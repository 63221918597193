import { Flex, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import COLOR from "../../constant/API_ROUTES/color";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";

interface Tab {
  label: string;
  desc: string;
  content: React.ReactNode;
  buttonForm: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex justifyContent="center" w="full" position="relative">
      {isTabletOrLaptop ? (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          style={{
            height: "220vh",
            objectFit: "cover",
            filter: "brightness(20%)",
          }}
        >
          <source src="/video/Onebit2.mp4" type="video/mp4"></source>
        </video>
      ) : (
        <Stack
          h="500px"
          sx={{
            overflow: "hidden",
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            style={{
              width: "100vw",

              filter: "brightness(20%)",
            }}
          >
            <source src="/video/Onebit2.mp4" type="video/mp4"></source>
          </video>
        </Stack>
      )}

      <Container
        direction={isTabletOrLaptop ? "column" : "row"}
        position="absolute"
        top={isTabletOrLaptop ? 0 : "15%"}
        // w="full"
        // p={isTabletOrLaptop ? 0 : "0 0 0 80px"}
        gap={isTabletOrLaptop ? "20px" : "50px"}
      >
        <Stack w={isTabletOrLaptop ? "100%" : "50%"}>
          <Flex p="20px 0" gap="10px" w="full" alignItems="center">
            {tabs.map((tab, index) => (
              <Flex
                cursor="pointer"
                p="6px 16px"
                justifyContent="center"
                key={index}
                className={`tab-item ${index === activeTab ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
                borderRadius="4px"
                bg={index === activeTab ? COLOR.seventh : "none"}
                border={
                  index === activeTab ? `1px solid ${COLOR.eighth}` : "none"
                }
              >
                <Text
                  textAlign="center"
                  color={index === activeTab ? "#FFF" : "#FFF"}
                  fontFamily="DM Sans"
                  fontSize="14px"
                  fontWeight={700}
                  lineHeight="22px"
                  m={0}
                >
                  {tab.label}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Text
            textAlign="left"
            fontFamily="DM Sans"
            fontSize="40px"
            fontWeight={600}
            lineHeight="52px"
            color={COLOR.white}
            m={0}
          >
            More than a Venture Capital
          </Text>
          <Text
            textAlign="left"
            fontFamily="DM Sans4"
            fontSize="16px"
            fontWeight={400}
            lineHeight="24px"
            color={COLOR.white}
            m={0}
          >
            {tabs[activeTab].desc}
          </Text>
          <Stack>{tabs[activeTab].buttonForm}</Stack>
        </Stack>
        <Stack w={isTabletOrLaptop ? "100%" : "50%"} spacing={0}>
          {tabs[activeTab].content}
        </Stack>
      </Container>
    </Flex>
  );
};

export default Tabs;
